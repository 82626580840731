import { useCallback, useEffect, useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { shallow } from 'zustand/shallow';
import { GridSortItem } from '@mui/x-data-grid';
import _ from 'lodash';

import {
  getIndexReport,
  updateDuplicateDocument,
  updateTimelineEntriesForReportSection,
} from '../../../api';
import {
  ChangeTimelineEntryDetailsInput,
  useUpdateTimelineReportMutation,
  useUpdateTimelineReportSectionMutation,
} from '../../../__generated__/graphql';
import useReportsStore from '../useReportsStore';
import { customMultiSort, formatEntryDateToString, shouldUpdateTableRow } from './utils/tableUtils';
import useBulkUpdatePageTags from '../../Timeline/gql/useBulkUpdatePageTags';
import { useAsync } from '../../../hooks/useAsync';
import { useUpdateDocumentName } from '../api-queries/useUpdateDocumentName';
import { useUpdateMonetaryTotal } from '../api-queries/useUpdateMonetaryTotal';
import useUpdateTimelineEntry from '../../Timeline/gql/updateTimelineEntry';
import { TimelineDetailsProps } from './DocumetPreviewer/DocumentPreviewer';
import { useUpdateMarkedImportant } from '../../../components/DocumentScrolling/useUpdateMarkedImportant';

export function useIndexReport(reportId: string) {
  const [shouldShowDuplicates, setShouldShowDuplicates] = useState(false);
  const [shouldShowEmptySections, setShouldShowEmptySections] = useState(false);
  const [indexReport, setIndexReport] = useState<any>(null);
  const [uploadDates, setUploadDates] = useState<string[]>([]);
  const [areIndexSectionsEmpty, setAreIndexSectionsEmpty] = useState<
    Record<string, boolean> | undefined
  >(undefined);
  const [sortModel, setSortModel] = useState<GridSortItem[]>([
    {
      field: 'entry_date',
      sort: 'asc',
    },
  ]);
  const [
    { isFirstComparisonDocumentDuplicate, isSecondComparisonDocumentDuplicate },
    setComparisonDocumentsIsDuplicate,
  ] = useState<{
    isFirstComparisonDocumentDuplicate?: boolean;
    isSecondComparisonDocumentDuplicate?: boolean;
  }>({});
  const [documentComparerDocumentIds, setDocumentComparerDocumentIds] = useState<{
    firstDocumentID?: string;
    secondDocumentID?: string;
  }>({});
  const [isDocumentComparerModalOpen, setIsDocumentComparerModalOpen] = useState<boolean>(false);
  const [
    { firstComparisonDocumentSectionID, secondComparisonDocumentSectionID },
    setComparisonDocumentsSectionIDs,
  ] = useState<{
    firstComparisonDocumentSectionID?: string;
    secondComparisonDocumentSectionID?: string;
  }>({});

  const [editTimelineReport] = useUpdateTimelineReportMutation();
  const [editReportSectionMutation] = useUpdateTimelineReportSectionMutation();
  const [bulkUpdatePageTags] = useBulkUpdatePageTags();
  const editDocumentName = useUpdateDocumentName();
  const editMonetaryTotal = useUpdateMonetaryTotal();
  const [editTimelineEntry] = useUpdateTimelineEntry();

  const { currentReport, setCurrentReport } = useReportsStore(
    (state) => ({
      currentReport: state.currentReport,
      setCurrentReport: state.setCurrentReport,
    }),
    shallow,
  );
  const { caseID = '' } = useParams<{ caseID: string }>();

  const [indexReportResp, refreshIndexReport] = useAsync(
    () => getIndexReport(caseID, reportId, !shouldShowDuplicates, uploadDates),
    [caseID, reportId, shouldShowDuplicates, uploadDates],
  );

  useEffect(() => {
    if (indexReportResp.status === 'resolved') {
      setIndexReport(indexReportResp.data?.data);
    }
  }, [indexReportResp]);

  useEffect(() => {
    if (indexReport) {
      const areSectionsEmpty = indexReport.sections.every(
        (section: any) => section.rows.length === 0,
      );
      setAreIndexSectionsEmpty(areSectionsEmpty);
    }
  }, [indexReport]);

  const updateIndexReportName = useCallback(
    async (reportName: string) => {
      try {
        const updatedReportResponse = await editTimelineReport({
          variables: {
            id: reportId,
            data: {
              name: reportName,
            },
          },
        });

        setCurrentReport({
          ...currentReport,
          reportName: updatedReportResponse.data?.updateTimelineReport?.name,
        });
        setIndexReport({
          ...indexReport,
          name: updatedReportResponse.data?.updateTimelineReport?.name,
        });
        toast.success('Successfully updated report name.');
      } catch (error) {
        toast.error('There was an error updating the report name.');
      }
    },
    [reportId, currentReport, indexReport],
  );

  const updateIndexReportSectionName = useCallback(
    async (sectionId: string, sectionName: string) => {
      try {
        await editReportSectionMutation({
          variables: {
            id: sectionId,
            data: { name: sectionName },
          },
        });

        const updatedIndexReport = {
          ...indexReport,
          sections: indexReport.sections.map((section: any) => {
            if (section.id === sectionId) {
              return {
                ...section,
                name: sectionName,
              };
            }
            return section;
          }),
        };

        setIndexReport(updatedIndexReport);
        toast.success('Successfully updated section name.');
      } catch (error) {
        console.error(error);
        toast.error('There was an error updating the section name.');
      }
    },
    [indexReport],
  );

  const updateIndexRow = async (updatedRow: any, originalRow: any) => {
    const sectionId = updatedRow.section_id;
    // 1. If shouldn't update, return
    if (!shouldUpdateTableRow(updatedRow, originalRow)) {
      return originalRow;
    }

    // 2. If the tags have changed, update them, then trigger refresh as
    // sections may change
    if (!_.isEqual(updatedRow.content_tags, originalRow.content_tags)) {
      try {
        await bulkUpdatePageTags({
          where: {
            timeline_entry_id: updatedRow.id,
          },
          data: {
            tagsIds: updatedRow.content_tags.map((tag) => tag.id),
            onlyDismissEntryTags: true,
          },
        });
        refreshIndexReport();
        toast.success('Successfully updated tags.');
        return updatedRow;
      } catch (error) {
        toast.error('There was an error updating the tags.');
        return originalRow;
      }
    }

    // 3. if the document name has changed, update it
    if (updatedRow.document_name !== originalRow.document_name) {
      try {
        await editDocumentName(updatedRow.id, updatedRow.document_name);
        if (updatedRow.document_name === '' || updatedRow.document_name == null) {
          refreshIndexReport();
        } else {
          updateIndexRowCache(sectionId, updatedRow.id, {
            document_name: updatedRow.document_name,
          });
        }
        toast.success('Successfully updated document name.');
        return updatedRow;
      } catch (error) {
        toast.error('There was an error updating the document name.');
        return originalRow;
      }
    }

    // 4. if the monetary value has changed, update it
    if (updatedRow.monetary_total !== originalRow.monetary_total) {
      try {
        await editMonetaryTotal(updatedRow.id, updatedRow.monetary_total);
        updateIndexRowCache(sectionId, updatedRow.id, {
          monetary_total: updatedRow.monetary_total,
        });
        toast.success('Successfully updated monetary total.');
        return updatedRow;
      } catch (error) {
        toast.error('There was an error updating the monetary total.');
        return originalRow;
      }
    }

    // org, author, or date has changed,
    try {
      const formattedEntryDate = formatEntryDateToString(updatedRow.entry_date);
      const updateData: ChangeTimelineEntryDetailsInput = {
        caseID: caseID,
        entryDate: formattedEntryDate,
        entryID: updatedRow.id,
        timelineID: indexReport.timeline_id,
        sourceID: updatedRow.source_id,
        organization: {
          name: updatedRow.organization_name,
        },
        author: {
          name: updatedRow.author_name,
        },
      };
      const resp = await editTimelineEntry(updateData);
      const updatedEntry = resp?.data?.changeTimelineEntryDetails;
      if (updatedEntry) {
        updateIndexRowCache(updatedRow.section_id, updatedRow.id, {
          entry_date: formattedEntryDate,
          organization_name: updatedEntry?.organizations?.value ?? null,
          org_id: updatedEntry?.organizations?.id ?? null,
          author_name: updatedEntry?.authors?.value ?? null,
          author_id: updatedEntry?.authors?.id ?? null,
        });
      }
      toast.success('Successfully updated entry details.');
      return { ...updatedRow, entry_date: formattedEntryDate };
    } catch (error) {
      toast.error('There was an error updating the entry details.');
      return originalRow;
    }
  };

  /*
    Custom table sort change implementation, removes 'unselected' sort model
    And instead toggles between 'asc' and 'desc' sort
  */
  const handleSortChange = (newSortModel: GridSortItem[]) => {
    // if unsorted, set to ascending instead
    if (newSortModel.length === 0) {
      newSortModel.push({
        field: sortModel[0].field,
        sort: 'asc',
      });
    }
    setSortModel(newSortModel);
  };

  const updateIndexRowCache = (sectionId: string, rowId: string, updatedValues: any) => {
    const sectionRows = indexReport.sections.find((section: any) => section.id === sectionId)?.rows;

    if (!sectionRows) {
      return;
    }

    const rowIndex = sectionRows.findIndex((row: any) => row.id === rowId);

    const existingValues = sectionRows[rowIndex];

    const updatedRow = {
      ...existingValues,
      ...updatedValues,
    };

    sectionRows[rowIndex] = updatedRow;
    setIndexReport({
      ...indexReport,
      sections: indexReport.sections.map((section: any) => {
        if (section.id === sectionId) {
          return {
            ...section,
            rows: sectionRows,
          };
        }
        return section;
      }),
    });
  };

  const handleUpdateDuplicateDocumentV1Cases = async (
    entryId: string,
    sectionId: string,
    isDuplicate: boolean,
  ) => {
    await updateDuplicateDocument({
      entryId: entryId,
      isDuplicate,
    });

    if (entryId === documentComparerDocumentIds.firstDocumentID) {
      setComparisonDocumentsIsDuplicate((prevState) => ({
        ...prevState,
        isFirstComparisonDocumentDuplicate: isDuplicate,
      }));
    } else if (entryId === documentComparerDocumentIds.secondDocumentID) {
      setComparisonDocumentsIsDuplicate((prevState) => ({
        ...prevState,
        isSecondComparisonDocumentDuplicate: isDuplicate,
      }));
    }
    updateIndexRowCache(sectionId, entryId, { all_pages_marked_duplicate: isDuplicate });
  };

  const handleSetFirstDocumentComparisonID = useCallback(
    (documentID: bigint, isDocumentDuplicate: boolean, sectionId: string) => {
      setDocumentComparerDocumentIds({
        firstDocumentID: String(documentID),
      });
      setComparisonDocumentsIsDuplicate({
        isFirstComparisonDocumentDuplicate: isDocumentDuplicate,
      });
      // this needs to be the section id
      setComparisonDocumentsSectionIDs({
        firstComparisonDocumentSectionID: sectionId,
      });
    },
    [],
  );

  const handleSetSecondDocumentComparisonIDAndOpenModal = useCallback(
    (documentID: string, isDocumentDuplicate: boolean, sectionId: string) => {
      setDocumentComparerDocumentIds({
        ...documentComparerDocumentIds,
        secondDocumentID: String(documentID),
      });
      setComparisonDocumentsIsDuplicate((prevState) => ({
        ...prevState,
        isSecondComparisonDocumentDuplicate: isDocumentDuplicate,
      }));
      setComparisonDocumentsSectionIDs((prevState) => ({
        ...prevState,
        secondComparisonDocumentSectionID: sectionId,
      }));
      setIsDocumentComparerModalOpen(true);
    },
    [documentComparerDocumentIds, firstComparisonDocumentSectionID],
  );

  const clearDocumentComparisonIDs = useCallback(() => {
    setDocumentComparerDocumentIds({});
    setComparisonDocumentsIsDuplicate({});
    setComparisonDocumentsSectionIDs({});
  }, []);

  const handleUpdateTimelineEntryFromDocumentPreview = async (
    caseID: string,
    entryID: bigint,
    sectionID: string,
    valuesToUpdate: TimelineDetailsProps,
  ) => {
    try {
      await updateTimelineEntriesForReportSection({
        caseID,
        entryID,
        valuesToUpdate,
      });
      // may have to map the the cache values here
      if (
        Object.prototype.hasOwnProperty.call(valuesToUpdate, 'documentName') &&
        (valuesToUpdate.documentName === '' || valuesToUpdate.documentName == null)
      ) {
        refreshIndexReport();
      } else {
        updateIndexRowCache(sectionID, String(entryID), {
          ...valuesToUpdate,
          ...(valuesToUpdate.organization && {
            organization_name: valuesToUpdate.organization?.label,
            org_id: valuesToUpdate.organization?.id,
          }),
          ...(valuesToUpdate.author && {
            author_name: valuesToUpdate.author?.label,
            author_id: valuesToUpdate.author?.id,
          }),
        });
      }
      toast.success('Successfully updated timeline entry.');
    } catch (error) {
      toast.error('There was an error updating the document.');
      console.error(error);
    }
  };

  const closeDocumentComparerModal = useCallback(() => {
    setIsDocumentComparerModalOpen(false);
    clearDocumentComparisonIDs();
    setComparisonDocumentsSectionIDs({});
  }, [clearDocumentComparisonIDs]);

  const sortedIndexReport = useMemo(() => {
    const sortField = sortModel[0].field;
    const sortDirection = sortModel[0].sort;

    return {
      ...indexReport,
      sections: indexReport?.sections?.map((section: any) => {
        const sortedRows = section.rows
          ? customMultiSort(section.rows, sortField, sortDirection)
          : [];
        return {
          ...section,
          rows: sortedRows,
        };
      }),
    };
  }, [indexReport, sortModel]);

  const markImportant = useUpdateMarkedImportant();

  const handleChangeImportanceMark = useCallback(
    async (documentId: string, newImportance: boolean) => {
      try {
        await markImportant.mutateAsync({
          entryID: documentId,
          markedImportant: newImportance,
        });
        refreshIndexReport();
        toast.success(`Document flagged as ${newImportance ? 'important' : 'not important'}`);
      } catch (error) {
        Sentry.captureException(error);
        toast.error('Error updating importance');
      }
    },
    [],
  );

  const configureOptions = [
    {
      label: 'Show Duplicate Documents',
      onClick: () => setShouldShowDuplicates(!shouldShowDuplicates),
      checked: shouldShowDuplicates,
    },
    {
      label: 'Show Empty Sections',
      onClick: () => setShouldShowEmptySections(!shouldShowEmptySections),
      checked: shouldShowEmptySections,
    },
  ];

  return {
    data: sortedIndexReport,
    isLoading: indexReportResp.status === 'pending' && indexReport == null,
    isError: indexReportResp.status === 'rejected',
    shouldShowDuplicates,
    setShouldShowDuplicates,
    shouldShowEmptySections,
    setShouldShowEmptySections,
    configureOptions,
    uploadDates,
    setUploadDates,
    updateIndexReportName,
    updateIndexReportSectionName,
    areIndexSectionsEmpty,
    sortModel,
    handleSortChange,
    updateIndexRow,
    updateIndexRowCache,
    handleUpdateDuplicateDocumentV1Cases,
    isFirstComparisonDocumentDuplicate,
    isSecondComparisonDocumentDuplicate,
    setComparisonDocumentsIsDuplicate,
    clearDocumentComparisonIDs,
    closeDocumentComparerModal,
    handleSetFirstDocumentComparisonID,
    handleSetSecondDocumentComparisonIDAndOpenModal,
    isDocumentComparerModalOpen,
    documentComparerDocumentIds,
    setDocumentComparerDocumentIds,
    firstComparisonDocumentSectionID,
    secondComparisonDocumentSectionID,
    handleUpdateTimelineEntryFromDocumentPreview,
    refreshIndexReport,
    handleChangeImportanceMark,
  };
}
