/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useLayoutEffect,
  useContext,
} from 'react';

import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import {
  ZoomIn,
  ZoomOut,
  RotateLeft,
  RotateRight,
  ArrowBack,
  FlagOutlined,
  Flag,
} from '@mui/icons-material';
import {
  Divider,
  Box,
  Tooltip,
  TextField,
  Skeleton,
  Button,
  CircularProgress,
} from '@mui/material';
import { Link, useSearchParams, useParams, useLocation } from 'react-router-dom';

import Modal from '../../../../components/common/HTML_components/Modal/Modal';
import { useAsync } from '../../../../hooks/useAsync';
import { getDocumentPreview } from '../../../../api';
import CloseIcon from '../../../../components/icons/Close';
import NavigationButton from '../../../../components/common/PdfNavigation/Components/NavigationButton';
import useEntryPages from '../../../Timeline/useEntryPages';
import { PageDate, useUpdatePageRotationMutation } from '../../../../__generated__/graphql';
import { PreviewEntryDetails } from '../IndexReportTable';
import IconVisible from '../../../../components/icons/IconVisible';
import IconHidden from '../../../../components/icons/IconHidden';
import { useUpdateDocumentHiddenStatus } from '../../../Timeline/api-queries/useUpdateDocumentHiddenStatus';
import Theme from '../../../../theme';
import GoToSource from '../../../../components/icons/GoToSource';
import { EditDocumentDetails } from './EditDocumentDetails';
import { useIsFileProcessor } from '../../../AccountSettings/useFileProcessing';
import PageControls from '../../../../components/DocumentComparer/components/PageControls';
import DateHighlight from '../../../../components/DocumentScrolling/DateHighlight';
import useDisplayStore from '../../../Timeline/useDisplayStore';
import PDFViewerPage from '../../../../components/DocumentScrolling/components/PdfViewerPage';
import CaseContext from '../../../Case/CaseContext';
import usePreviewPages from './usePreviewPages';
import { useFetchTimelineEntryDocumentDetails } from '../../api-queries/useGetTimelineEntriesForReportSection';
import { useTimelineList } from '../../../Timeline/useTimeline';

type DocumentPreviewerModalProps = {
  caseId: string;
  isFirstComparisonDocumentDuplicate: boolean;
  isSecondComparisonDocumentDuplicate: boolean;
  documentId: string | null;
  firstPageID: bigint | null;
  currentEntryDetails: PreviewEntryDetails;
  setCurrentEntryDetails: (details: PreviewEntryDetails) => void;
  setSelectedDate: (date: string) => void;
  selectedDate: string | null;
  onClose: () => void;
  onUpdate: (
    caseID: string | undefined,
    entryID: bigint,
    sectionID: number,
    valuesToUpdate: TimelineDetailsProps,
  ) => void;
  isDocumentDuplicate: boolean;
  isUpdating: boolean;

  sectionName: string;
  handleChangeImportanceMark: (documentId: string, newImportance: boolean) => void;
  refreshIndexReport?: () => void;
  updateIndexRowCache?: (sectionId: string, rowId: string, updatedValues: any) => void;
  navigateDocument: (direction: 'previous' | 'next') => void;
  lastDocumentOfReport: boolean;
  firstDocumentOfReport: boolean;
  file_id?: string;
};

export type TimelineDetailsProps = {
  date?: string;
  documentName?: string;
  author?: { id: number | null; name: string | null; label: string | null };
  organization?: { id: number | null; name: string | null; label: string | null };
  source?: bigint;
  monetary_total?: string;
  extracted_dates?: PageDate[];
  marked_important?: boolean;
};

type PageRotation = { id: string; rotation_angle: number; page_number: number; file_name: string };

export function DocumentPreviewerModal({
  caseId,
  isUpdating,
  isDocumentDuplicate,
  documentId,
  file_id,
  firstPageID,
  currentEntryDetails,
  setCurrentEntryDetails,
  onClose,
  onUpdate,
  setSelectedDate,
  sectionName,
  handleChangeImportanceMark,
  refreshIndexReport,
  updateIndexRowCache,
  navigateDocument,
  lastDocumentOfReport,
  firstDocumentOfReport,
}: DocumentPreviewerModalProps) {
  const [zoom, setZoom] = useState(1);
  const [updatingDocumentName, setUpdatingDocumentName] = useState(false);
  const [documentName, setDocumentName] = useState(currentEntryDetails?.documentName ?? '');
  useEffect(() => {
    if (currentEntryDetails.documentName !== documentName) {
      setDocumentName(currentEntryDetails.documentName);
    }
  }, [currentEntryDetails.documentName]);

  const queryClient = useQueryClient();
  const isFileProcessor = useIsFileProcessor();
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    setCurrentPageID(null);
    setZoom(1);
    onClose();
  };

  const { data } = useFetchTimelineEntryDocumentDetails(caseId, documentId);

  const [pageRotationMutation] = useUpdatePageRotationMutation();
  const updateDocumentHiddenStatus = useUpdateDocumentHiddenStatus();

  const { data: previewPages } = usePreviewPages({ caseId, documentId, hideBlanks: true });

  const saveRotation = useCallback(
    async (pageID: string, newRotation: number) => {
      try {
        await pageRotationMutation({
          variables: {
            data: { pageID, rotation_angle: newRotation },
          },
        });
        queryClient.cancelQueries(['pages', documentId]);
        const previousPages = queryClient.getQueryData(['pages', documentId]);
        const newPagesArr = previousPages as Array<PageRotation>;
        const pageIndex = newPagesArr.findIndex((p) => +p.id === +pageID);
        newPagesArr[pageIndex] = {
          ...newPagesArr[pageIndex],
          rotation_angle: newRotation,
        };
        queryClient.setQueryData(['pages', documentId], newPagesArr);
        toast.success('Page rotation saved');
      } catch (error) {
        toast.error('Failed to save page rotation');
        Sentry.captureException(error);
      }
    },
    [pageRotationMutation, queryClient, documentId],
  );

  const getVisiblePageId = useCallback(() => {
    const images = Array.from(document.querySelectorAll('.image')) as HTMLImageElement[];
    const container = document.getElementById('doc-previewer-modal')!;
    const containerRect = container.getBoundingClientRect();
    const containerArea = containerRect.width * containerRect.height;
    let maxVisibleArea = 0;
    let mostVisiblePage: HTMLImageElement | null = null;
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      const rect = image.getBoundingClientRect();
      const visibleArea =
        ((Math.min(rect.right, containerRect.right) - Math.max(rect.left, containerRect.left)) *
          (Math.min(rect.bottom, containerRect.bottom) - Math.max(rect.top, containerRect.top))) /
        containerArea;
      if (visibleArea > maxVisibleArea) {
        maxVisibleArea = visibleArea;
        mostVisiblePage = image;
      }
    }
    return mostVisiblePage?.id;
  }, []);

  const rotatePage = useCallback(
    (direction: 'clockwise' | 'counterClockwise') => {
      const pageID = getVisiblePageId();
      const currentRotation =
        previewPages?.find((p) => String(p.id) === pageID)?.pageDetails?.rotation_angle ?? 0;
      if (pageID) {
        if (direction === 'clockwise') {
          saveRotation(pageID, currentRotation === 270 ? 0 : currentRotation + 90);
        } else if (direction === 'counterClockwise') {
          saveRotation(pageID, currentRotation === 0 ? 270 : currentRotation - 90);
        }
      }
    },
    [getVisiblePageId, previewPages, saveRotation],
  );

  const [currentPageID, setCurrentPageID] = useState<string | null>(
    previewPages ? previewPages[0]?.id : null,
  );

  const getCurrentPageID = useCallback(
    () => setCurrentPageID(getVisiblePageId() ?? null),
    [getVisiblePageId],
  );

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }
    scrollRef.current.addEventListener('scrollend', getCurrentPageID);
    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scrollend', getCurrentPageID);
      }
    };
  }, [scrollRef, previewPages]);

  useEffect(() => {
    if (previewPages && previewPages?.length > 0) {
      setCurrentPageID(previewPages[0].id);
    }
  }, [previewPages]);

  const handleUpdateDocumentName = useCallback(() => {
    if (
      documentName?.length > 0 &&
      documentName !== currentEntryDetails?.documentName &&
      documentId
    ) {
      onUpdate(caseId, documentId, currentEntryDetails.sectionId, {
        documentName,
      });
      if (updateIndexRowCache) {
        updateIndexRowCache(currentEntryDetails.sectionId, String(documentId) ?? '', {
          document_name: documentName,
        });
        setCurrentEntryDetails({
          ...currentEntryDetails,
          documentName,
        });
      }
    }
    setUpdatingDocumentName(false);
  }, [
    documentName,
    currentEntryDetails,
    documentId,
    caseId,
    onUpdate,
    updateIndexRowCache,
    setCurrentEntryDetails,
    setUpdatingDocumentName,
  ]);

  const [inputWidth, setInputWidth] = useState(0);
  const inputRef = useRef(null);

  useLayoutEffect(() => {
    if (inputRef.current) {
      const extraWidth = 10;
      const tempElement = document.createElement('span');
      tempElement.style.fontSize = window.getComputedStyle(inputRef.current).fontSize;
      tempElement.style.whiteSpace = 'pre';
      tempElement.textContent = currentEntryDetails?.documentName;
      document.body.appendChild(tempElement);
      const calculatedWidth = tempElement.getBoundingClientRect().width + extraWidth;
      document.body.removeChild(tempElement);
      setInputWidth(calculatedWidth < 165 ? calculatedWidth : 165);
    }
  }, [currentEntryDetails?.documentName, documentName]);

  const FlagComponent = currentEntryDetails.markedImportant ? Flag : FlagOutlined;

  const currentIndex = useMemo(
    () => previewPages?.findIndex((page) => +page.id === +(currentPageID ?? '')),
    [previewPages, currentPageID],
  );

  return (
    <Modal
      key={documentId}
      isOpen={documentId != null}
      style={{ width: '80%', height: '92%', overflowY: 'hidden' }}
      onClose={handleClose}
    >
      <div style={{ width: '100%' }} id="doc-previewer-modal">
        <div
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            padding: '1.5rem',
            paddingBottom: 0,
            width: '100%',
            overflow: 'hidden',
            zIndex: 1,
          }}
        >
          <h1 style={{ fontSize: '1.1rem', fontWeight: 500 }}>Document Preview</h1>
          <button
            className="sm-icon-button"
            type="button"
            style={{ position: 'absolute', top: '1rem', right: '1rem' }}
            onClick={onClose}
          >
            <CloseIcon />
          </button>
          <hr />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '30%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                marginRight: '1.5rem',
              }}
            >
              <p
                style={{
                  fontSize: '0.85rem',
                  marginRight: '0.2rem',
                  marginTop: '0.18rem',
                  maxWidth: '140px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {sectionName}
              </p>
              <p style={{ fontSize: '0.85rem', marginRight: '0.2rem', marginTop: '0.18rem' }}>|</p>
              <TextField
                variant="standard"
                inputRef={inputRef}
                InputProps={{
                  disableUnderline: true,
                  sx: {
                    width: `${inputWidth}px`,
                    fontSize: '0.8rem',
                    color: '#212529',
                    backgroundColor: updatingDocumentName ? '#F2F4F7' : '',
                    border: updatingDocumentName ? '1px solid #98A2B3' : '',
                    borderRadius: '3px',
                  },
                }}
                sx={{
                  input: { cursor: 'pointer' },
                  marginRight: '0.7rem',
                  borderRadius: '3px',
                  '& .MuiInputBase-input': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                  width: `${inputWidth}px`,
                }}
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
                onClick={() => setUpdatingDocumentName(true)}
                onBlur={handleUpdateDocumentName}
              />
              <IndexPreviewGoToSource
                isFileProcessor={isFileProcessor}
                file_id={file_id ?? ''}
                documentID={documentId}
                firstPageID={String(firstPageID)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '70%',
                gap: '10px',
                whiteSpace: 'nowrap',
              }}
            >
              <button
                type="button"
                className="nav-button"
                onClick={() => navigateDocument('previous')}
                disabled={firstDocumentOfReport}
              >
                <ArrowBack sx={{ mr: 0.8, fontSize: '17px' }} />
                Previous Document
              </button>
              <div
                style={{
                  fontSize: '0.9rem',
                  marginTop: '0.2rem',
                }}
              >
                {currentIndex !== -1 ? (currentIndex ?? 0) + 1 : 1} of{' '}
                {previewPages?.length ?? (
                  <Skeleton
                    variant="rectangular"
                    width={15}
                    height={16}
                    sx={{ borderRadius: '8px', display: 'inline-block' }}
                  />
                )}
              </div>
              <button
                type="button"
                className="nav-button"
                onClick={() => navigateDocument('next')}
                disabled={lastDocumentOfReport}
              >
                Next Document
                <ArrowBack sx={{ ml: 0.8, rotate: '180deg', fontSize: '17px' }} />
              </button>
              <NavigationButton
                onClick={() => setZoom(zoom - 0.1)}
                sx={{ opacity: '100%', backgroundColor: 'white !important' }}
                icon={
                  <ZoomOut
                    sx={{
                      fontSize: '1rem',
                      color: zoom <= 0.5 ? 'inherit' : '#344054',
                    }}
                  />
                }
                disabled={zoom <= 0.5}
              />
              <NavigationButton
                onClick={() => setZoom(zoom + 0.1)}
                sx={{ mr: 1, opacity: '100%', backgroundColor: 'white !important' }}
                icon={
                  <ZoomIn
                    sx={{
                      fontSize: '1rem',
                      color: zoom >= 1.4 ? 'inherit' : '#344054',
                    }}
                  />
                }
                disabled={zoom >= 1.4}
              />
            </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div
            id="scrollable-viewer"
            className="scrollable-viewer"
            style={{ width: '70%' }}
            ref={scrollRef}
          >
            {documentId && (
              <DocumentPreviewer
                isDocumentDuplicate={isDocumentDuplicate}
                zoom={zoom}
                caseId={caseId}
                currentPageID={currentPageID}
                documentId={documentId}
                hideBlanks={true}
                pageHeader={false}
                selectedDate={currentEntryDetails.entryDate}
                extractedDates={data?.extracted_dates ?? []}
                setSelectedDate={setSelectedDate}
                isUpdating={isUpdating}
              />
            )}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div className="viewer-buttons-container-rotations">
              <NavigationButton
                onClick={() => rotatePage('counterClockwise')}
                sx={{ mr: 0.5, opacity: '100%', backgroundColor: 'white !important' }}
                icon={
                  <RotateLeft
                    sx={{
                      fontSize: '1rem',
                    }}
                  />
                }
              />
              <NavigationButton
                onClick={() => rotatePage('clockwise')}
                sx={{ mr: 1.5, opacity: '100%', backgroundColor: 'white !important' }}
                icon={
                  <RotateRight
                    sx={{
                      fontSize: '1rem',
                    }}
                  />
                }
              />
            </div>
          </div>
          <div id="side-edit-menu" style={{ width: '30%' }}>
            <div style={{ marginLeft: '20px' }} id="actions-box">
              <h2 style={{ textAlign: 'left', fontSize: '0.95rem', fontWeight: 500 }}>Actions</h2>

              {!isFileProcessor && (
                <div
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => {
                    handleChangeImportanceMark(
                      String(documentId),
                      !currentEntryDetails.markedImportant,
                    );
                    setCurrentEntryDetails({
                      ...currentEntryDetails,
                      markedImportant: !currentEntryDetails.markedImportant,
                    });
                    if (updateIndexRowCache) {
                      updateIndexRowCache(currentEntryDetails.sectionId, String(documentId) ?? '', {
                        marked_important: !currentEntryDetails.markedImportant,
                      });
                    }
                  }}
                >
                  <span>
                    <FlagComponent
                      style={{
                        cursor: 'pointer',
                        color: Theme.palette.primary.light,
                      }}
                    />
                  </span>
                  <span
                    style={{
                      marginLeft: '8px',
                      marginTop: '5px',
                      textAlign: 'left',
                      fontSize: '0.85rem',
                      fontWeight: 600,
                      color: Theme.palette.primary.light,
                    }}
                  >
                    {currentEntryDetails.markedImportant ? 'Unflag' : 'Flag'}
                  </span>
                </div>
              )}
              {currentEntryDetails.isHidden ? (
                <div
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => {
                    updateDocumentHiddenStatus(documentId, false);
                    setCurrentEntryDetails({ ...currentEntryDetails, isHidden: false });
                    if (updateIndexRowCache) {
                      updateIndexRowCache(currentEntryDetails.sectionId, String(documentId) ?? '', {
                        is_hidden: 0,
                      });
                    }
                  }}
                >
                  <span>
                    <IconVisible
                      style={{
                        cursor: 'pointer',
                        color: Theme.palette.primary.light,
                      }}
                    />
                  </span>
                  <span
                    style={{
                      marginLeft: '8px',
                      marginTop: '5px',
                      textAlign: 'left',
                      fontSize: '0.85rem',
                      fontWeight: 600,
                      color: Theme.palette.primary.light,
                    }}
                  >
                    Show
                  </span>
                </div>
              ) : (
                <div
                  style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                  onClick={() => {
                    updateDocumentHiddenStatus(documentId, true);
                    setCurrentEntryDetails({ ...currentEntryDetails, isHidden: true });
                    if (updateIndexRowCache) {
                      updateIndexRowCache(currentEntryDetails.sectionId, documentId ?? '', {
                        is_hidden: 1,
                      });
                    }
                  }}
                >
                  <span>
                    <IconHidden
                      style={{
                        cursor: 'pointer',
                        color: Theme.palette.primary.light,
                      }}
                    />
                  </span>
                  <span
                    style={{
                      marginLeft: '8px',
                      marginTop: '5px',
                      textAlign: 'left',
                      fontSize: '0.85rem',
                      fontWeight: 600,
                      color: Theme.palette.primary.light,
                    }}
                  >
                    Hide
                  </span>
                </div>
              )}
            </div>
            <Divider
              variant="middle"
              sx={{
                marginTop: '10px',
                marginBottom: '5px',
                width: '85%',
              }}
            />
            <EditDocumentDetails
              entryID={Number(documentId)}
              pageID={currentPageID}
              caseID={caseId}
              currentEntryDetails={currentEntryDetails}
              setCurrentEntryDetails={setCurrentEntryDetails}
              onUpdate={onUpdate}
              isFileProcessor={isFileProcessor}
              setSelectedDate={setSelectedDate}
              refreshIndexReport={refreshIndexReport}
              updateIndexRowCache={updateIndexRowCache}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

type DocumentPreviewerProps = {
  caseId: string;
  documentId: number;
  zoom: number;
  entryPages?: any;
  hideBlanks: boolean;
  currentPageID: string | null;
  pageHeader: boolean;
  rotateClockwise?: (pageID: string) => void;
  rotateCounterClockwise?: (pageID: string) => void;
  extractedDates: PageDate[];
  selectedDate: string | null;
  setSelectedDate: (date: string) => void;
  isFileProcessor?: boolean;
  markAsDuplicate?: () => void;
  isDocumentDuplicate: boolean;
  isUpdating: boolean;
};

export function DocumentPreviewer({
  caseId,
  isUpdating,
  documentId,
  zoom,
  isDocumentDuplicate,
  entryPages,
  hideBlanks,
  currentPageID,
  markAsDuplicate,
  pageHeader = false,
  rotateClockwise = () => {},
  rotateCounterClockwise = () => {},
  extractedDates,
  selectedDate,
  setSelectedDate,
  isFileProcessor,
}: DocumentPreviewerProps) {
  const [result] = useAsync(
    () => getDocumentPreview({ caseId, documentID: documentId, hideBlanks }),
    [documentId, hideBlanks],
  );
  const { caseInstance } = useContext(CaseContext);

  const caseVersion = useMemo(() => caseInstance?.version, [caseInstance]);

  const filteredEntryPages = useMemo(() => {
    return entryPages?.filter(
      (entryPage) => result.data?.data?.pages.some((page) => page.id === entryPage.id),
    );
  }, [entryPages, result.data?.data?.pages]);

  const {
    data: previewPages,
    isLoading,
    isError,
  } = usePreviewPages({ caseId, documentId, hideBlanks });
  const { documentWidth, documentHeight } = useDisplayStore((state) => state.PDFDimensions);

  const filteredExtractedDates = useMemo(() => {
    const uniqueDates = new Set();
    return extractedDates?.filter((date) => {
      if (!date.id || !date.sx || !date.sy || !date.ex || !date.ey) {
        return false;
      }

      // Check if the date belongs to the current page
      if (String(date.page_id) !== String(currentPageID)) {
        return false;
      }

      // Create a unique key for this combination of values
      const key = `${date.id}-${date.sx}-${date.sy}-${date.ex}-${date.ey}`;
      if (uniqueDates.has(key)) {
        return false;
      }

      uniqueDates.add(key);
      return true;
    });
  }, [extractedDates, currentPageID]);

  if (isLoading) {
    return <>Loading...</>;
  }
  if (result.status === 'rejected') {
    return <>Something went wrong, please try again later.</>;
  }
  return (
    <div
      style={{
        paddingBottom: '5rem',
        display: 'flex',
        alignItems: pageHeader ? '' : 'center',
        flexDirection: 'column',
      }}
    >
      {isUpdating ? (
        <CircularProgress sx={{ margin: '8rem auto', display: 'flex', justifyContent: 'center' }} />
      ) : (
        previewPages?.map((page, currentIndex) => {
          return (
            <>
              {pageHeader && (
                <PreviewPageHeader
                  filteredEntryPages={filteredEntryPages}
                  caseVersion={caseVersion}
                  duplicateDisplayed={false}
                  isDocumentDuplicate={isDocumentDuplicate}
                  documentId={documentId}
                  isFileProcessor={isFileProcessor}
                  currentPage={page}
                  currentIndex={currentIndex}
                  rotateClockwise={rotateClockwise}
                  rotateCounterClockwise={rotateCounterClockwise}
                  markAsDuplicate={markAsDuplicate}
                />
              )}
              <Box
                id={`${page.id}`}
                className="image"
                sx={{
                  position: 'relative',
                  width: `${
                    page.pageDetails.rotation_angle === 90 ||
                    page.pageDetails.rotation_angle === 270
                      ? (documentHeight * zoom).toString().concat('px')
                      : (documentWidth * zoom).toString().concat('px')
                  } !important`,
                  height: `${
                    page.pageDetails.rotation_angle === 90 ||
                    page.pageDetails.rotation_angle === 270
                      ? (documentWidth * zoom).toString().concat('px')
                      : (documentHeight * zoom).toString().concat('px')
                  } !important`,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <PDFViewerPage
                  url={page.url}
                  width={documentWidth}
                  height={documentHeight}
                  scale={zoom}
                  rotate={page.pageDetails.rotation_angle ?? 0}
                  currentPage={String(currentPageID) === String(page.id)}
                />
                <DateHighlight
                  entryID={documentId}
                  dates={filteredExtractedDates}
                  selectedDate={selectedDate ?? ''}
                  setSelectedDate={setSelectedDate}
                  rotation={page.pageDetails.rotation_angle ?? 0}
                />
              </Box>
            </>
          );
        })
      )}
    </div>
  );
}

function PreviewPageHeader({
  currentPage,
  currentIndex,
  rotateClockwise,
  rotateCounterClockwise,
  documentId,
  markAsDuplicate,
  caseVersion,
  duplicateDisplayed,
  isDocumentDuplicate,
  filteredEntryPages,

  isFileProcessor,
}: {
  currentPage: {
    page_number: number;
    file_name: string;
    id: string;
    pageDetails: {
      page_number: number;
      rotation_angle: number;
      file_name: string;
      file_id: string;
    };
  };
  currentIndex: number;
  filteredEntryPages: number[];
  rotateClockwise: () => void;
  rotateCounterClockwise: () => void;
  documentId: string;
  markAsDuplicate: () => void;
  caseVersion?: number;
  duplicateDisplayed?: boolean;
  isDocumentDuplicate?: boolean;

  isFileProcessor?: boolean;
}) {
  return (
    <div
      style={{
        display: 'flex',
        marginLeft: '8px',
        marginRight: '8px',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '12px',
          paddingRight: '2px',
          marginTop: '8px',
          color: Theme.palette.duplicatesByDocumentBlue.main,
        }}
      >
        Page {currentIndex + 1}/{filteredEntryPages?.length}
      </div>
      <Divider
        orientation="vertical"
        sx={{
          height: '20px',
          display: 'inline-flex',
          marginTop: '8px',
          marginBottom: '-5px',
          bgcolor: Theme.palette.duplicatesByDocumentBlue.main,
        }}
      />
      <div
        style={{
          display: 'inline-flex',
          fontWeight: 600,
          fontSize: '12px',
          paddingLeft: '5px',
          paddingRight: '5px',
          marginTop: '10px',
          marginBottom: '2px',
          color: Theme.palette.duplicatesByDocumentBlue.main,
        }}
      >
        <div
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            width: '8rem',
            whiteSpace: 'nowrap',
          }}
        >
          {currentPage?.pageDetails?.file_name ?? ''}
        </div>

        <IndexPreviewGoToSource
          isFileProcessor={isFileProcessor ?? true}
          file_id={currentPage?.pageDetails?.file_id ?? ''}
          documentID={documentId}
          firstPageID={currentPage.id}
        />
      </div>

      {currentPage?.id && (
        <PageControls
          rotateCounterClockwise={() => rotateCounterClockwise(currentPage.id)}
          rotateClockwise={() => rotateClockwise(currentPage.id)}
        />
      )}
      {caseVersion !== 1 &&
        !duplicateDisplayed &&
        (isDocumentDuplicate ? (
          <span
            style={{
              color: Theme.palette.duplicatesByDocumentRed.main,
              fontWeight: 600,
            }}
          >
            DUPLICATE
          </span>
        ) : (
          <Button
            sx={{
              backgroundColor: 'primary.dark',
              color: 'white',
              border: '1px solid',
              borderColor: 'caseStatus.processorActionButtonBackground',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '18px',
            }}
            onClick={markAsDuplicate}
          >
            Mark as Duplicate
          </Button>
        ))}
    </div>
  );
}

export function IndexPreviewGoToSource({
  documentID,
  firstPageID,
  isFileProcessor,
  file_id,
  isInTaggingView = false,
}: {
  documentID: string | null;
  firstPageID: string | null;
  file_id: string | null;
  isFileProcessor: boolean;
  isInTaggingView?: boolean;
}) {
  const location = useLocation();
  const { caseID } = useParams<{ caseID: string }>();
  const { data: timelineList } = useTimelineList(caseID);
  const timelineID = timelineList?.find((timeline: { isDefault: number }) => timeline.isDefault).id;
  const isInDuplicatesTab = location.pathname.includes('/duplicates');
  const basePath = isInDuplicatesTab ? location.pathname.split('/duplicates')[0] : `..`;
  const newPath = `${basePath}/timeline/${timelineID}/${documentID}/${firstPageID}${
    isFileProcessor ? `?documentID=${file_id}` : ''
  }`;

  return (
    <Tooltip title="Go to source" placement="top">
      <Link
        to={newPath}
        state={{
          resetFilters: true,
        }}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: 'inherit',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: isInTaggingView ? '0.5rem' : '0.1rem',
        }}
      >
        <GoToSource />
        {isInTaggingView && (
          <span
            style={{
              marginLeft: '9.5px',
              textAlign: 'left',
              fontSize: '0.85rem',
              fontWeight: 600,
              color: Theme.palette.primary.light,
            }}
          >
            Go To Source
          </span>
        )}
      </Link>
    </Tooltip>
  );
}
