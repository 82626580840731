import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type BaseDocumentPagesWhereInput = {
  corrected_page_date?: InputMaybe<DateTimeFilter>;
  file_id?: InputMaybe<StringFilter>;
  is_duplicate?: InputMaybe<IntFilter>;
  is_favourite?: InputMaybe<IntFilter>;
  page_date?: InputMaybe<DateTimeFilter>;
  page_number?: InputMaybe<IntFilter>;
  page_to_tag?: InputMaybe<PageToTagByRelationWhereInput>;
  pages_viewed?: InputMaybe<PagesViewedRelationInput>;
};

export type BaseMultipartDocumentPagesWhereInput = {
  AND?: InputMaybe<Array<BaseDocumentPagesWhereInput>>;
  NOT?: InputMaybe<Array<BaseDocumentPagesWhereInput>>;
  OR?: InputMaybe<Array<BaseDocumentPagesWhereInput>>;
  corrected_page_date?: InputMaybe<DateTimeFilter>;
  file_id?: InputMaybe<StringFilter>;
  is_duplicate?: InputMaybe<IntFilter>;
  is_favourite?: InputMaybe<IntFilter>;
  page_date?: InputMaybe<DateTimeFilter>;
  page_number?: InputMaybe<IntFilter>;
  page_to_tag?: InputMaybe<PageToTagByRelationWhereInput>;
  pages_viewed?: InputMaybe<PagesViewedRelationInput>;
};

export type BaseMultipartPageWhereInput = {
  AND?: InputMaybe<Array<BasePageWhereInput>>;
  NOT?: InputMaybe<Array<BasePageWhereInput>>;
  OR?: InputMaybe<Array<BasePageWhereInput>>;
  corrected_page_date?: InputMaybe<DateTimeFilter>;
  document_id?: InputMaybe<StringFilter>;
  file_id?: InputMaybe<StringFilter>;
  is_duplicate?: InputMaybe<IntFilter>;
  is_favourite?: InputMaybe<IntFilter>;
  page_date?: InputMaybe<DateTimeFilter>;
  page_number?: InputMaybe<IntFilter>;
  page_to_tag?: InputMaybe<PageToTagByRelationWhereInput>;
  pages_viewed?: InputMaybe<PagesViewedRelationInput>;
};

export type BasePageToTagWhereInput = {
  dismissed?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  tag_id?: InputMaybe<IntFilter>;
  tags?: InputMaybe<PageToTagToTagByRelationWhereInput>;
};

export type BasePageWhereInput = {
  corrected_page_date?: InputMaybe<DateTimeFilter>;
  document_id?: InputMaybe<StringFilter>;
  file_id?: InputMaybe<StringFilter>;
  is_duplicate?: InputMaybe<IntFilter>;
  is_favourite?: InputMaybe<IntFilter>;
  page_date?: InputMaybe<DateTimeFilter>;
  page_number?: InputMaybe<IntFilter>;
  page_to_tag?: InputMaybe<PageToTagByRelationWhereInput>;
  pages_viewed?: InputMaybe<PagesViewedRelationInput>;
};

export type BaseTimelineWhereInput = {
  archived_at?: InputMaybe<Scalars['String']['input']>;
  case_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_default?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringFilter>;
  timeline_entries?: InputMaybe<TimelineEntryRelationFilter>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BoolFilter>;
};

export type BreakApartMutationResponse = {
  __typename?: 'BreakApartMutationResponse';
  updatedPages: Array<PageObject>;
};

export type BreakApartTimelineEntriesInput = {
  caseID: Scalars['String']['input'];
  entryID?: InputMaybe<Scalars['BigInt']['input']>;
  timelineID: Scalars['BigInt']['input'];
};

export type BulkUpdatePageTagsDataInput = {
  bulkDismissOnly?: InputMaybe<Scalars['Boolean']['input']>;
  onlyDismissEntryTags?: Scalars['Boolean']['input'];
  shouldInvalidateUnspecifiedTags?: Scalars['Boolean']['input'];
  tagsIds: Array<Scalars['BigInt']['input']>;
};

export type BulkUpdatePageTagsWhereInput = {
  id?: InputMaybe<Scalars['BigInt']['input']>;
  pageIds?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timeline_entry_id?: InputMaybe<Scalars['BigInt']['input']>;
};

export type BulkUpdateResponse = {
  __typename?: 'BulkUpdateResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type BulkUpdateTimelineEntriesInput = {
  timelineId: Scalars['BigInt']['input'];
  updates: Array<TimelineEntryUpdateInput>;
};

export type CaseUserLocationObject = {
  __typename?: 'CaseUserLocationObject';
  case_id: Scalars['String']['output'];
  document_id: Scalars['String']['output'];
  page_id: Scalars['BigInt']['output'];
  timeline_entry_id: Scalars['BigInt']['output'];
  user_id: Scalars['String']['output'];
  view: Scalars['String']['output'];
};

export type CasesObject = {
  __typename?: 'CasesObject';
  archived_at?: Maybe<Scalars['Date']['output']>;
  archived_by?: Maybe<Scalars['String']['output']>;
  case_name: Scalars['String']['output'];
  case_status: Scalars['String']['output'];
  case_user_location?: Maybe<CaseUserLocationObject>;
  claim_reason?: Maybe<Scalars['String']['output']>;
  create_date: Scalars['DateTime']['output'];
  date_of_birth?: Maybe<Scalars['Date']['output']>;
  date_of_injury?: Maybe<Scalars['Date']['output']>;
  due_date?: Maybe<Scalars['Date']['output']>;
  duplicates_regenerate: Scalars['Boolean']['output'];
  full_name?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  is_ready: Scalars['Int']['output'];
  last_opened: Scalars['DateTime']['output'];
  marked_ready_date?: Maybe<Scalars['DateTime']['output']>;
  number_of_pages?: Maybe<Scalars['BigInt']['output']>;
  number_of_pages_viewed?: Maybe<Scalars['BigInt']['output']>;
  processor_assigned?: Maybe<Scalars['String']['output']>;
  ref_id: Scalars['BigInt']['output'];
  similarity_threshold: Scalars['Float']['output'];
  user_id: Scalars['String']['output'];
  version: Scalars['Int']['output'];
};

export type CasesQueryInput = {
  id: Scalars['String']['input'];
};

export type ChangeCaseDetailsInput = {
  caseID: Scalars['String']['input'];
  caseName?: InputMaybe<Scalars['String']['input']>;
  claimReason?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfInjury?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  patientName?: InputMaybe<Scalars['String']['input']>;
};

export type ChangeEntityInput = {
  author?: InputMaybe<TimelineEntriesPersonInput>;
  caseID: Scalars['String']['input'];
  current_page_id?: InputMaybe<Scalars['BigInt']['input']>;
  entryID: Scalars['BigInt']['input'];
  organization?: InputMaybe<TimelineEntriesOrgInput>;
  origin?: InputMaybe<Scalars['String']['input']>;
};

export type ChangeTimelineEntryDetailsInput = {
  author?: InputMaybe<TimelineEntriesPersonInput>;
  caseID: Scalars['String']['input'];
  current_page_id?: InputMaybe<Scalars['BigInt']['input']>;
  date_id?: InputMaybe<Scalars['BigInt']['input']>;
  entryDate: Scalars['DateTime']['input'];
  entryID: Scalars['BigInt']['input'];
  ml_suggested_entity?: InputMaybe<Scalars['String']['input']>;
  organization?: InputMaybe<TimelineEntriesOrgInput>;
  origin?: InputMaybe<Scalars['String']['input']>;
  sourceID: Scalars['BigInt']['input'];
  subject?: InputMaybe<TimelineEntriesPersonInput>;
  timelineID: Scalars['BigInt']['input'];
};

export type CreateCaseInput = {
  archived_at?: InputMaybe<Scalars['DateTime']['input']>;
  archived_by?: InputMaybe<Scalars['String']['input']>;
  case_name: Scalars['String']['input'];
  claimReason?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfInjury?: InputMaybe<Scalars['Date']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  ref_id?: InputMaybe<Scalars['BigInt']['input']>;
  userIds: Array<Scalars['String']['input']>;
  version: Scalars['Int']['input'];
};

export type CreateCustomTagInput = {
  color: Scalars['String']['input'];
  title: Scalars['String']['input'];
  userID: Scalars['String']['input'];
};

export type CreateNoteInput = {
  body: Scalars['String']['input'];
  date?: InputMaybe<Scalars['DateTime']['input']>;
  document_id: Scalars['String']['input'];
  favourite?: InputMaybe<Scalars['Int']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  images?: InputMaybe<Array<NoteImageInput>>;
  page_id: Scalars['BigInt']['input'];
  physician?: InputMaybe<Scalars['String']['input']>;
  private_note: Scalars['Int']['input'];
  tags?: InputMaybe<Array<CreateNoteTagInput>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type CreateNoteTagInput = {
  tag_id: Scalars['String']['input'];
};

export type CreateTimelineReportInput = {
  case_id: Scalars['String']['input'];
  is_auto_generated: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type CreateTimelineReportSectionInput = {
  case_id: Scalars['String']['input'];
  extra_columns?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  section_rules: Scalars['JSON']['input'];
  section_type?: InputMaybe<Scalars['String']['input']>;
  sort_order?: InputMaybe<Scalars['Float']['input']>;
  timeline_report_id: Scalars['String']['input'];
};

export type CustomTag = {
  __typename?: 'CustomTag';
  color: Scalars['String']['output'];
  tagID: Scalars['String']['output'];
  tag_creation_date?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  userID: Scalars['String']['output'];
};

export type CustomTagsWhereInput = {
  color?: InputMaybe<StringFilter>;
  tagID?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
  userID?: InputMaybe<StringFilter>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DocumentDescriptorObject = {
  __typename?: 'DocumentDescriptorObject';
  document_id: Array<Scalars['String']['output']>;
  pages: Array<PageDescriptor>;
};

export enum DocumentErrorType {
  Encrypted = 'ENCRYPTED',
  TooBig = 'TOO_BIG'
}

export type DocumentObject = {
  __typename?: 'DocumentObject';
  author_chunks: Scalars['Float']['output'];
  author_status: Scalars['String']['output'];
  case_id: Scalars['String']['output'];
  classified_chunks: Scalars['Int']['output'];
  completed_duplicate_chunks: Scalars['Float']['output'];
  document_file_name: Scalars['String']['output'];
  document_file_path: Scalars['String']['output'];
  duplicate_status: Scalars['String']['output'];
  error_type?: Maybe<DocumentErrorType>;
  file_status: Scalars['String']['output'];
  id: Scalars['String']['output'];
  is_ready: Scalars['Int']['output'];
  num_duplicate_chunks: Scalars['Float']['output'];
  number_of_chunks: Scalars['Int']['output'];
  number_of_pages: Scalars['BigInt']['output'];
  pageListNodes?: Maybe<Array<PageListNodeObject>>;
  /** Returns a list of pages */
  pages: Array<PageObject>;
  retry: Scalars['Int']['output'];
  upload_date: Scalars['DateTime']['output'];
  uploaded_by?: Maybe<Scalars['String']['output']>;
};


export type DocumentObjectPagesArgs = {
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentPagesWhereInput>;
};

export type DocumentPagesWhereInput = {
  AND?: InputMaybe<Array<MultipartDocumentPagesWhereInput>>;
  NOT?: InputMaybe<Array<MultipartDocumentPagesWhereInput>>;
  OR?: InputMaybe<Array<MultipartDocumentPagesWhereInput>>;
  corrected_page_date?: InputMaybe<DateTimeFilter>;
  file_id?: InputMaybe<StringFilter>;
  is_duplicate?: InputMaybe<IntFilter>;
  is_favourite?: InputMaybe<IntFilter>;
  page_date?: InputMaybe<DateTimeFilter>;
  page_number?: InputMaybe<IntFilter>;
  page_to_tag?: InputMaybe<PageToTagByRelationWhereInput>;
  pages_viewed?: InputMaybe<PagesViewedRelationInput>;
};

export type DocumentWhereUniqueInput = {
  id: Scalars['String']['input'];
};

export type DocumentsOrderByInput = {
  document?: InputMaybe<SortOrder>;
  page_date?: InputMaybe<SortOrder>;
};

export type DocumentsWhereInput = {
  afterDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Allowed content types and specialities */
  allowedPageTags?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  allowedSourceTags?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  beforeDate?: InputMaybe<Scalars['DateTime']['input']>;
  case_id: Scalars['String']['input'];
  document_id?: InputMaybe<Array<Scalars['String']['input']>>;
  endPageNumber?: InputMaybe<Scalars['Int']['input']>;
  hideDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
  hideViewed?: InputMaybe<Scalars['Boolean']['input']>;
  keyword_search_result: PagesKeywordSearchResult;
  showFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  startPageNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type DuplicateSet = {
  __typename?: 'DuplicateSet';
  id: Scalars['BigInt']['output'];
  original_page?: Maybe<DuplicateSetMember>;
  similar_pages?: Maybe<Array<DuplicateSetMember>>;
  viewed_at?: Maybe<Scalars['DateTime']['output']>;
};

export type DuplicateSetMember = {
  __typename?: 'DuplicateSetMember';
  document_file_name: Scalars['String']['output'];
  document_id: Scalars['String']['output'];
  duplicates_set_id: Scalars['BigInt']['output'];
  is_duplicate?: Maybe<Scalars['Boolean']['output']>;
  page_id: Scalars['BigInt']['output'];
  page_number: Scalars['Float']['output'];
  rotation_angle: Scalars['Float']['output'];
  score?: Maybe<Scalars['Float']['output']>;
};

export type DuplicateStats = {
  __typename?: 'DuplicateStats';
  resolved_count: Scalars['Int']['output'];
  unresolved_count: Scalars['Int']['output'];
  unresolved_duplicate_sets_count: Scalars['Int']['output'];
};

export type DuplicatesQueryInput = {
  case_id: Scalars['String']['input'];
};

export type EditDocumentInput = {
  file_status?: InputMaybe<Scalars['String']['input']>;
};

export type EditNoteInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  favourite?: InputMaybe<Scalars['Int']['input']>;
  imagesToAdd?: InputMaybe<Array<NoteImageInput>>;
  imagesToRemove?: InputMaybe<Array<Scalars['Int']['input']>>;
  physician?: InputMaybe<Scalars['String']['input']>;
  private_note?: InputMaybe<Scalars['Boolean']['input']>;
  tagsToAdd?: InputMaybe<Array<Scalars['String']['input']>>;
  tagsToRemove?: InputMaybe<Array<Scalars['String']['input']>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type EditTimelineReportInput = {
  name: Scalars['String']['input'];
};

export type EditTimelineReportSectionInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type EnumFilterBaseClass = {
  equals?: InputMaybe<TagType>;
  in?: InputMaybe<Array<TagType>>;
  not?: InputMaybe<EnumFilterBaseClass>;
  notIn?: InputMaybe<Array<TagType>>;
};

export type HiddenEntitiesResponse = {
  __typename?: 'HiddenEntitiesResponse';
  hiddenEntities: Array<Scalars['String']['output']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type MergeSplitMutationResponse = {
  __typename?: 'MergeSplitMutationResponse';
  deletedPageTimelineEntries: Array<PageTimelineEntriesForDeletion>;
  deletedTimelineEntries?: Maybe<Array<Scalars['BigInt']['output']>>;
  newEntry: TimelineEntryObject;
};

export type MergeSplitTimelineEntriesInput = {
  author?: InputMaybe<TimelineEntriesPersonInput>;
  caseID: Scalars['String']['input'];
  current_page_id?: InputMaybe<Scalars['BigInt']['input']>;
  entryDate?: InputMaybe<Scalars['DateTime']['input']>;
  entryID?: InputMaybe<Scalars['BigInt']['input']>;
  organization?: InputMaybe<TimelineEntriesOrgInput>;
  pages: Array<Scalars['Int']['input']>;
  sourceID: Scalars['BigInt']['input'];
  subject?: InputMaybe<TimelineEntriesPersonInput>;
  timelineID: Scalars['BigInt']['input'];
};

export type MultipartDocumentPagesWhereInput = {
  AND?: InputMaybe<Array<BaseMultipartDocumentPagesWhereInput>>;
  NOT?: InputMaybe<Array<BaseMultipartDocumentPagesWhereInput>>;
  OR?: InputMaybe<Array<BaseMultipartDocumentPagesWhereInput>>;
  corrected_page_date?: InputMaybe<DateTimeFilter>;
  file_id?: InputMaybe<StringFilter>;
  is_duplicate?: InputMaybe<IntFilter>;
  is_favourite?: InputMaybe<IntFilter>;
  page_date?: InputMaybe<DateTimeFilter>;
  page_number?: InputMaybe<IntFilter>;
  page_to_tag?: InputMaybe<PageToTagByRelationWhereInput>;
  pages_viewed?: InputMaybe<PagesViewedRelationInput>;
};

export type MultipartPageWhereInput = {
  AND?: InputMaybe<Array<BaseMultipartPageWhereInput>>;
  NOT?: InputMaybe<Array<BaseMultipartPageWhereInput>>;
  OR?: InputMaybe<Array<BaseMultipartPageWhereInput>>;
  corrected_page_date?: InputMaybe<DateTimeFilter>;
  document_id?: InputMaybe<StringFilter>;
  file_id?: InputMaybe<StringFilter>;
  is_duplicate?: InputMaybe<IntFilter>;
  is_favourite?: InputMaybe<IntFilter>;
  page_date?: InputMaybe<DateTimeFilter>;
  page_number?: InputMaybe<IntFilter>;
  page_to_tag?: InputMaybe<PageToTagByRelationWhereInput>;
  pages_viewed?: InputMaybe<PagesViewedRelationInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addTagToNote: NotesDocument;
  breakApartTimelineEntries: BreakApartMutationResponse;
  bulkUpdatePageTags: Array<PageObject>;
  bulkUpdateTimelineEntries: BulkUpdateResponse;
  changeCaseDetails: CasesObject;
  changeEntity: TimelineEntryObject;
  changeTimelineEntryDetails: TimelineEntryObject;
  createCase: CasesObject;
  createCustomTag: CustomTag;
  createNote: NotesDocument;
  createTimelineReport: TimelineReportDocument;
  createTimelineReportSection: TimelineReportSection;
  deleteCustomTag: Scalars['String']['output'];
  deleteNote: Scalars['String']['output'];
  deleteTimelineReport: Scalars['String']['output'];
  mergeSplitTimelineEntries: MergeSplitMutationResponse;
  removeTagFromNote: NotesDocument;
  resolveSelectedDuplicatePages: DuplicateSet;
  setDuplicateThreshold: CasesObject;
  setNewOriginal: DuplicateSet;
  updateCaseUserLocation: CasesObject;
  updateCustomTag: CustomTag;
  updateDocument: DocumentObject;
  updateDuplicatePageSetViewedStatus: DuplicateSet;
  updateEntryDate: TimelineEntryObject;
  updateEntrySource: TimelineEntryObject;
  updateMarkedImportant: TimelineEntryObject;
  updateNote: NotesDocument;
  updateOrganizationStatus: OrganizationObject;
  updatePageFavouriteStatus: PageObject;
  updatePageRotation: PageObject;
  updatePageViewedStatus: PageObject;
  updatePersonStatus: PersonObject;
  updateTimelineReport: TimelineReportDocument;
  updateTimelineReportSection: TimelineReportSection;
};


export type MutationAddTagToNoteArgs = {
  note_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};


export type MutationBreakApartTimelineEntriesArgs = {
  data: BreakApartTimelineEntriesInput;
};


export type MutationBulkUpdatePageTagsArgs = {
  data: BulkUpdatePageTagsDataInput;
  where: BulkUpdatePageTagsWhereInput;
};


export type MutationBulkUpdateTimelineEntriesArgs = {
  input: BulkUpdateTimelineEntriesInput;
};


export type MutationChangeCaseDetailsArgs = {
  data: ChangeCaseDetailsInput;
};


export type MutationChangeEntityArgs = {
  data: ChangeEntityInput;
};


export type MutationChangeTimelineEntryDetailsArgs = {
  data: ChangeTimelineEntryDetailsInput;
};


export type MutationCreateCaseArgs = {
  data: CreateCaseInput;
};


export type MutationCreateCustomTagArgs = {
  data: CreateCustomTagInput;
};


export type MutationCreateNoteArgs = {
  case_id: Scalars['String']['input'];
  data: CreateNoteInput;
};


export type MutationCreateTimelineReportArgs = {
  data: CreateTimelineReportInput;
};


export type MutationCreateTimelineReportSectionArgs = {
  data: CreateTimelineReportSectionInput;
};


export type MutationDeleteCustomTagArgs = {
  tag_id: Scalars['String']['input'];
};


export type MutationDeleteNoteArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTimelineReportArgs = {
  id: Scalars['String']['input'];
};


export type MutationMergeSplitTimelineEntriesArgs = {
  data: MergeSplitTimelineEntriesInput;
};


export type MutationRemoveTagFromNoteArgs = {
  note_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
};


export type MutationResolveSelectedDuplicatePagesArgs = {
  data: ResolveSelectedDuplicatePagesInput;
};


export type MutationSetDuplicateThresholdArgs = {
  data: SetDuplicateThresholdInput;
};


export type MutationSetNewOriginalArgs = {
  data: SetOriginalPageDuplicateInput;
};


export type MutationUpdateCaseUserLocationArgs = {
  data: UpdateCaseUserLocationInput;
};


export type MutationUpdateCustomTagArgs = {
  data: UpdateCustomTagInput;
  tag_id: Scalars['String']['input'];
};


export type MutationUpdateDocumentArgs = {
  data: EditDocumentInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateDuplicatePageSetViewedStatusArgs = {
  data: UpdateDuplicatePageSetViewedInput;
};


export type MutationUpdateEntryDateArgs = {
  data: UpdateEntryDateInput;
};


export type MutationUpdateEntrySourceArgs = {
  data: UpdateEntrySourceInput;
};


export type MutationUpdateMarkedImportantArgs = {
  data: UpdateTimelineEntryMarkedStatusInput;
};


export type MutationUpdateNoteArgs = {
  data: EditNoteInput;
  note_id: Scalars['String']['input'];
};


export type MutationUpdateOrganizationStatusArgs = {
  caseID: Scalars['String']['input'];
  hidden: Scalars['Boolean']['input'];
  organizationValue: Scalars['String']['input'];
};


export type MutationUpdatePageFavouriteStatusArgs = {
  data: UpdatePageFavouriteInput;
};


export type MutationUpdatePageRotationArgs = {
  data: UpdatePageRotationInput;
};


export type MutationUpdatePageViewedStatusArgs = {
  data: UpdatePageViewedInput;
};


export type MutationUpdatePersonStatusArgs = {
  caseID: Scalars['String']['input'];
  hidden: Scalars['Boolean']['input'];
  value: Scalars['String']['input'];
};


export type MutationUpdateTimelineReportArgs = {
  data: EditTimelineReportInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateTimelineReportSectionArgs = {
  data: EditTimelineReportSectionInput;
  id: Scalars['String']['input'];
};

export type NoteImageInput = {
  captured_text?: InputMaybe<Scalars['String']['input']>;
  end_x: Scalars['Float']['input'];
  end_y: Scalars['Float']['input'];
  page_id: Scalars['Int']['input'];
  start_x: Scalars['Float']['input'];
  start_y: Scalars['Float']['input'];
};

export type NoteImageObject = {
  __typename?: 'NoteImageObject';
  capture_creation_date?: Maybe<Scalars['DateTime']['output']>;
  end_x?: Maybe<Scalars['Float']['output']>;
  end_y?: Maybe<Scalars['Float']['output']>;
  id: Scalars['BigInt']['output'];
  image_key_url?: Maybe<Scalars['String']['output']>;
  note_id: Scalars['String']['output'];
  page_id: Scalars['BigInt']['output'];
  start_x?: Maybe<Scalars['Float']['output']>;
  start_y?: Maybe<Scalars['Float']['output']>;
};

export type NoteTagObject = {
  __typename?: 'NoteTagObject';
  customTag?: Maybe<CustomTag>;
  noteID: Scalars['String']['output'];
  tagID: Scalars['String']['output'];
};

export type NotesDocument = {
  __typename?: 'NotesDocument';
  body: Scalars['String']['output'];
  case_id: Scalars['String']['output'];
  creation_date: Scalars['DateTime']['output'];
  date?: Maybe<Scalars['DateTime']['output']>;
  document_id: Scalars['String']['output'];
  favourite?: Maybe<Scalars['Float']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  last_modified_date: Scalars['DateTime']['output'];
  note_images?: Maybe<Array<NoteImageObject>>;
  note_tags?: Maybe<Array<NoteTagObject>>;
  owner_id: Scalars['String']['output'];
  page: PageObject;
  page_id: Scalars['BigInt']['output'];
  physician: Scalars['String']['output'];
  private: Scalars['Float']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type NotesWhereInput = {
  case_id?: InputMaybe<StringFilter>;
  document_id?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  owner_id?: InputMaybe<StringFilter>;
  page_id?: InputMaybe<Scalars['BigInt']['input']>;
};

export type OrganizationObject = {
  __typename?: 'OrganizationObject';
  case_id: Scalars['String']['output'];
  confidence?: Maybe<Scalars['Float']['output']>;
  dismissed: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['BigInt']['output'];
  ml_predicted: Scalars['Boolean']['output'];
  origin: Scalars['String']['output'];
  page_id: Scalars['BigInt']['output'];
  start_position: Scalars['Float']['output'];
  value: Scalars['String']['output'];
};

export type PageDate = {
  __typename?: 'PageDate';
  ex?: Maybe<Scalars['Float']['output']>;
  extracted_date: Scalars['Date']['output'];
  ey?: Maybe<Scalars['Float']['output']>;
  id: Scalars['BigInt']['output'];
  page_id: Scalars['BigInt']['output'];
  sx?: Maybe<Scalars['Float']['output']>;
  sy?: Maybe<Scalars['Float']['output']>;
};

export type PageDescriptor = {
  __typename?: 'PageDescriptor';
  id: Scalars['BigInt']['output'];
  page_number: Scalars['BigInt']['output'];
};

export type PageListNodeObject = {
  __typename?: 'PageListNodeObject';
  id: Scalars['BigInt']['output'];
  node: PageObject;
  order: Scalars['Int']['output'];
};

export type PageObject = {
  __typename?: 'PageObject';
  /** @deprecated Use `tags` instead */
  content_types: Array<TagObject>;
  corrected_page_date?: Maybe<Scalars['Date']['output']>;
  date_table?: Maybe<Array<PageDate>>;
  date_table_id: Scalars['Int']['output'];
  document_id: Scalars['String']['output'];
  documents?: Maybe<DocumentObject>;
  file_id: Scalars['String']['output'];
  has_duplicates: Scalars['Boolean']['output'];
  id: Scalars['BigInt']['output'];
  is_duplicate?: Maybe<Scalars['Int']['output']>;
  is_favourite?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Float']['output']>;
  page_date?: Maybe<Scalars['Date']['output']>;
  page_lang?: Maybe<Scalars['String']['output']>;
  page_number: Scalars['BigInt']['output'];
  page_of?: Maybe<Scalars['String']['output']>;
  pages_viewed: Array<PagesViewedObject>;
  rotation_angle: Scalars['Int']['output'];
  source?: Maybe<PageTagObject>;
  specialities?: Maybe<Array<PageTagObject>>;
  tags: Array<TagDisplayObject>;
  timeline_entry_id: Scalars['BigInt']['output'];
};


export type PageObjectPages_ViewedArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PagesViewedWhereInput>;
};

export type PageOrderByInput = {
  corrected_page_date?: InputMaybe<SortOrder>;
  document_id?: InputMaybe<SortOrder>;
  file_id?: InputMaybe<SortOrder>;
  is_duplicate?: InputMaybe<SortOrder>;
  is_favourite?: InputMaybe<SortOrder>;
  page_date?: InputMaybe<SortOrder>;
  page_number?: InputMaybe<SortOrder>;
};

export type PageTagObject = {
  __typename?: 'PageTagObject';
  confidence?: Maybe<Scalars['Float']['output']>;
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  origin: Scalars['String']['output'];
  parent_tag_id?: Maybe<Scalars['BigInt']['output']>;
  sub_tags: Array<TagObject>;
  type: TagType;
};

export type PageTimelineEntriesForDeletion = {
  __typename?: 'PageTimelineEntriesForDeletion';
  page_id: Scalars['BigInt']['output'];
  timeline_entries_id: Scalars['BigInt']['output'];
};

export type PageToTagByRelationWhereInput = {
  every?: InputMaybe<PageToTagWhereInput>;
  none?: InputMaybe<PageToTagWhereInput>;
  some?: InputMaybe<PageToTagWhereInput>;
};

export type PageToTagToTagByRelationWhereInput = {
  is?: InputMaybe<TagWhereInput>;
  isNot?: InputMaybe<TagWhereInput>;
};

export type PageToTagWhereInput = {
  AND?: InputMaybe<Array<BasePageToTagWhereInput>>;
  NOT?: InputMaybe<Array<BasePageToTagWhereInput>>;
  OR?: InputMaybe<Array<BasePageToTagWhereInput>>;
  dismissed?: InputMaybe<BoolFilter>;
  id?: InputMaybe<IntFilter>;
  tag_id?: InputMaybe<IntFilter>;
  tags?: InputMaybe<PageToTagToTagByRelationWhereInput>;
};

export type PageUpdateInput = {
  pageId: Scalars['BigInt']['input'];
  tags: Array<TagInput>;
};

export type PageWhereInput = {
  AND?: InputMaybe<Array<MultipartPageWhereInput>>;
  NOT?: InputMaybe<Array<MultipartPageWhereInput>>;
  OR?: InputMaybe<Array<MultipartPageWhereInput>>;
  corrected_page_date?: InputMaybe<DateTimeFilter>;
  document_id?: InputMaybe<StringFilter>;
  file_id?: InputMaybe<StringFilter>;
  is_duplicate?: InputMaybe<IntFilter>;
  is_favourite?: InputMaybe<IntFilter>;
  page_date?: InputMaybe<DateTimeFilter>;
  page_number?: InputMaybe<IntFilter>;
  page_to_tag?: InputMaybe<PageToTagByRelationWhereInput>;
  pages_viewed?: InputMaybe<PagesViewedRelationInput>;
};

export type PagesKeywordSearchMatchItem = {
  document_id: Scalars['String']['input'];
  page_number: Scalars['Int']['input'];
};

export type PagesKeywordSearchResult = {
  keyword?: InputMaybe<Scalars['String']['input']>;
  matches: Array<PagesKeywordSearchMatchItem>;
  total_results?: InputMaybe<Scalars['Int']['input']>;
};

export type PagesViewedObject = {
  __typename?: 'PagesViewedObject';
  page_id: Scalars['BigInt']['output'];
  user_id: Scalars['String']['output'];
  viewed_at: Scalars['DateTime']['output'];
};

export type PagesViewedRelationInput = {
  every?: InputMaybe<PagesViewedWhereInput>;
  none?: InputMaybe<PagesViewedWhereInput>;
  some?: InputMaybe<PagesViewedWhereInput>;
};

export type PagesViewedWhereInput = {
  user_id?: InputMaybe<StringFilter>;
};

export type PersonObject = {
  __typename?: 'PersonObject';
  case_id: Scalars['String']['output'];
  confidence?: Maybe<Scalars['Float']['output']>;
  dismissed: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['BigInt']['output'];
  ml_predicted: Scalars['Boolean']['output'];
  ml_suggested_entity?: Maybe<Scalars['String']['output']>;
  origin: Scalars['String']['output'];
  page_id: Scalars['BigInt']['output'];
  start_position?: Maybe<Scalars['Float']['output']>;
  value: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  case: CasesObject;
  cases: Array<CasesObject>;
  contentTypesForFilter: Array<TagDisplayObject>;
  custom_tags: Array<CustomTag>;
  document: DocumentObject;
  documentListDescriptor: Array<DocumentDescriptorObject>;
  /** @deprecated Not useful in v2 schema */
  documents: Array<DocumentObject>;
  documentsWithoutPages: Array<DocumentObject>;
  duplicateStats: DuplicateStats;
  duplicates: Array<DuplicateSet>;
  hiddenEntities: HiddenEntitiesResponse;
  me: UserObject;
  note: NotesDocument;
  notes: Array<NotesDocument>;
  page: PageObject;
  paginatedPages: Array<PageObject>;
  sourceTypesForFilter: Array<TagDisplayObject>;
  tags: Array<TagDisplayObject>;
  timeline: TimelineDocument;
  timelineEntires: Array<TimelineEntryObject>;
  timelineEntry: TimelineEntryObject;
  timelineEntryDescriptors: Array<TimelineEntryDescriptorObject>;
  timeline_report: TimelineReportDocument;
  timeline_reports: Array<TimelineReportDocument>;
  timelines: Array<TimelineDocument>;
};


export type QueryCaseArgs = {
  id: Scalars['String']['input'];
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CasesQueryInput>;
};


export type QueryContentTypesForFilterArgs = {
  caseID: Scalars['String']['input'];
};


export type QueryCustom_TagsArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CustomTagsWhereInput>;
};


export type QueryDocumentArgs = {
  where: DocumentWhereUniqueInput;
};


export type QueryDocumentListDescriptorArgs = {
  orderBy?: InputMaybe<DocumentsOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentsWhereInput>;
};


export type QueryDocumentsArgs = {
  orderBy?: InputMaybe<DocumentsOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentsWhereInput>;
};


export type QueryDocumentsWithoutPagesArgs = {
  caseID: Scalars['String']['input'];
};


export type QueryDuplicateStatsArgs = {
  case_id: Scalars['String']['input'];
  hide_resolved?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  set_id?: InputMaybe<Scalars['BigInt']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DuplicatesQueryInput>;
};


export type QueryDuplicatesArgs = {
  case_id: Scalars['String']['input'];
  hide_resolved?: InputMaybe<Scalars['Boolean']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  set_id?: InputMaybe<Scalars['BigInt']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DuplicatesQueryInput>;
};


export type QueryHiddenEntitiesArgs = {
  caseID: Scalars['String']['input'];
};


export type QueryNoteArgs = {
  id: Scalars['String']['input'];
};


export type QueryNotesArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<NotesWhereInput>;
};


export type QueryPageArgs = {
  id: Scalars['BigInt']['input'];
};


export type QueryPaginatedPagesArgs = {
  orderBy?: InputMaybe<DocumentsOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DocumentsWhereInput>;
};


export type QuerySourceTypesForFilterArgs = {
  caseID: Scalars['String']['input'];
};


export type QueryTagsArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TagWhereInput>;
};


export type QueryTimelineArgs = {
  id: Scalars['BigInt']['input'];
};


export type QueryTimelineEntiresArgs = {
  orderBy: TimelineEntryOrderByInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: TimelineEntryWhereInput;
};


export type QueryTimelineEntryArgs = {
  id: Scalars['BigInt']['input'];
};


export type QueryTimelineEntryDescriptorsArgs = {
  orderBy: TimelineEntryOrderByInput;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: TimelineEntryWhereInput;
};


export type QueryTimeline_ReportArgs = {
  id: Scalars['String']['input'];
};


export type QueryTimeline_ReportsArgs = {
  orderBy?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TimelineReportsWhereInput>;
};


export type QueryTimelinesArgs = {
  orderBy?: InputMaybe<TimelineOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TimelineWhereInput>;
};

export type ResolveSelectedDuplicatePagesInput = {
  case_id: Scalars['String']['input'];
  duplicate_set_id: Scalars['BigInt']['input'];
  hide_resolved: Scalars['Boolean']['input'];
  is_duplicate: Scalars['Boolean']['input'];
  page_ids: Array<Scalars['BigInt']['input']>;
};

export type SetDuplicateThresholdInput = {
  case_id: Scalars['String']['input'];
  threshold: Scalars['Float']['input'];
};

export type SetOriginalPageDuplicateInput = {
  case_id: Scalars['String']['input'];
  duplicate_set_id: Scalars['BigInt']['input'];
  page_id: Scalars['BigInt']['input'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type TagDisplayObject = {
  __typename?: 'TagDisplayObject';
  id: Scalars['BigInt']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  origin: Scalars['String']['output'];
  parent_tag_id?: Maybe<Scalars['BigInt']['output']>;
  sub_tags?: Maybe<Array<TagDisplayObject>>;
  type: TagType;
};

export type TagInput = {
  id: Scalars['BigInt']['input'];
  type?: InputMaybe<Scalars['BigInt']['input']>;
};

export type TagObject = {
  __typename?: 'TagObject';
  id: Scalars['BigInt']['output'];
  name: Scalars['String']['output'];
  origin: Scalars['String']['output'];
  parent_tag_id?: Maybe<Scalars['BigInt']['output']>;
  sub_tags: Array<TagObject>;
  type: TagType;
};

export enum TagType {
  ContentType = 'content_type',
  Source = 'source',
  Speciality = 'speciality'
}

export type TagTypeEnumFilter = {
  equals?: InputMaybe<TagType>;
  in?: InputMaybe<Array<TagType>>;
  not?: InputMaybe<EnumFilterBaseClass>;
  notIn?: InputMaybe<Array<TagType>>;
};

export type TagWhereInput = {
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  origin?: InputMaybe<StringFilter>;
  parent_tag_id?: InputMaybe<IntFilter>;
  type?: InputMaybe<TagTypeEnumFilter>;
};

export type TimelineDocument = {
  __typename?: 'TimelineDocument';
  archived_at: Scalars['String']['output'];
  case_id: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  created_by: Scalars['String']['output'];
  id: Scalars['BigInt']['output'];
  is_default: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type TimelineEntriesOrgInput = {
  id?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TimelineEntriesPersonInput = {
  id?: InputMaybe<Scalars['BigInt']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TimelineEntryDescriptorObject = {
  __typename?: 'TimelineEntryDescriptorObject';
  entry_id: Scalars['BigInt']['output'];
  pages: Array<PageObject>;
};


export type TimelineEntryDescriptorObjectPagesArgs = {
  where: PageWhereInput;
};

export type TimelineEntryObject = {
  __typename?: 'TimelineEntryObject';
  all_pages: Array<PageObject>;
  author_id?: Maybe<Scalars['BigInt']['output']>;
  authors?: Maybe<PersonObject>;
  case_id: Scalars['String']['output'];
  created_date: Scalars['DateTime']['output'];
  entry_date?: Maybe<Scalars['Date']['output']>;
  extracted_authors: Array<PersonObject>;
  extracted_organizations: Array<OrganizationObject>;
  id: Scalars['BigInt']['output'];
  is_hidden: Scalars['Boolean']['output'];
  last_modified_date: Scalars['DateTime']['output'];
  legacy_entry_date?: Maybe<Scalars['DateTime']['output']>;
  marked_important: Scalars['Boolean']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  org_id?: Maybe<Scalars['BigInt']['output']>;
  organizations?: Maybe<OrganizationObject>;
  pages?: Maybe<Array<PageObject>>;
  subject_id?: Maybe<Scalars['BigInt']['output']>;
  subjects?: Maybe<PersonObject>;
  timeline_id: Scalars['BigInt']['output'];
};


export type TimelineEntryObjectPagesArgs = {
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PageWhereInput>;
};

export type TimelineEntryOrderByInput = {
  document?: InputMaybe<SortOrder>;
  page_date?: InputMaybe<SortOrder>;
};

export type TimelineEntryRelationFilter = {
  every?: InputMaybe<TimelineEntryWhereInput>;
  none?: InputMaybe<TimelineEntryWhereInput>;
  some?: InputMaybe<TimelineEntryWhereInput>;
};

export type TimelineEntryUpdateInput = {
  authorId?: InputMaybe<Scalars['BigInt']['input']>;
  entryDate?: InputMaybe<Scalars['Date']['input']>;
  entryId: Scalars['BigInt']['input'];
  orgId?: InputMaybe<Scalars['BigInt']['input']>;
  pages: Array<PageUpdateInput>;
  source?: InputMaybe<Scalars['BigInt']['input']>;
  sourceID?: InputMaybe<Scalars['BigInt']['input']>;
};

export type TimelineEntryWhereInput = {
  afterDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Allowed content types and specialities */
  allowedPageTags?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  allowedSourceTags?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  beforeDate?: InputMaybe<Scalars['DateTime']['input']>;
  document_id?: InputMaybe<Array<Scalars['String']['input']>>;
  endPageNumber?: InputMaybe<Scalars['Int']['input']>;
  hideDuplicates?: InputMaybe<Scalars['Boolean']['input']>;
  hideViewed?: InputMaybe<Scalars['Boolean']['input']>;
  keyword_search_result?: InputMaybe<PagesKeywordSearchResult>;
  showFavourited?: InputMaybe<Scalars['Boolean']['input']>;
  startPageNumber?: InputMaybe<Scalars['Int']['input']>;
  timeline_id: Scalars['BigInt']['input'];
};

export type TimelineOrderByInput = {
  archived_at?: InputMaybe<SortOrder>;
  case_id?: InputMaybe<SortOrder>;
  created_at?: InputMaybe<SortOrder>;
  created_by?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  is_default?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type TimelineReportDocument = {
  __typename?: 'TimelineReportDocument';
  case_id: Scalars['String']['output'];
  creation_date: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  is_auto_generated: Scalars['Boolean']['output'];
  last_modified_date: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  owner_id: Scalars['String']['output'];
  report_type: Scalars['String']['output'];
  timeline_id?: Maybe<Scalars['BigInt']['output']>;
  timeline_report_sections?: Maybe<Array<TimelineReportSection>>;
};

export type TimelineReportSection = {
  __typename?: 'TimelineReportSection';
  case_id: Scalars['String']['output'];
  content_types?: Maybe<Array<TagDisplayObject>>;
  extra_columns?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  section_rules: Scalars['JSON']['output'];
  section_type: Scalars['String']['output'];
  sort_order: Scalars['Float']['output'];
  sources?: Maybe<Array<TagDisplayObject>>;
  sub_content_types?: Maybe<Array<TagDisplayObject>>;
  timeline_report_id: Scalars['String']['output'];
};

export type TimelineReportsWhereInput = {
  case_id?: InputMaybe<StringFilter>;
  creation_date?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  last_modified_date?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  owner_id?: InputMaybe<StringFilter>;
  report_type?: InputMaybe<StringFilter>;
};

export type TimelineWhereInput = {
  AND?: InputMaybe<Array<BaseTimelineWhereInput>>;
  NOT?: InputMaybe<Array<BaseTimelineWhereInput>>;
  OR?: InputMaybe<Array<BaseTimelineWhereInput>>;
  archived_at?: InputMaybe<Scalars['String']['input']>;
  case_id?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['DateTime']['input']>;
  created_by?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  is_default?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<StringFilter>;
  timeline_entries?: InputMaybe<TimelineEntryRelationFilter>;
};

export type UpdateCaseUserLocationInput = {
  caseId: Scalars['String']['input'];
  pageId: Scalars['BigInt']['input'];
  userId: Scalars['String']['input'];
  view: Scalars['String']['input'];
};

export type UpdateCustomTagInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDuplicatePageSetViewedInput = {
  duplicate_page_set_id: Scalars['BigInt']['input'];
  viewed: Scalars['Int']['input'];
};

export type UpdateEntryDateInput = {
  date: Scalars['Date']['input'];
  entryID: Scalars['BigInt']['input'];
};

export type UpdateEntrySourceInput = {
  entryID: Scalars['BigInt']['input'];
  sourceID: Scalars['BigInt']['input'];
};

export type UpdatePageFavouriteInput = {
  isFavourite: Scalars['Int']['input'];
  pageID: Scalars['BigInt']['input'];
};

export type UpdatePageRotationInput = {
  pageID: Scalars['BigInt']['input'];
  rotation_angle: Scalars['Int']['input'];
};

export type UpdatePageViewedInput = {
  pageID: Scalars['BigInt']['input'];
  viewed: Scalars['Int']['input'];
};

export type UpdateTimelineEntryMarkedStatusInput = {
  entryID: Scalars['BigInt']['input'];
  marked_important: Scalars['Boolean']['input'];
};

export type UserObject = {
  __typename?: 'UserObject';
  email: Scalars['String']['output'];
  email_verified: Scalars['Boolean']['output'];
  family_name: Scalars['String']['output'];
  given_name: Scalars['String']['output'];
  id: Scalars['String']['output'];
  is_file_processor: Scalars['Boolean']['output'];
  token_use: Scalars['String']['output'];
  user_groups: Array<Scalars['String']['output']>;
};

export type TimelineEntryWithTaggedPagesFragment = { __typename?: 'TimelineEntryObject', id: any, author_id?: any | null, org_id?: any | null, pages?: Array<{ __typename?: 'PageObject', id: any, tags: Array<{ __typename?: 'TagDisplayObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }> }> | null };

export type EntryUpdateFragmentFragment = { __typename?: 'TimelineEntryObject', pages?: Array<{ __typename?: 'PageObject', id: any, tags: Array<{ __typename?: 'TagDisplayObject', id: any, name: string, origin: string, type: TagType, parent_tag_id?: any | null }> }> | null };

export type NewTagFragmentFragment = { __typename?: 'TagDisplayObject', id: any, name: string, origin: string, type: TagType, parent_tag_id?: any | null };

export type UpdateMarkedImportantMutationVariables = Exact<{
  entryID: Scalars['BigInt']['input'];
  markedImportant: Scalars['Boolean']['input'];
}>;


export type UpdateMarkedImportantMutation = { __typename?: 'Mutation', updateMarkedImportant: { __typename?: 'TimelineEntryObject', id: any, marked_important: boolean } };

export type CaseFragmentFragment = { __typename?: 'CasesObject', id: string, case_name: string, due_date?: any | null, date_of_birth?: any | null, date_of_injury?: any | null, full_name?: string | null, claim_reason?: string | null, similarity_threshold: number, case_user_location?: { __typename?: 'CaseUserLocationObject', page_id: any, view: string, timeline_entry_id: any, document_id: string } | null };

export type CreateCaseMutationVariables = Exact<{
  createCaseInput: CreateCaseInput;
}>;


export type CreateCaseMutation = { __typename?: 'Mutation', createCase: { __typename?: 'CasesObject', id: string, case_name: string, due_date?: any | null, date_of_birth?: any | null, date_of_injury?: any | null, full_name?: string | null, claim_reason?: string | null, similarity_threshold: number, case_user_location?: { __typename?: 'CaseUserLocationObject', page_id: any, view: string, timeline_entry_id: any, document_id: string } | null } };

export type UpdateCaseMutationVariables = Exact<{
  changeCaseDetailsInput: ChangeCaseDetailsInput;
}>;


export type UpdateCaseMutation = { __typename?: 'Mutation', changeCaseDetails: { __typename?: 'CasesObject', id: string, case_name: string, due_date?: any | null, date_of_birth?: any | null, date_of_injury?: any | null, full_name?: string | null, claim_reason?: string | null, similarity_threshold: number, case_user_location?: { __typename?: 'CaseUserLocationObject', page_id: any, view: string, timeline_entry_id: any, document_id: string } | null } };

export type SetDuplicateThresholdMutationVariables = Exact<{
  data: SetDuplicateThresholdInput;
}>;


export type SetDuplicateThresholdMutation = { __typename?: 'Mutation', setDuplicateThreshold: { __typename?: 'CasesObject', id: string, case_name: string, due_date?: any | null, date_of_birth?: any | null, date_of_injury?: any | null, full_name?: string | null, claim_reason?: string | null, similarity_threshold: number, case_user_location?: { __typename?: 'CaseUserLocationObject', page_id: any, view: string, timeline_entry_id: any, document_id: string } | null } };

export type UpdateCaseUserLocationMutationVariables = Exact<{
  data: UpdateCaseUserLocationInput;
}>;


export type UpdateCaseUserLocationMutation = { __typename?: 'Mutation', updateCaseUserLocation: { __typename?: 'CasesObject', id: string, case_name: string, due_date?: any | null, date_of_birth?: any | null, date_of_injury?: any | null, full_name?: string | null, claim_reason?: string | null, similarity_threshold: number, case_user_location?: { __typename?: 'CaseUserLocationObject', page_id: any, view: string, timeline_entry_id: any, document_id: string } | null } };

export type GetCasesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCasesQuery = { __typename?: 'Query', cases: Array<{ __typename?: 'CasesObject', caseID: string, caseName: string, userID: string, caseStatus: string, claimReason?: string | null, createDate: any, dateOfBirth?: any | null, dateOfInjury?: any | null, dueDate?: any | null, isReady: number, fullName?: string | null, lastOpened: any, pageCount?: any | null, pagesViewed?: any | null, similarityThreshold: number, archived_at?: any | null, archived_by?: string | null, ref_id: any }> };

export type GetCaseQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetCaseQuery = { __typename?: 'Query', case: { __typename?: 'CasesObject', caseID: string, caseName: string, userID: string, caseStatus: string, claimReason?: string | null, createDate: any, dateOfBirth?: any | null, dateOfInjury?: any | null, dueDate?: any | null, isReady: number, fullName?: string | null, lastOpened: any, pageCount?: any | null, pagesViewed?: any | null, similarityThreshold: number, ref_id: any, duplicates_regenerate: boolean, caseUserLocation?: { __typename?: 'CaseUserLocationObject', view: string, pageID: any, timelineEntryID: any, documentID: string } | null } };

export type GetPageByIdQueryVariables = Exact<{
  id: Scalars['BigInt']['input'];
}>;


export type GetPageByIdQuery = { __typename?: 'Query', page: { __typename?: 'PageObject', pageID: any, documentID: string, pageNumber: any, pageDate?: any | null, correctedDate?: any | null, timelineEntryID: any, source?: { __typename?: 'PageTagObject', name: string } | null, tags: Array<{ __typename?: 'TagDisplayObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string, label: string }> } };

export type PaginatedPageFragment = { __typename?: 'PageObject', id: any, order?: number | null, entryID: any, documentID: string, hasDuplicates: boolean, isDuplicate?: number | null, isFavourite?: number | null, pageDate?: any | null, rotationAngle: number, correctedDate?: any | null, pageNumber: any, pages_viewed: Array<{ __typename?: 'PagesViewedObject', user_id: string, viewed_at: any, page_id: any }>, tags: Array<{ __typename?: 'TagDisplayObject', id: any, label: string, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, contentTypes: Array<{ __typename?: 'TagObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, specialities?: Array<{ __typename?: 'PageTagObject', id: any, name: string, type: TagType }> | null, source?: { __typename?: 'PageTagObject', id: any, name: string, type: TagType, confidence?: number | null } | null };

export type PaginatedPagesQueryVariables = Exact<{
  where?: InputMaybe<DocumentsWhereInput>;
  pagesViewedWhere?: InputMaybe<PagesViewedWhereInput>;
  orderBy?: InputMaybe<DocumentsOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type PaginatedPagesQuery = { __typename?: 'Query', paginatedPages: Array<{ __typename?: 'PageObject', id: any, order?: number | null, entryID: any, documentID: string, hasDuplicates: boolean, isDuplicate?: number | null, isFavourite?: number | null, pageDate?: any | null, rotationAngle: number, correctedDate?: any | null, pageNumber: any, pages_viewed: Array<{ __typename?: 'PagesViewedObject', user_id: string, viewed_at: any, page_id: any }>, tags: Array<{ __typename?: 'TagDisplayObject', id: any, label: string, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, contentTypes: Array<{ __typename?: 'TagObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, specialities?: Array<{ __typename?: 'PageTagObject', id: any, name: string, type: TagType }> | null, source?: { __typename?: 'PageTagObject', id: any, name: string, type: TagType, confidence?: number | null } | null }> };

export type DocumentListDescriptorQueryVariables = Exact<{
  where?: InputMaybe<DocumentsWhereInput>;
  orderBy?: InputMaybe<DocumentsOrderByInput>;
}>;


export type DocumentListDescriptorQuery = { __typename?: 'Query', documentListDescriptor: Array<{ __typename?: 'DocumentDescriptorObject', documentID: Array<string>, pages: Array<{ __typename?: 'PageDescriptor', id: any, pageNumber: any }> }> };

export type PageCardDetailsFragment = { __typename?: 'PageObject', id: any, order?: number | null, entryID: any, documentID: string, hasDuplicates: boolean, isDuplicate?: number | null, isFavourite?: number | null, pageDate?: any | null, rotationAngle: number, correctedDate?: any | null, pageNumber: any, pages_viewed: Array<{ __typename?: 'PagesViewedObject', user_id: string, viewed_at: any, page_id: any }>, tags: Array<{ __typename?: 'TagDisplayObject', id: any, label: string, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, contentTypes: Array<{ __typename?: 'TagObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, specialities?: Array<{ __typename?: 'PageTagObject', id: any, name: string, type: TagType }> | null, source?: { __typename?: 'PageTagObject', id: any, name: string, type: TagType, confidence?: number | null } | null };

export type GetDuplicatesQueryVariables = Exact<{
  case_id: Scalars['String']['input'];
  hide_resolved: Scalars['Boolean']['input'];
  set_id?: InputMaybe<Scalars['BigInt']['input']>;
}>;


export type GetDuplicatesQuery = { __typename?: 'Query', duplicates: Array<{ __typename?: 'DuplicateSet', setID: any, viewedAt?: any | null, originalPage?: { __typename?: 'DuplicateSetMember', setID: any, pageID: any, pageNumber: number, rotationAngle: number, isDuplicate?: boolean | null, documentID: string, documentFileName: string } | null, similarPages?: Array<{ __typename?: 'DuplicateSetMember', score?: number | null, setID: any, pageID: any, pageNumber: number, isDuplicate?: boolean | null, documentID: string, documentFileName: string, rotationAngle: number }> | null }> };

export type DuplicatePageFragmentFragment = { __typename?: 'DuplicateSetMember', score?: number | null, setID: any, pageID: any, pageNumber: number, isDuplicate?: boolean | null, documentID: string, documentFileName: string, rotationAngle: number };

export type GetDuplicateStatsQueryVariables = Exact<{
  case_id: Scalars['String']['input'];
}>;


export type GetDuplicateStatsQuery = { __typename?: 'Query', duplicateStats: { __typename?: 'DuplicateStats', resolvedCount: number, unresolvedCount: number, unresolvedDuplicateSetsCount: number } };

export type SetNewOriginalMutationVariables = Exact<{
  data: SetOriginalPageDuplicateInput;
}>;


export type SetNewOriginalMutation = { __typename?: 'Mutation', setNewOriginal: { __typename?: 'DuplicateSet', setID: any, originalPage?: { __typename?: 'DuplicateSetMember', setID: any, pageID: any, pageNumber: number, isDuplicate?: boolean | null, documentID: string, documentFileName: string } | null, similarPages?: Array<{ __typename?: 'DuplicateSetMember', score?: number | null, setID: any, pageID: any, pageNumber: number, isDuplicate?: boolean | null, documentID: string, documentFileName: string, rotationAngle: number }> | null } };

export type ResolveSelectedDuplicatePagesMutationVariables = Exact<{
  data: ResolveSelectedDuplicatePagesInput;
}>;


export type ResolveSelectedDuplicatePagesMutation = { __typename?: 'Mutation', resolveSelectedDuplicatePages: { __typename?: 'DuplicateSet', setID: any, originalPage?: { __typename?: 'DuplicateSetMember', setID: any, pageID: any, pageNumber: number, isDuplicate?: boolean | null, documentID: string, documentFileName: string } | null, similarPages?: Array<{ __typename?: 'DuplicateSetMember', score?: number | null, setID: any, pageID: any, pageNumber: number, isDuplicate?: boolean | null, documentID: string, documentFileName: string, rotationAngle: number }> | null } };

export type UpdateDuplicatePageSetViewedMutationVariables = Exact<{
  data: UpdateDuplicatePageSetViewedInput;
}>;


export type UpdateDuplicatePageSetViewedMutation = { __typename?: 'Mutation', updateDuplicatePageSetViewedStatus: { __typename?: 'DuplicateSet', id: any, viewed_at?: any | null } };

export type NoteImageFragmentFragment = { __typename?: 'NoteImageObject', id: any, end_x?: number | null, end_y?: number | null, start_x?: number | null, start_y?: number | null, image_key_url?: string | null, page_id: any, note_id: string };

export type NoteTagFragmentFragment = { __typename?: 'NoteTagObject', noteID: string, tagID: string, customTag?: { __typename?: 'CustomTag', tagID: string, title: string, color: string, userID: string } | null };

export type NoteFragmentFragment = { __typename?: 'NotesDocument', id: string, title?: string | null, body: string, creation_date: any, last_modified_date: any, private: number, physician: string, document_id: string, case_id: string, owner_id: string, page_id: any, favourite?: number | null, fileName?: string | null, date?: any | null, page: { __typename?: 'PageObject', page_number: any }, note_images?: Array<{ __typename?: 'NoteImageObject', id: any, end_x?: number | null, end_y?: number | null, start_x?: number | null, start_y?: number | null, image_key_url?: string | null, page_id: any, note_id: string }> | null, note_tags?: Array<{ __typename?: 'NoteTagObject', noteID: string, tagID: string, customTag?: { __typename?: 'CustomTag', tagID: string, title: string, color: string, userID: string } | null }> | null };

export type CustomTagFragmentFragment = { __typename?: 'CustomTag', tagID: string, title: string, color: string, userID: string };

export type GetNotesDocumentsQueryVariables = Exact<{
  where: NotesWhereInput;
}>;


export type GetNotesDocumentsQuery = { __typename?: 'Query', notes: Array<{ __typename?: 'NotesDocument', id: string, title?: string | null, body: string, creation_date: any, last_modified_date: any, private: number, physician: string, document_id: string, case_id: string, owner_id: string, page_id: any, favourite?: number | null, fileName?: string | null, date?: any | null, page: { __typename?: 'PageObject', page_number: any }, note_images?: Array<{ __typename?: 'NoteImageObject', id: any, end_x?: number | null, end_y?: number | null, start_x?: number | null, start_y?: number | null, image_key_url?: string | null, page_id: any, note_id: string }> | null, note_tags?: Array<{ __typename?: 'NoteTagObject', noteID: string, tagID: string, customTag?: { __typename?: 'CustomTag', tagID: string, title: string, color: string, userID: string } | null }> | null }> };

export type GetNoteQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetNoteQuery = { __typename?: 'Query', note: { __typename?: 'NotesDocument', id: string, title?: string | null, body: string, creation_date: any, last_modified_date: any, private: number, physician: string, document_id: string, case_id: string, owner_id: string, page_id: any, favourite?: number | null, fileName?: string | null, date?: any | null, page: { __typename?: 'PageObject', page_number: any }, note_images?: Array<{ __typename?: 'NoteImageObject', id: any, end_x?: number | null, end_y?: number | null, start_x?: number | null, start_y?: number | null, image_key_url?: string | null, page_id: any, note_id: string }> | null, note_tags?: Array<{ __typename?: 'NoteTagObject', noteID: string, tagID: string, customTag?: { __typename?: 'CustomTag', tagID: string, title: string, color: string, userID: string } | null }> | null } };

export type CreateNoteMutationVariables = Exact<{
  case_id: Scalars['String']['input'];
  data: CreateNoteInput;
}>;


export type CreateNoteMutation = { __typename?: 'Mutation', createNote: { __typename?: 'NotesDocument', id: string, title?: string | null, body: string, creation_date: any, last_modified_date: any, private: number, physician: string, document_id: string, case_id: string, owner_id: string, page_id: any, favourite?: number | null, fileName?: string | null, date?: any | null, page: { __typename?: 'PageObject', page_number: any }, note_images?: Array<{ __typename?: 'NoteImageObject', id: any, end_x?: number | null, end_y?: number | null, start_x?: number | null, start_y?: number | null, image_key_url?: string | null, page_id: any, note_id: string }> | null, note_tags?: Array<{ __typename?: 'NoteTagObject', noteID: string, tagID: string, customTag?: { __typename?: 'CustomTag', tagID: string, title: string, color: string, userID: string } | null }> | null } };

export type UpdateNoteMutationVariables = Exact<{
  note_id: Scalars['String']['input'];
  editNoteData: EditNoteInput;
}>;


export type UpdateNoteMutation = { __typename?: 'Mutation', updateNote: { __typename?: 'NotesDocument', id: string, title?: string | null, body: string, creation_date: any, last_modified_date: any, private: number, physician: string, document_id: string, case_id: string, owner_id: string, page_id: any, favourite?: number | null, fileName?: string | null, date?: any | null, page: { __typename?: 'PageObject', page_number: any }, note_images?: Array<{ __typename?: 'NoteImageObject', id: any, end_x?: number | null, end_y?: number | null, start_x?: number | null, start_y?: number | null, image_key_url?: string | null, page_id: any, note_id: string }> | null, note_tags?: Array<{ __typename?: 'NoteTagObject', noteID: string, tagID: string, customTag?: { __typename?: 'CustomTag', tagID: string, title: string, color: string, userID: string } | null }> | null } };

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteNoteMutation = { __typename?: 'Mutation', deleteNote: string };

export type AddTagToNoteMutationVariables = Exact<{
  note_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
}>;


export type AddTagToNoteMutation = { __typename?: 'Mutation', addTagToNote: { __typename?: 'NotesDocument', id: string, title?: string | null, body: string, creation_date: any, last_modified_date: any, private: number, physician: string, document_id: string, case_id: string, owner_id: string, page_id: any, favourite?: number | null, fileName?: string | null, date?: any | null, page: { __typename?: 'PageObject', page_number: any }, note_images?: Array<{ __typename?: 'NoteImageObject', id: any, end_x?: number | null, end_y?: number | null, start_x?: number | null, start_y?: number | null, image_key_url?: string | null, page_id: any, note_id: string }> | null, note_tags?: Array<{ __typename?: 'NoteTagObject', noteID: string, tagID: string, customTag?: { __typename?: 'CustomTag', tagID: string, title: string, color: string, userID: string } | null }> | null } };

export type RemoveTagFromNoteMutationVariables = Exact<{
  note_id: Scalars['String']['input'];
  tag_id: Scalars['String']['input'];
}>;


export type RemoveTagFromNoteMutation = { __typename?: 'Mutation', removeTagFromNote: { __typename?: 'NotesDocument', id: string, title?: string | null, body: string, creation_date: any, last_modified_date: any, private: number, physician: string, document_id: string, case_id: string, owner_id: string, page_id: any, favourite?: number | null, fileName?: string | null, date?: any | null, page: { __typename?: 'PageObject', page_number: any }, note_images?: Array<{ __typename?: 'NoteImageObject', id: any, end_x?: number | null, end_y?: number | null, start_x?: number | null, start_y?: number | null, image_key_url?: string | null, page_id: any, note_id: string }> | null, note_tags?: Array<{ __typename?: 'NoteTagObject', noteID: string, tagID: string, customTag?: { __typename?: 'CustomTag', tagID: string, title: string, color: string, userID: string } | null }> | null } };

export type CustomTagsQueryVariables = Exact<{
  where: CustomTagsWhereInput;
}>;


export type CustomTagsQuery = { __typename?: 'Query', custom_tags: Array<{ __typename?: 'CustomTag', tagID: string, title: string, color: string, userID: string }> };

export type GetCustomTagsForNotesQueryVariables = Exact<{
  where: NotesWhereInput;
}>;


export type GetCustomTagsForNotesQuery = { __typename?: 'Query', notes: Array<{ __typename?: 'NotesDocument', note_tags?: Array<{ __typename?: 'NoteTagObject', noteID: string, tagID: string, customTag?: { __typename?: 'CustomTag', tagID: string, title: string, color: string, userID: string } | null }> | null }> };

export type CreateCustomTagMutationVariables = Exact<{
  data: CreateCustomTagInput;
}>;


export type CreateCustomTagMutation = { __typename?: 'Mutation', createCustomTag: { __typename?: 'CustomTag', tagID: string, title: string, color: string, userID: string } };

export type UpdateCustomTagMutationVariables = Exact<{
  tag_id: Scalars['String']['input'];
  data: UpdateCustomTagInput;
}>;


export type UpdateCustomTagMutation = { __typename?: 'Mutation', updateCustomTag: { __typename?: 'CustomTag', tagID: string, title: string, color: string, userID: string } };

export type DeleteCustomTagMutationVariables = Exact<{
  tag_id: Scalars['String']['input'];
}>;


export type DeleteCustomTagMutation = { __typename?: 'Mutation', deleteCustomTag: string };

export type PageDetailsFragmentFragment = { __typename?: 'PageObject', pages_viewed: Array<{ __typename?: 'PagesViewedObject', user_id: string, page_id: any, viewed_at: any }> };

export type TimelineReportFragmentFragment = { __typename?: 'TimelineReportDocument', id: string, case_id: string, report_type: string, name: string, timeline_id?: any | null, creation_date: any, last_modified_date: any, owner_id: string, is_auto_generated: boolean };

export type GetTimelineReportsQueryVariables = Exact<{
  where: TimelineReportsWhereInput;
}>;


export type GetTimelineReportsQuery = { __typename?: 'Query', timeline_reports: Array<{ __typename?: 'TimelineReportDocument', id: string, case_id: string, report_type: string, name: string, timeline_id?: any | null, creation_date: any, last_modified_date: any, owner_id: string, is_auto_generated: boolean }> };

export type GetTimelineReportQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetTimelineReportQuery = { __typename?: 'Query', timeline_report: { __typename?: 'TimelineReportDocument', id: string, case_id: string, report_type: string, name: string, timeline_id?: any | null, creation_date: any, last_modified_date: any, owner_id: string, is_auto_generated: boolean } };

export type CreateTimelineReportMutationVariables = Exact<{
  data: CreateTimelineReportInput;
}>;


export type CreateTimelineReportMutation = { __typename?: 'Mutation', createTimelineReport: { __typename?: 'TimelineReportDocument', id: string, case_id: string, report_type: string, name: string, timeline_id?: any | null, creation_date: any, last_modified_date: any, owner_id: string, is_auto_generated: boolean } };

export type UpdateTimelineReportMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: EditTimelineReportInput;
}>;


export type UpdateTimelineReportMutation = { __typename?: 'Mutation', updateTimelineReport: { __typename?: 'TimelineReportDocument', id: string, case_id: string, report_type: string, name: string, timeline_id?: any | null, creation_date: any, last_modified_date: any, owner_id: string, is_auto_generated: boolean } };

export type DeleteTimelineReportMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteTimelineReportMutation = { __typename?: 'Mutation', deleteTimelineReport: string };

export type CreateTimelineReportSectionMutationVariables = Exact<{
  data: CreateTimelineReportSectionInput;
}>;


export type CreateTimelineReportSectionMutation = { __typename?: 'Mutation', createTimelineReportSection: { __typename?: 'TimelineReportSection', id: string, timeline_report_id: string, name: string } };

export type UpdateTimelineReportSectionMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: EditTimelineReportSectionInput;
}>;


export type UpdateTimelineReportSectionMutation = { __typename?: 'Mutation', updateTimelineReportSection: { __typename?: 'TimelineReportSection', id: string, timeline_report_id: string, name: string } };

export type SelectorPageDetailsFragment = { __typename?: 'PageObject', documentID: string, entryID: any };

export type TagFragmentFragment = { __typename?: 'TagObject', id: any, name: string, type: TagType };

export type PageWithContentTypesFragmentFragment = { __typename?: 'PageObject', content_types: Array<{ __typename?: 'TagObject', id: any, name: string, type: TagType }> };

export type PageWithSpecialitiesFragmentFragment = { __typename?: 'PageObject', specialities?: Array<{ __typename?: 'PageTagObject', id: any, name: string, type: TagType }> | null };

export type PageWithContentTypesAndSpecialitiesFragmentFragment = { __typename?: 'PageObject', content_types: Array<{ __typename?: 'TagObject', id: any, name: string, type: TagType }>, specialities?: Array<{ __typename?: 'PageTagObject', id: any, name: string, type: TagType }> | null };

export type PageViewByUserFragment = { __typename?: 'PagesViewedObject', user_id: string, page_id: any, viewed_at: any };

export type PageWithViewsByUsersFragment = { __typename?: 'PageObject', pages_viewed: Array<{ __typename?: 'PagesViewedObject', user_id: string, page_id: any, viewed_at: any }> };

export type GetCaseContentFiltersQueryVariables = Exact<{
  caseID: Scalars['String']['input'];
}>;


export type GetCaseContentFiltersQuery = { __typename?: 'Query', contentTypesForFilter: Array<{ __typename?: 'TagDisplayObject', id: any, label: string, origin: string, parent_tag_id?: any | null, type: TagType, value: string, subItems?: Array<{ __typename?: 'TagDisplayObject', id: any, label: string, origin: string, parent_tag_id?: any | null, type: TagType, value: string }> | null }> };

export type GetCaseSourceFiltersQueryVariables = Exact<{
  caseID: Scalars['String']['input'];
}>;


export type GetCaseSourceFiltersQuery = { __typename?: 'Query', sourceTypesForFilter: Array<{ __typename?: 'TagDisplayObject', id: any, label: string, origin: string, type: TagType, value: string }> };

export type GetPageTagsQueryVariables = Exact<{
  where?: InputMaybe<TagWhereInput>;
}>;


export type GetPageTagsQuery = { __typename?: 'Query', tags: Array<{ __typename?: 'TagDisplayObject', id: any, name: string, label: string, type: TagType, parent_tag_id?: any | null, origin: string }> };

export type GetPageTagsWithChildrenQueryVariables = Exact<{
  where?: InputMaybe<TagWhereInput>;
}>;


export type GetPageTagsWithChildrenQuery = { __typename?: 'Query', tags: Array<{ __typename?: 'TagDisplayObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string, sub_tags?: Array<{ __typename?: 'TagDisplayObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }> | null }> };

export type ApplicationPageFragmentFragment = { __typename?: 'PageObject', id: any, timeline_entry_id: any, file_id: string, document_id: string, has_duplicates: boolean, is_duplicate?: number | null, is_favourite?: number | null, page_date?: any | null, corrected_page_date?: any | null, rotation_angle: number, page_number: any, pages_viewed: Array<{ __typename?: 'PagesViewedObject', user_id: string, viewed_at: any, page_id: any }>, tags: Array<{ __typename?: 'TagDisplayObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, content_types: Array<{ __typename?: 'TagObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, specialities?: Array<{ __typename?: 'PageTagObject', id: any, name: string, type: TagType }> | null, source?: { __typename?: 'PageTagObject', id: any, name: string, type: TagType, confidence?: number | null } | null, documents?: { __typename?: 'DocumentObject', id: string, document_file_name: string, document_file_path: string } | null, date_table?: Array<{ __typename?: 'PageDate', extracted_date: any, sx?: number | null, sy?: number | null, ex?: number | null, ey?: number | null }> | null };

export type DocumentFragmentFragment = { __typename?: 'DocumentObject', id: string, case_id: string, classified_chunks: number, document_file_name: string, document_file_path: string, is_ready: number, number_of_chunks: number, number_of_pages: any, upload_date: any, pages: Array<{ __typename?: 'PageObject', id: any, page_number: any, document_id: string, timeline_entry_id: any }> };

export type TimelineEntryFragmentFragment = { __typename?: 'TimelineEntryObject', id: any, entry_date?: any | null, last_modified_date: any, timeline_id: any, created_date: any, author_id?: any | null, org_id?: any | null, subject_id?: any | null, marked_important: boolean, authors?: { __typename?: 'PersonObject', id: any, value: string } | null, subjects?: { __typename?: 'PersonObject', id: any, value: string } | null, organizations?: { __typename?: 'OrganizationObject', id: any, value: string } | null, pages?: Array<{ __typename?: 'PageObject', id: any, timeline_entry_id: any, file_id: string, document_id: string, has_duplicates: boolean, is_duplicate?: number | null, is_favourite?: number | null, page_date?: any | null, corrected_page_date?: any | null, rotation_angle: number, page_number: any, pages_viewed: Array<{ __typename?: 'PagesViewedObject', user_id: string, viewed_at: any, page_id: any }>, tags: Array<{ __typename?: 'TagDisplayObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, content_types: Array<{ __typename?: 'TagObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, specialities?: Array<{ __typename?: 'PageTagObject', id: any, name: string, type: TagType }> | null, source?: { __typename?: 'PageTagObject', id: any, name: string, type: TagType, confidence?: number | null } | null, documents?: { __typename?: 'DocumentObject', id: string, document_file_name: string, document_file_path: string } | null, date_table?: Array<{ __typename?: 'PageDate', extracted_date: any, sx?: number | null, sy?: number | null, ex?: number | null, ey?: number | null }> | null }> | null, extracted_authors: Array<{ __typename?: 'PersonObject', id: any, value: string }>, extracted_organizations: Array<{ __typename?: 'OrganizationObject', id: any, value: string }> };

export type PageWithTagsFragmentFragment = { __typename?: 'PageObject', id: any, tags: Array<{ __typename?: 'TagDisplayObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }> };

export type GetTimelineEntriesQueryVariables = Exact<{
  timelineEntriesWhere: TimelineEntryWhereInput;
  timelineEntriesOrderBy: TimelineEntryOrderByInput;
  pagesWhere?: InputMaybe<PageWhereInput>;
  pagesOrderBy?: InputMaybe<PageOrderByInput>;
  pagesViewedWhere?: InputMaybe<PagesViewedWhereInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetTimelineEntriesQuery = { __typename?: 'Query', entries: Array<{ __typename?: 'TimelineEntryObject', id: any, entry_date?: any | null, last_modified_date: any, timeline_id: any, created_date: any, author_id?: any | null, org_id?: any | null, subject_id?: any | null, marked_important: boolean, order?: number | null, pages?: Array<{ __typename?: 'PageObject', id: any, timeline_entry_id: any, file_id: string, document_id: string, has_duplicates: boolean, is_duplicate?: number | null, is_favourite?: number | null, page_date?: any | null, corrected_page_date?: any | null, rotation_angle: number, page_number: any, pages_viewed: Array<{ __typename?: 'PagesViewedObject', user_id: string, viewed_at: any, page_id: any }>, tags: Array<{ __typename?: 'TagDisplayObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, content_types: Array<{ __typename?: 'TagObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, specialities?: Array<{ __typename?: 'PageTagObject', id: any, name: string, type: TagType }> | null, source?: { __typename?: 'PageTagObject', id: any, name: string, type: TagType, confidence?: number | null } | null, documents?: { __typename?: 'DocumentObject', id: string, document_file_name: string, document_file_path: string } | null, date_table?: Array<{ __typename?: 'PageDate', extracted_date: any, sx?: number | null, sy?: number | null, ex?: number | null, ey?: number | null }> | null }> | null }> };

export type GetTimelineEntriesDescriptorsQueryVariables = Exact<{
  timelineEntriesWhere: TimelineEntryWhereInput;
  timelineEntriesOrderBy: TimelineEntryOrderByInput;
  pagesWhere: PageWhereInput;
}>;


export type GetTimelineEntriesDescriptorsQuery = { __typename?: 'Query', timelineEntryDescriptors: Array<{ __typename?: 'TimelineEntryDescriptorObject', id: any, pages: Array<{ __typename?: 'PageObject', id: any, pageNumber: any, documentID: string }> }> };

export type UpdateTimelineEntryMutationVariables = Exact<{
  changeTimelineEntryDetailsInput: ChangeTimelineEntryDetailsInput;
  pagesViewedWhere?: InputMaybe<PagesViewedWhereInput>;
}>;


export type UpdateTimelineEntryMutation = { __typename?: 'Mutation', changeTimelineEntryDetails: { __typename?: 'TimelineEntryObject', id: any, entry_date?: any | null, last_modified_date: any, timeline_id: any, created_date: any, author_id?: any | null, org_id?: any | null, subject_id?: any | null, marked_important: boolean, authors?: { __typename?: 'PersonObject', id: any, value: string } | null, subjects?: { __typename?: 'PersonObject', id: any, value: string } | null, organizations?: { __typename?: 'OrganizationObject', id: any, value: string } | null, pages?: Array<{ __typename?: 'PageObject', id: any, timeline_entry_id: any, file_id: string, document_id: string, has_duplicates: boolean, is_duplicate?: number | null, is_favourite?: number | null, page_date?: any | null, corrected_page_date?: any | null, rotation_angle: number, page_number: any, pages_viewed: Array<{ __typename?: 'PagesViewedObject', user_id: string, viewed_at: any, page_id: any }>, tags: Array<{ __typename?: 'TagDisplayObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, content_types: Array<{ __typename?: 'TagObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }>, specialities?: Array<{ __typename?: 'PageTagObject', id: any, name: string, type: TagType }> | null, source?: { __typename?: 'PageTagObject', id: any, name: string, type: TagType, confidence?: number | null } | null, documents?: { __typename?: 'DocumentObject', id: string, document_file_name: string, document_file_path: string } | null, date_table?: Array<{ __typename?: 'PageDate', extracted_date: any, sx?: number | null, sy?: number | null, ex?: number | null, ey?: number | null }> | null }> | null, extracted_authors: Array<{ __typename?: 'PersonObject', id: any, value: string }>, extracted_organizations: Array<{ __typename?: 'OrganizationObject', id: any, value: string }> } };

export type MergeSplitTimelineEntriesMutationVariables = Exact<{
  data: MergeSplitTimelineEntriesInput;
}>;


export type MergeSplitTimelineEntriesMutation = { __typename?: 'Mutation', mergeSplitTimelineEntries: { __typename?: 'MergeSplitMutationResponse', deletedTimelineEntries?: Array<any> | null, newEntry: { __typename?: 'TimelineEntryObject', id: any, entry_date?: any | null, timeline_id: any, pages?: Array<{ __typename?: 'PageObject', id: any, timeline_entry_id: any }> | null } } };

export type BreakApartTimelineEntriesMutationVariables = Exact<{
  data: BreakApartTimelineEntriesInput;
}>;


export type BreakApartTimelineEntriesMutation = { __typename?: 'Mutation', breakApartTimelineEntries: { __typename?: 'BreakApartMutationResponse', updatedPages: Array<{ __typename?: 'PageObject', id: any, timeline_entry_id: any }> } };

export type BulkUpdatePageTagsMutationVariables = Exact<{
  where: BulkUpdatePageTagsWhereInput;
  data: BulkUpdatePageTagsDataInput;
}>;


export type BulkUpdatePageTagsMutation = { __typename?: 'Mutation', bulkUpdatePageTags: Array<{ __typename?: 'PageObject', id: any, tags: Array<{ __typename?: 'TagDisplayObject', id: any, name: string, type: TagType, parent_tag_id?: any | null, origin: string }> }> };

export type UpdatePageFavouriteMutationVariables = Exact<{
  data: UpdatePageFavouriteInput;
}>;


export type UpdatePageFavouriteMutation = { __typename?: 'Mutation', updatePageFavouriteStatus: { __typename?: 'PageObject', id: any, is_favourite?: number | null } };

export type UpdatePageViewedMutationVariables = Exact<{
  data: UpdatePageViewedInput;
}>;


export type UpdatePageViewedMutation = { __typename?: 'Mutation', updatePageViewedStatus: { __typename?: 'PageObject', id: any, pages_viewed: Array<{ __typename?: 'PagesViewedObject', page_id: any, user_id: string, viewed_at: any }> } };

export type UpdatePageRotationMutationVariables = Exact<{
  data: UpdatePageRotationInput;
}>;


export type UpdatePageRotationMutation = { __typename?: 'Mutation', updatePageRotation: { __typename?: 'PageObject', id: any, rotation_angle: number } };

export type UpdateEntryDateMutationVariables = Exact<{
  data: UpdateEntryDateInput;
}>;


export type UpdateEntryDateMutation = { __typename?: 'Mutation', updateEntryDate: { __typename?: 'TimelineEntryObject', id: any } };

export type UpdateEntityMutationVariables = Exact<{
  data: ChangeEntityInput;
}>;


export type UpdateEntityMutation = { __typename?: 'Mutation', changeEntity: { __typename?: 'TimelineEntryObject', id: any, author_id?: any | null, org_id?: any | null, subject_id?: any | null, authors?: { __typename?: 'PersonObject', id: any, value: string } | null, organizations?: { __typename?: 'OrganizationObject', id: any, value: string } | null } };

export type UpdateEntrySourceMutationVariables = Exact<{
  data: UpdateEntrySourceInput;
}>;


export type UpdateEntrySourceMutation = { __typename?: 'Mutation', updateEntrySource: { __typename?: 'TimelineEntryObject', id: any } };

export type BulkUpdateTimelineEntriesMutationVariables = Exact<{
  input: BulkUpdateTimelineEntriesInput;
}>;


export type BulkUpdateTimelineEntriesMutation = { __typename?: 'Mutation', bulkUpdateTimelineEntries: { __typename?: 'BulkUpdateResponse', success: boolean, message: string } };

export type NewTagFragment = { __typename?: 'TagDisplayObject', id: any, label: string, name: string, origin: string, type: TagType };

export type EntryDateFragment = { __typename?: 'TimelineEntryObject', id: any, entry_date?: any | null, pages?: Array<{ __typename?: 'PageObject', id: any }> | null };

export const TimelineEntryWithTaggedPagesFragmentDoc = gql`
    fragment TimelineEntryWithTaggedPages on TimelineEntryObject {
  id
  author_id
  org_id
  pages {
    id
    tags {
      id
      name
      type
      parent_tag_id
      origin
    }
  }
}
    `;
export const EntryUpdateFragmentFragmentDoc = gql`
    fragment EntryUpdateFragment on TimelineEntryObject {
  pages {
    id
    tags {
      id
      name
      origin
      type
      parent_tag_id
    }
  }
}
    `;
export const NewTagFragmentFragmentDoc = gql`
    fragment NewTagFragment on TagDisplayObject {
  id
  name
  origin
  type
  parent_tag_id
}
    `;
export const CaseFragmentFragmentDoc = gql`
    fragment CaseFragment on CasesObject {
  id
  case_name
  due_date
  date_of_birth
  date_of_injury
  full_name
  claim_reason
  similarity_threshold
  case_user_location {
    page_id
    view
    timeline_entry_id
    document_id
  }
}
    `;
export const PaginatedPageFragmentDoc = gql`
    fragment paginatedPage on PageObject {
  id
  entryID: timeline_entry_id
  documentID: document_id
  hasDuplicates: has_duplicates
  isDuplicate: is_duplicate
  isFavourite: is_favourite
  pageDate: page_date
  rotationAngle: rotation_angle
  correctedDate: corrected_page_date
  pageNumber: page_number
  order
  pages_viewed(where: $pagesViewedWhere) {
    user_id
    viewed_at
    page_id
  }
  tags {
    id
    label
    name
    type
    parent_tag_id
    origin
  }
  contentTypes: content_types {
    id
    name
    type
    parent_tag_id
    origin
  }
  specialities {
    id
    name
    type
  }
  source {
    id
    name
    type
    confidence
  }
}
    `;
export const PageCardDetailsFragmentDoc = gql`
    fragment pageCardDetails on PageObject {
  id
  entryID: timeline_entry_id
  documentID: document_id
  hasDuplicates: has_duplicates
  isDuplicate: is_duplicate
  isFavourite: is_favourite
  pageDate: page_date
  rotationAngle: rotation_angle
  correctedDate: corrected_page_date
  pageNumber: page_number
  order
  pages_viewed(where: $pagesViewedWhere) {
    user_id
    viewed_at
    page_id
  }
  tags {
    id
    label
    name
    type
    parent_tag_id
    origin
  }
  contentTypes: content_types {
    id
    name
    type
    parent_tag_id
    origin
  }
  specialities {
    id
    name
    type
  }
  source {
    id
    name
    type
    confidence
  }
}
    `;
export const DuplicatePageFragmentFragmentDoc = gql`
    fragment DuplicatePageFragment on DuplicateSetMember {
  setID: duplicates_set_id
  score
  pageID: page_id
  pageNumber: page_number
  isDuplicate: is_duplicate
  documentID: document_id
  documentFileName: document_file_name
  rotationAngle: rotation_angle
}
    `;
export const NoteImageFragmentFragmentDoc = gql`
    fragment NoteImageFragment on NoteImageObject {
  id
  end_x
  end_y
  start_x
  start_y
  image_key_url
  page_id
  note_id
}
    `;
export const CustomTagFragmentFragmentDoc = gql`
    fragment CustomTagFragment on CustomTag {
  tagID
  title
  color
  userID
}
    `;
export const NoteTagFragmentFragmentDoc = gql`
    fragment NoteTagFragment on NoteTagObject {
  noteID
  tagID
  customTag {
    ...CustomTagFragment
  }
}
    ${CustomTagFragmentFragmentDoc}`;
export const NoteFragmentFragmentDoc = gql`
    fragment NoteFragment on NotesDocument {
  id
  title
  body
  creation_date
  last_modified_date
  private
  physician
  document_id
  case_id
  owner_id
  page_id
  page {
    page_number
  }
  favourite
  fileName
  date
  note_images {
    ...NoteImageFragment
  }
  note_tags {
    ...NoteTagFragment
  }
}
    ${NoteImageFragmentFragmentDoc}
${NoteTagFragmentFragmentDoc}`;
export const PageDetailsFragmentFragmentDoc = gql`
    fragment PageDetailsFragment on PageObject {
  pages_viewed {
    user_id
    page_id
    viewed_at
  }
}
    `;
export const TimelineReportFragmentFragmentDoc = gql`
    fragment TimelineReportFragment on TimelineReportDocument {
  id
  case_id
  report_type
  name
  timeline_id
  creation_date
  last_modified_date
  owner_id
  is_auto_generated
}
    `;
export const SelectorPageDetailsFragmentDoc = gql`
    fragment SelectorPageDetails on PageObject {
  documentID: document_id
  entryID: timeline_entry_id
}
    `;
export const TagFragmentFragmentDoc = gql`
    fragment TagFragment on TagObject {
  id
  name
  type
}
    `;
export const PageWithContentTypesFragmentFragmentDoc = gql`
    fragment PageWithContentTypesFragment on PageObject {
  content_types {
    id
    name
    type
  }
}
    `;
export const PageWithSpecialitiesFragmentFragmentDoc = gql`
    fragment PageWithSpecialitiesFragment on PageObject {
  specialities {
    id
    name
    type
  }
}
    `;
export const PageWithContentTypesAndSpecialitiesFragmentFragmentDoc = gql`
    fragment PageWithContentTypesAndSpecialitiesFragment on PageObject {
  ...PageWithContentTypesFragment
  ...PageWithSpecialitiesFragment
}
    ${PageWithContentTypesFragmentFragmentDoc}
${PageWithSpecialitiesFragmentFragmentDoc}`;
export const PageViewByUserFragmentDoc = gql`
    fragment PageViewByUser on PagesViewedObject {
  user_id
  page_id
  viewed_at
}
    `;
export const PageWithViewsByUsersFragmentDoc = gql`
    fragment PageWithViewsByUsers on PageObject {
  pages_viewed {
    ...PageViewByUser
  }
}
    ${PageViewByUserFragmentDoc}`;
export const DocumentFragmentFragmentDoc = gql`
    fragment DocumentFragment on DocumentObject {
  id
  case_id
  classified_chunks
  document_file_name
  document_file_path
  is_ready
  number_of_chunks
  number_of_pages
  upload_date
  pages(where: $pagesWhere) {
    id
    page_number
    document_id
    timeline_entry_id
  }
}
    `;
export const ApplicationPageFragmentFragmentDoc = gql`
    fragment ApplicationPageFragment on PageObject {
  id
  timeline_entry_id
  file_id
  document_id
  has_duplicates
  is_duplicate
  is_favourite
  page_date
  corrected_page_date
  rotation_angle
  page_number
  pages_viewed(where: $pagesViewedWhere) {
    user_id
    viewed_at
    page_id
  }
  tags {
    id
    name
    type
    parent_tag_id
    origin
  }
  content_types {
    id
    name
    type
    parent_tag_id
    origin
  }
  specialities {
    id
    name
    type
  }
  source {
    id
    name
    type
    confidence
  }
  documents {
    id
    document_file_name
    document_file_path
  }
  date_table {
    extracted_date
    sx
    sy
    ex
    ey
  }
}
    `;
export const TimelineEntryFragmentFragmentDoc = gql`
    fragment TimelineEntryFragment on TimelineEntryObject {
  id
  entry_date
  last_modified_date
  timeline_id
  created_date
  author_id
  org_id
  subject_id
  marked_important
  authors {
    id
    value
  }
  subjects {
    id
    value
  }
  organizations {
    id
    value
  }
  pages {
    ...ApplicationPageFragment
  }
  extracted_authors {
    id
    value
  }
  extracted_organizations {
    id
    value
  }
}
    ${ApplicationPageFragmentFragmentDoc}`;
export const PageWithTagsFragmentFragmentDoc = gql`
    fragment PageWithTagsFragment on PageObject {
  id
  tags {
    id
    name
    type
    parent_tag_id
    origin
  }
}
    `;
export const NewTagFragmentDoc = gql`
    fragment NewTag on TagDisplayObject {
  id
  label
  name
  origin
  type
}
    `;
export const EntryDateFragmentDoc = gql`
    fragment EntryDate on TimelineEntryObject {
  id
  entry_date
  pages {
    id
  }
}
    `;
export const UpdateMarkedImportantDocument = gql`
    mutation UpdateMarkedImportant($entryID: BigInt!, $markedImportant: Boolean!) {
  updateMarkedImportant(
    data: {entryID: $entryID, marked_important: $markedImportant}
  ) {
    id
    marked_important
  }
}
    `;
export type UpdateMarkedImportantMutationFn = Apollo.MutationFunction<UpdateMarkedImportantMutation, UpdateMarkedImportantMutationVariables>;

/**
 * __useUpdateMarkedImportantMutation__
 *
 * To run a mutation, you first call `useUpdateMarkedImportantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMarkedImportantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMarkedImportantMutation, { data, loading, error }] = useUpdateMarkedImportantMutation({
 *   variables: {
 *      entryID: // value for 'entryID'
 *      markedImportant: // value for 'markedImportant'
 *   },
 * });
 */
export function useUpdateMarkedImportantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMarkedImportantMutation, UpdateMarkedImportantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMarkedImportantMutation, UpdateMarkedImportantMutationVariables>(UpdateMarkedImportantDocument, options);
      }
export type UpdateMarkedImportantMutationHookResult = ReturnType<typeof useUpdateMarkedImportantMutation>;
export type UpdateMarkedImportantMutationResult = Apollo.MutationResult<UpdateMarkedImportantMutation>;
export type UpdateMarkedImportantMutationOptions = Apollo.BaseMutationOptions<UpdateMarkedImportantMutation, UpdateMarkedImportantMutationVariables>;
export const CreateCaseDocument = gql`
    mutation createCase($createCaseInput: CreateCaseInput!) {
  createCase(data: $createCaseInput) {
    ...CaseFragment
  }
}
    ${CaseFragmentFragmentDoc}`;
export type CreateCaseMutationFn = Apollo.MutationFunction<CreateCaseMutation, CreateCaseMutationVariables>;

/**
 * __useCreateCaseMutation__
 *
 * To run a mutation, you first call `useCreateCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCaseMutation, { data, loading, error }] = useCreateCaseMutation({
 *   variables: {
 *      createCaseInput: // value for 'createCaseInput'
 *   },
 * });
 */
export function useCreateCaseMutation(baseOptions?: Apollo.MutationHookOptions<CreateCaseMutation, CreateCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCaseMutation, CreateCaseMutationVariables>(CreateCaseDocument, options);
      }
export type CreateCaseMutationHookResult = ReturnType<typeof useCreateCaseMutation>;
export type CreateCaseMutationResult = Apollo.MutationResult<CreateCaseMutation>;
export type CreateCaseMutationOptions = Apollo.BaseMutationOptions<CreateCaseMutation, CreateCaseMutationVariables>;
export const UpdateCaseDocument = gql`
    mutation UpdateCase($changeCaseDetailsInput: ChangeCaseDetailsInput!) {
  changeCaseDetails(data: $changeCaseDetailsInput) {
    ...CaseFragment
  }
}
    ${CaseFragmentFragmentDoc}`;
export type UpdateCaseMutationFn = Apollo.MutationFunction<UpdateCaseMutation, UpdateCaseMutationVariables>;

/**
 * __useUpdateCaseMutation__
 *
 * To run a mutation, you first call `useUpdateCaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseMutation, { data, loading, error }] = useUpdateCaseMutation({
 *   variables: {
 *      changeCaseDetailsInput: // value for 'changeCaseDetailsInput'
 *   },
 * });
 */
export function useUpdateCaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCaseMutation, UpdateCaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCaseMutation, UpdateCaseMutationVariables>(UpdateCaseDocument, options);
      }
export type UpdateCaseMutationHookResult = ReturnType<typeof useUpdateCaseMutation>;
export type UpdateCaseMutationResult = Apollo.MutationResult<UpdateCaseMutation>;
export type UpdateCaseMutationOptions = Apollo.BaseMutationOptions<UpdateCaseMutation, UpdateCaseMutationVariables>;
export const SetDuplicateThresholdDocument = gql`
    mutation setDuplicateThreshold($data: SetDuplicateThresholdInput!) {
  setDuplicateThreshold(data: $data) {
    ...CaseFragment
  }
}
    ${CaseFragmentFragmentDoc}`;
export type SetDuplicateThresholdMutationFn = Apollo.MutationFunction<SetDuplicateThresholdMutation, SetDuplicateThresholdMutationVariables>;

/**
 * __useSetDuplicateThresholdMutation__
 *
 * To run a mutation, you first call `useSetDuplicateThresholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDuplicateThresholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDuplicateThresholdMutation, { data, loading, error }] = useSetDuplicateThresholdMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetDuplicateThresholdMutation(baseOptions?: Apollo.MutationHookOptions<SetDuplicateThresholdMutation, SetDuplicateThresholdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetDuplicateThresholdMutation, SetDuplicateThresholdMutationVariables>(SetDuplicateThresholdDocument, options);
      }
export type SetDuplicateThresholdMutationHookResult = ReturnType<typeof useSetDuplicateThresholdMutation>;
export type SetDuplicateThresholdMutationResult = Apollo.MutationResult<SetDuplicateThresholdMutation>;
export type SetDuplicateThresholdMutationOptions = Apollo.BaseMutationOptions<SetDuplicateThresholdMutation, SetDuplicateThresholdMutationVariables>;
export const UpdateCaseUserLocationDocument = gql`
    mutation updateCaseUserLocation($data: UpdateCaseUserLocationInput!) {
  updateCaseUserLocation(data: $data) {
    ...CaseFragment
  }
}
    ${CaseFragmentFragmentDoc}`;
export type UpdateCaseUserLocationMutationFn = Apollo.MutationFunction<UpdateCaseUserLocationMutation, UpdateCaseUserLocationMutationVariables>;

/**
 * __useUpdateCaseUserLocationMutation__
 *
 * To run a mutation, you first call `useUpdateCaseUserLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCaseUserLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCaseUserLocationMutation, { data, loading, error }] = useUpdateCaseUserLocationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCaseUserLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCaseUserLocationMutation, UpdateCaseUserLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCaseUserLocationMutation, UpdateCaseUserLocationMutationVariables>(UpdateCaseUserLocationDocument, options);
      }
export type UpdateCaseUserLocationMutationHookResult = ReturnType<typeof useUpdateCaseUserLocationMutation>;
export type UpdateCaseUserLocationMutationResult = Apollo.MutationResult<UpdateCaseUserLocationMutation>;
export type UpdateCaseUserLocationMutationOptions = Apollo.BaseMutationOptions<UpdateCaseUserLocationMutation, UpdateCaseUserLocationMutationVariables>;
export const GetCasesDocument = gql`
    query getCases {
  cases {
    caseID: id
    caseName: case_name
    userID: user_id
    caseStatus: case_status
    claimReason: claim_reason
    createDate: create_date
    dateOfBirth: date_of_birth
    dateOfInjury: date_of_injury
    dueDate: due_date
    isReady: is_ready
    fullName: full_name
    lastOpened: last_opened
    pageCount: number_of_pages
    pagesViewed: number_of_pages_viewed
    similarityThreshold: similarity_threshold
    archived_at: archived_at
    archived_by: archived_by
    ref_id: ref_id
  }
}
    `;

/**
 * __useGetCasesQuery__
 *
 * To run a query within a React component, call `useGetCasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCasesQuery(baseOptions?: Apollo.QueryHookOptions<GetCasesQuery, GetCasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCasesQuery, GetCasesQueryVariables>(GetCasesDocument, options);
      }
export function useGetCasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCasesQuery, GetCasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCasesQuery, GetCasesQueryVariables>(GetCasesDocument, options);
        }
export type GetCasesQueryHookResult = ReturnType<typeof useGetCasesQuery>;
export type GetCasesLazyQueryHookResult = ReturnType<typeof useGetCasesLazyQuery>;
export type GetCasesQueryResult = Apollo.QueryResult<GetCasesQuery, GetCasesQueryVariables>;
export const GetCaseDocument = gql`
    query getCase($id: String!) {
  case(id: $id) {
    caseID: id
    caseName: case_name
    userID: user_id
    caseStatus: case_status
    claimReason: claim_reason
    createDate: create_date
    dateOfBirth: date_of_birth
    dateOfInjury: date_of_injury
    dueDate: due_date
    isReady: is_ready
    fullName: full_name
    lastOpened: last_opened
    pageCount: number_of_pages
    pagesViewed: number_of_pages_viewed
    similarityThreshold: similarity_threshold
    ref_id: ref_id
    duplicates_regenerate: duplicates_regenerate
    caseUserLocation: case_user_location {
      pageID: page_id
      view
      timelineEntryID: timeline_entry_id
      documentID: document_id
    }
  }
}
    `;

/**
 * __useGetCaseQuery__
 *
 * To run a query within a React component, call `useGetCaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCaseQuery(baseOptions: Apollo.QueryHookOptions<GetCaseQuery, GetCaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCaseQuery, GetCaseQueryVariables>(GetCaseDocument, options);
      }
export function useGetCaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCaseQuery, GetCaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCaseQuery, GetCaseQueryVariables>(GetCaseDocument, options);
        }
export type GetCaseQueryHookResult = ReturnType<typeof useGetCaseQuery>;
export type GetCaseLazyQueryHookResult = ReturnType<typeof useGetCaseLazyQuery>;
export type GetCaseQueryResult = Apollo.QueryResult<GetCaseQuery, GetCaseQueryVariables>;
export const GetPageByIdDocument = gql`
    query getPageByID($id: BigInt!) {
  page(id: $id) {
    pageID: id
    source {
      name
    }
    documentID: document_id
    pageNumber: page_number
    pageID: id
    pageDate: page_date
    correctedDate: corrected_page_date
    timelineEntryID: timeline_entry_id
    tags {
      id
      name
      type
      parent_tag_id
      origin
      label
    }
  }
}
    `;

/**
 * __useGetPageByIdQuery__
 *
 * To run a query within a React component, call `useGetPageByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPageByIdQuery(baseOptions: Apollo.QueryHookOptions<GetPageByIdQuery, GetPageByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageByIdQuery, GetPageByIdQueryVariables>(GetPageByIdDocument, options);
      }
export function useGetPageByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageByIdQuery, GetPageByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageByIdQuery, GetPageByIdQueryVariables>(GetPageByIdDocument, options);
        }
export type GetPageByIdQueryHookResult = ReturnType<typeof useGetPageByIdQuery>;
export type GetPageByIdLazyQueryHookResult = ReturnType<typeof useGetPageByIdLazyQuery>;
export type GetPageByIdQueryResult = Apollo.QueryResult<GetPageByIdQuery, GetPageByIdQueryVariables>;
export const PaginatedPagesDocument = gql`
    query paginatedPages($where: DocumentsWhereInput, $pagesViewedWhere: PagesViewedWhereInput, $orderBy: DocumentsOrderByInput, $skip: Int, $take: Int) {
  paginatedPages(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
    ...paginatedPage
  }
}
    ${PaginatedPageFragmentDoc}`;

/**
 * __usePaginatedPagesQuery__
 *
 * To run a query within a React component, call `usePaginatedPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaginatedPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaginatedPagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      pagesViewedWhere: // value for 'pagesViewedWhere'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePaginatedPagesQuery(baseOptions?: Apollo.QueryHookOptions<PaginatedPagesQuery, PaginatedPagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaginatedPagesQuery, PaginatedPagesQueryVariables>(PaginatedPagesDocument, options);
      }
export function usePaginatedPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaginatedPagesQuery, PaginatedPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaginatedPagesQuery, PaginatedPagesQueryVariables>(PaginatedPagesDocument, options);
        }
export type PaginatedPagesQueryHookResult = ReturnType<typeof usePaginatedPagesQuery>;
export type PaginatedPagesLazyQueryHookResult = ReturnType<typeof usePaginatedPagesLazyQuery>;
export type PaginatedPagesQueryResult = Apollo.QueryResult<PaginatedPagesQuery, PaginatedPagesQueryVariables>;
export const DocumentListDescriptorDocument = gql`
    query documentListDescriptor($where: DocumentsWhereInput, $orderBy: DocumentsOrderByInput) {
  documentListDescriptor(where: $where, orderBy: $orderBy) {
    documentID: document_id
    pages {
      id
      pageNumber: page_number
    }
  }
}
    `;

/**
 * __useDocumentListDescriptorQuery__
 *
 * To run a query within a React component, call `useDocumentListDescriptorQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListDescriptorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListDescriptorQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useDocumentListDescriptorQuery(baseOptions?: Apollo.QueryHookOptions<DocumentListDescriptorQuery, DocumentListDescriptorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentListDescriptorQuery, DocumentListDescriptorQueryVariables>(DocumentListDescriptorDocument, options);
      }
export function useDocumentListDescriptorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentListDescriptorQuery, DocumentListDescriptorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentListDescriptorQuery, DocumentListDescriptorQueryVariables>(DocumentListDescriptorDocument, options);
        }
export type DocumentListDescriptorQueryHookResult = ReturnType<typeof useDocumentListDescriptorQuery>;
export type DocumentListDescriptorLazyQueryHookResult = ReturnType<typeof useDocumentListDescriptorLazyQuery>;
export type DocumentListDescriptorQueryResult = Apollo.QueryResult<DocumentListDescriptorQuery, DocumentListDescriptorQueryVariables>;
export const GetDuplicatesDocument = gql`
    query getDuplicates($case_id: String!, $hide_resolved: Boolean!, $set_id: BigInt) {
  duplicates(case_id: $case_id, hide_resolved: $hide_resolved, set_id: $set_id) {
    setID: id
    viewedAt: viewed_at
    originalPage: original_page {
      setID: duplicates_set_id
      pageID: page_id
      pageNumber: page_number
      rotationAngle: rotation_angle
      isDuplicate: is_duplicate
      documentID: document_id
      documentFileName: document_file_name
    }
    similarPages: similar_pages {
      ...DuplicatePageFragment
    }
  }
}
    ${DuplicatePageFragmentFragmentDoc}`;

/**
 * __useGetDuplicatesQuery__
 *
 * To run a query within a React component, call `useGetDuplicatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDuplicatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDuplicatesQuery({
 *   variables: {
 *      case_id: // value for 'case_id'
 *      hide_resolved: // value for 'hide_resolved'
 *      set_id: // value for 'set_id'
 *   },
 * });
 */
export function useGetDuplicatesQuery(baseOptions: Apollo.QueryHookOptions<GetDuplicatesQuery, GetDuplicatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDuplicatesQuery, GetDuplicatesQueryVariables>(GetDuplicatesDocument, options);
      }
export function useGetDuplicatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDuplicatesQuery, GetDuplicatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDuplicatesQuery, GetDuplicatesQueryVariables>(GetDuplicatesDocument, options);
        }
export type GetDuplicatesQueryHookResult = ReturnType<typeof useGetDuplicatesQuery>;
export type GetDuplicatesLazyQueryHookResult = ReturnType<typeof useGetDuplicatesLazyQuery>;
export type GetDuplicatesQueryResult = Apollo.QueryResult<GetDuplicatesQuery, GetDuplicatesQueryVariables>;
export const GetDuplicateStatsDocument = gql`
    query getDuplicateStats($case_id: String!) {
  duplicateStats(case_id: $case_id) {
    resolvedCount: resolved_count
    unresolvedCount: unresolved_count
    unresolvedDuplicateSetsCount: unresolved_duplicate_sets_count
  }
}
    `;

/**
 * __useGetDuplicateStatsQuery__
 *
 * To run a query within a React component, call `useGetDuplicateStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDuplicateStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDuplicateStatsQuery({
 *   variables: {
 *      case_id: // value for 'case_id'
 *   },
 * });
 */
export function useGetDuplicateStatsQuery(baseOptions: Apollo.QueryHookOptions<GetDuplicateStatsQuery, GetDuplicateStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDuplicateStatsQuery, GetDuplicateStatsQueryVariables>(GetDuplicateStatsDocument, options);
      }
export function useGetDuplicateStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDuplicateStatsQuery, GetDuplicateStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDuplicateStatsQuery, GetDuplicateStatsQueryVariables>(GetDuplicateStatsDocument, options);
        }
export type GetDuplicateStatsQueryHookResult = ReturnType<typeof useGetDuplicateStatsQuery>;
export type GetDuplicateStatsLazyQueryHookResult = ReturnType<typeof useGetDuplicateStatsLazyQuery>;
export type GetDuplicateStatsQueryResult = Apollo.QueryResult<GetDuplicateStatsQuery, GetDuplicateStatsQueryVariables>;
export const SetNewOriginalDocument = gql`
    mutation setNewOriginal($data: SetOriginalPageDuplicateInput!) {
  setNewOriginal(data: $data) {
    setID: id
    originalPage: original_page {
      setID: duplicates_set_id
      pageID: page_id
      pageNumber: page_number
      isDuplicate: is_duplicate
      documentID: document_id
      documentFileName: document_file_name
    }
    similarPages: similar_pages {
      ...DuplicatePageFragment
    }
  }
}
    ${DuplicatePageFragmentFragmentDoc}`;
export type SetNewOriginalMutationFn = Apollo.MutationFunction<SetNewOriginalMutation, SetNewOriginalMutationVariables>;

/**
 * __useSetNewOriginalMutation__
 *
 * To run a mutation, you first call `useSetNewOriginalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNewOriginalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNewOriginalMutation, { data, loading, error }] = useSetNewOriginalMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSetNewOriginalMutation(baseOptions?: Apollo.MutationHookOptions<SetNewOriginalMutation, SetNewOriginalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNewOriginalMutation, SetNewOriginalMutationVariables>(SetNewOriginalDocument, options);
      }
export type SetNewOriginalMutationHookResult = ReturnType<typeof useSetNewOriginalMutation>;
export type SetNewOriginalMutationResult = Apollo.MutationResult<SetNewOriginalMutation>;
export type SetNewOriginalMutationOptions = Apollo.BaseMutationOptions<SetNewOriginalMutation, SetNewOriginalMutationVariables>;
export const ResolveSelectedDuplicatePagesDocument = gql`
    mutation resolveSelectedDuplicatePages($data: ResolveSelectedDuplicatePagesInput!) {
  resolveSelectedDuplicatePages(data: $data) {
    setID: id
    originalPage: original_page {
      setID: duplicates_set_id
      pageID: page_id
      pageNumber: page_number
      isDuplicate: is_duplicate
      documentID: document_id
      documentFileName: document_file_name
    }
    similarPages: similar_pages {
      ...DuplicatePageFragment
    }
  }
}
    ${DuplicatePageFragmentFragmentDoc}`;
export type ResolveSelectedDuplicatePagesMutationFn = Apollo.MutationFunction<ResolveSelectedDuplicatePagesMutation, ResolveSelectedDuplicatePagesMutationVariables>;

/**
 * __useResolveSelectedDuplicatePagesMutation__
 *
 * To run a mutation, you first call `useResolveSelectedDuplicatePagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResolveSelectedDuplicatePagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resolveSelectedDuplicatePagesMutation, { data, loading, error }] = useResolveSelectedDuplicatePagesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useResolveSelectedDuplicatePagesMutation(baseOptions?: Apollo.MutationHookOptions<ResolveSelectedDuplicatePagesMutation, ResolveSelectedDuplicatePagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResolveSelectedDuplicatePagesMutation, ResolveSelectedDuplicatePagesMutationVariables>(ResolveSelectedDuplicatePagesDocument, options);
      }
export type ResolveSelectedDuplicatePagesMutationHookResult = ReturnType<typeof useResolveSelectedDuplicatePagesMutation>;
export type ResolveSelectedDuplicatePagesMutationResult = Apollo.MutationResult<ResolveSelectedDuplicatePagesMutation>;
export type ResolveSelectedDuplicatePagesMutationOptions = Apollo.BaseMutationOptions<ResolveSelectedDuplicatePagesMutation, ResolveSelectedDuplicatePagesMutationVariables>;
export const UpdateDuplicatePageSetViewedDocument = gql`
    mutation UpdateDuplicatePageSetViewed($data: UpdateDuplicatePageSetViewedInput!) {
  updateDuplicatePageSetViewedStatus(data: $data) {
    id
    viewed_at
  }
}
    `;
export type UpdateDuplicatePageSetViewedMutationFn = Apollo.MutationFunction<UpdateDuplicatePageSetViewedMutation, UpdateDuplicatePageSetViewedMutationVariables>;

/**
 * __useUpdateDuplicatePageSetViewedMutation__
 *
 * To run a mutation, you first call `useUpdateDuplicatePageSetViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDuplicatePageSetViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDuplicatePageSetViewedMutation, { data, loading, error }] = useUpdateDuplicatePageSetViewedMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDuplicatePageSetViewedMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDuplicatePageSetViewedMutation, UpdateDuplicatePageSetViewedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDuplicatePageSetViewedMutation, UpdateDuplicatePageSetViewedMutationVariables>(UpdateDuplicatePageSetViewedDocument, options);
      }
export type UpdateDuplicatePageSetViewedMutationHookResult = ReturnType<typeof useUpdateDuplicatePageSetViewedMutation>;
export type UpdateDuplicatePageSetViewedMutationResult = Apollo.MutationResult<UpdateDuplicatePageSetViewedMutation>;
export type UpdateDuplicatePageSetViewedMutationOptions = Apollo.BaseMutationOptions<UpdateDuplicatePageSetViewedMutation, UpdateDuplicatePageSetViewedMutationVariables>;
export const GetNotesDocumentsDocument = gql`
    query getNotesDocuments($where: NotesWhereInput!) {
  notes(where: $where) {
    ...NoteFragment
  }
}
    ${NoteFragmentFragmentDoc}`;

/**
 * __useGetNotesDocumentsQuery__
 *
 * To run a query within a React component, call `useGetNotesDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotesDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotesDocumentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetNotesDocumentsQuery(baseOptions: Apollo.QueryHookOptions<GetNotesDocumentsQuery, GetNotesDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotesDocumentsQuery, GetNotesDocumentsQueryVariables>(GetNotesDocumentsDocument, options);
      }
export function useGetNotesDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotesDocumentsQuery, GetNotesDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotesDocumentsQuery, GetNotesDocumentsQueryVariables>(GetNotesDocumentsDocument, options);
        }
export type GetNotesDocumentsQueryHookResult = ReturnType<typeof useGetNotesDocumentsQuery>;
export type GetNotesDocumentsLazyQueryHookResult = ReturnType<typeof useGetNotesDocumentsLazyQuery>;
export type GetNotesDocumentsQueryResult = Apollo.QueryResult<GetNotesDocumentsQuery, GetNotesDocumentsQueryVariables>;
export const GetNoteDocument = gql`
    query getNote($id: String!) {
  note(id: $id) {
    ...NoteFragment
  }
}
    ${NoteFragmentFragmentDoc}`;

/**
 * __useGetNoteQuery__
 *
 * To run a query within a React component, call `useGetNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNoteQuery(baseOptions: Apollo.QueryHookOptions<GetNoteQuery, GetNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNoteQuery, GetNoteQueryVariables>(GetNoteDocument, options);
      }
export function useGetNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNoteQuery, GetNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNoteQuery, GetNoteQueryVariables>(GetNoteDocument, options);
        }
export type GetNoteQueryHookResult = ReturnType<typeof useGetNoteQuery>;
export type GetNoteLazyQueryHookResult = ReturnType<typeof useGetNoteLazyQuery>;
export type GetNoteQueryResult = Apollo.QueryResult<GetNoteQuery, GetNoteQueryVariables>;
export const CreateNoteDocument = gql`
    mutation createNote($case_id: String!, $data: CreateNoteInput!) {
  createNote(case_id: $case_id, data: $data) {
    ...NoteFragment
  }
}
    ${NoteFragmentFragmentDoc}`;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      case_id: // value for 'case_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const UpdateNoteDocument = gql`
    mutation updateNote($note_id: String!, $editNoteData: EditNoteInput!) {
  updateNote(note_id: $note_id, data: $editNoteData) {
    ...NoteFragment
  }
}
    ${NoteFragmentFragmentDoc}`;
export type UpdateNoteMutationFn = Apollo.MutationFunction<UpdateNoteMutation, UpdateNoteMutationVariables>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      note_id: // value for 'note_id'
 *      editNoteData: // value for 'editNoteData'
 *   },
 * });
 */
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<UpdateNoteMutation, UpdateNoteMutationVariables>;
export const DeleteNoteDocument = gql`
    mutation deleteNote($id: String!) {
  deleteNote(id: $id)
}
    `;
export type DeleteNoteMutationFn = Apollo.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, options);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const AddTagToNoteDocument = gql`
    mutation addTagToNote($note_id: String!, $tag_id: String!) {
  addTagToNote(note_id: $note_id, tag_id: $tag_id) {
    ...NoteFragment
  }
}
    ${NoteFragmentFragmentDoc}`;
export type AddTagToNoteMutationFn = Apollo.MutationFunction<AddTagToNoteMutation, AddTagToNoteMutationVariables>;

/**
 * __useAddTagToNoteMutation__
 *
 * To run a mutation, you first call `useAddTagToNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagToNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagToNoteMutation, { data, loading, error }] = useAddTagToNoteMutation({
 *   variables: {
 *      note_id: // value for 'note_id'
 *      tag_id: // value for 'tag_id'
 *   },
 * });
 */
export function useAddTagToNoteMutation(baseOptions?: Apollo.MutationHookOptions<AddTagToNoteMutation, AddTagToNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTagToNoteMutation, AddTagToNoteMutationVariables>(AddTagToNoteDocument, options);
      }
export type AddTagToNoteMutationHookResult = ReturnType<typeof useAddTagToNoteMutation>;
export type AddTagToNoteMutationResult = Apollo.MutationResult<AddTagToNoteMutation>;
export type AddTagToNoteMutationOptions = Apollo.BaseMutationOptions<AddTagToNoteMutation, AddTagToNoteMutationVariables>;
export const RemoveTagFromNoteDocument = gql`
    mutation removeTagFromNote($note_id: String!, $tag_id: String!) {
  removeTagFromNote(note_id: $note_id, tag_id: $tag_id) {
    ...NoteFragment
  }
}
    ${NoteFragmentFragmentDoc}`;
export type RemoveTagFromNoteMutationFn = Apollo.MutationFunction<RemoveTagFromNoteMutation, RemoveTagFromNoteMutationVariables>;

/**
 * __useRemoveTagFromNoteMutation__
 *
 * To run a mutation, you first call `useRemoveTagFromNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagFromNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagFromNoteMutation, { data, loading, error }] = useRemoveTagFromNoteMutation({
 *   variables: {
 *      note_id: // value for 'note_id'
 *      tag_id: // value for 'tag_id'
 *   },
 * });
 */
export function useRemoveTagFromNoteMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTagFromNoteMutation, RemoveTagFromNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTagFromNoteMutation, RemoveTagFromNoteMutationVariables>(RemoveTagFromNoteDocument, options);
      }
export type RemoveTagFromNoteMutationHookResult = ReturnType<typeof useRemoveTagFromNoteMutation>;
export type RemoveTagFromNoteMutationResult = Apollo.MutationResult<RemoveTagFromNoteMutation>;
export type RemoveTagFromNoteMutationOptions = Apollo.BaseMutationOptions<RemoveTagFromNoteMutation, RemoveTagFromNoteMutationVariables>;
export const CustomTagsDocument = gql`
    query customTags($where: CustomTagsWhereInput!) {
  custom_tags(where: $where) {
    ...CustomTagFragment
  }
}
    ${CustomTagFragmentFragmentDoc}`;

/**
 * __useCustomTagsQuery__
 *
 * To run a query within a React component, call `useCustomTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomTagsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCustomTagsQuery(baseOptions: Apollo.QueryHookOptions<CustomTagsQuery, CustomTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomTagsQuery, CustomTagsQueryVariables>(CustomTagsDocument, options);
      }
export function useCustomTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomTagsQuery, CustomTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomTagsQuery, CustomTagsQueryVariables>(CustomTagsDocument, options);
        }
export type CustomTagsQueryHookResult = ReturnType<typeof useCustomTagsQuery>;
export type CustomTagsLazyQueryHookResult = ReturnType<typeof useCustomTagsLazyQuery>;
export type CustomTagsQueryResult = Apollo.QueryResult<CustomTagsQuery, CustomTagsQueryVariables>;
export const GetCustomTagsForNotesDocument = gql`
    query getCustomTagsForNotes($where: NotesWhereInput!) {
  notes(where: $where) {
    note_tags {
      ...NoteTagFragment
    }
  }
}
    ${NoteTagFragmentFragmentDoc}`;

/**
 * __useGetCustomTagsForNotesQuery__
 *
 * To run a query within a React component, call `useGetCustomTagsForNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomTagsForNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomTagsForNotesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCustomTagsForNotesQuery(baseOptions: Apollo.QueryHookOptions<GetCustomTagsForNotesQuery, GetCustomTagsForNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomTagsForNotesQuery, GetCustomTagsForNotesQueryVariables>(GetCustomTagsForNotesDocument, options);
      }
export function useGetCustomTagsForNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomTagsForNotesQuery, GetCustomTagsForNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomTagsForNotesQuery, GetCustomTagsForNotesQueryVariables>(GetCustomTagsForNotesDocument, options);
        }
export type GetCustomTagsForNotesQueryHookResult = ReturnType<typeof useGetCustomTagsForNotesQuery>;
export type GetCustomTagsForNotesLazyQueryHookResult = ReturnType<typeof useGetCustomTagsForNotesLazyQuery>;
export type GetCustomTagsForNotesQueryResult = Apollo.QueryResult<GetCustomTagsForNotesQuery, GetCustomTagsForNotesQueryVariables>;
export const CreateCustomTagDocument = gql`
    mutation createCustomTag($data: CreateCustomTagInput!) {
  createCustomTag(data: $data) {
    ...CustomTagFragment
  }
}
    ${CustomTagFragmentFragmentDoc}`;
export type CreateCustomTagMutationFn = Apollo.MutationFunction<CreateCustomTagMutation, CreateCustomTagMutationVariables>;

/**
 * __useCreateCustomTagMutation__
 *
 * To run a mutation, you first call `useCreateCustomTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomTagMutation, { data, loading, error }] = useCreateCustomTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCustomTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomTagMutation, CreateCustomTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomTagMutation, CreateCustomTagMutationVariables>(CreateCustomTagDocument, options);
      }
export type CreateCustomTagMutationHookResult = ReturnType<typeof useCreateCustomTagMutation>;
export type CreateCustomTagMutationResult = Apollo.MutationResult<CreateCustomTagMutation>;
export type CreateCustomTagMutationOptions = Apollo.BaseMutationOptions<CreateCustomTagMutation, CreateCustomTagMutationVariables>;
export const UpdateCustomTagDocument = gql`
    mutation updateCustomTag($tag_id: String!, $data: UpdateCustomTagInput!) {
  updateCustomTag(tag_id: $tag_id, data: $data) {
    ...CustomTagFragment
  }
}
    ${CustomTagFragmentFragmentDoc}`;
export type UpdateCustomTagMutationFn = Apollo.MutationFunction<UpdateCustomTagMutation, UpdateCustomTagMutationVariables>;

/**
 * __useUpdateCustomTagMutation__
 *
 * To run a mutation, you first call `useUpdateCustomTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomTagMutation, { data, loading, error }] = useUpdateCustomTagMutation({
 *   variables: {
 *      tag_id: // value for 'tag_id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCustomTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomTagMutation, UpdateCustomTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomTagMutation, UpdateCustomTagMutationVariables>(UpdateCustomTagDocument, options);
      }
export type UpdateCustomTagMutationHookResult = ReturnType<typeof useUpdateCustomTagMutation>;
export type UpdateCustomTagMutationResult = Apollo.MutationResult<UpdateCustomTagMutation>;
export type UpdateCustomTagMutationOptions = Apollo.BaseMutationOptions<UpdateCustomTagMutation, UpdateCustomTagMutationVariables>;
export const DeleteCustomTagDocument = gql`
    mutation deleteCustomTag($tag_id: String!) {
  deleteCustomTag(tag_id: $tag_id)
}
    `;
export type DeleteCustomTagMutationFn = Apollo.MutationFunction<DeleteCustomTagMutation, DeleteCustomTagMutationVariables>;

/**
 * __useDeleteCustomTagMutation__
 *
 * To run a mutation, you first call `useDeleteCustomTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomTagMutation, { data, loading, error }] = useDeleteCustomTagMutation({
 *   variables: {
 *      tag_id: // value for 'tag_id'
 *   },
 * });
 */
export function useDeleteCustomTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomTagMutation, DeleteCustomTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomTagMutation, DeleteCustomTagMutationVariables>(DeleteCustomTagDocument, options);
      }
export type DeleteCustomTagMutationHookResult = ReturnType<typeof useDeleteCustomTagMutation>;
export type DeleteCustomTagMutationResult = Apollo.MutationResult<DeleteCustomTagMutation>;
export type DeleteCustomTagMutationOptions = Apollo.BaseMutationOptions<DeleteCustomTagMutation, DeleteCustomTagMutationVariables>;
export const GetTimelineReportsDocument = gql`
    query getTimelineReports($where: TimelineReportsWhereInput!) {
  timeline_reports(where: $where) {
    ...TimelineReportFragment
  }
}
    ${TimelineReportFragmentFragmentDoc}`;

/**
 * __useGetTimelineReportsQuery__
 *
 * To run a query within a React component, call `useGetTimelineReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimelineReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimelineReportsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTimelineReportsQuery(baseOptions: Apollo.QueryHookOptions<GetTimelineReportsQuery, GetTimelineReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimelineReportsQuery, GetTimelineReportsQueryVariables>(GetTimelineReportsDocument, options);
      }
export function useGetTimelineReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimelineReportsQuery, GetTimelineReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimelineReportsQuery, GetTimelineReportsQueryVariables>(GetTimelineReportsDocument, options);
        }
export type GetTimelineReportsQueryHookResult = ReturnType<typeof useGetTimelineReportsQuery>;
export type GetTimelineReportsLazyQueryHookResult = ReturnType<typeof useGetTimelineReportsLazyQuery>;
export type GetTimelineReportsQueryResult = Apollo.QueryResult<GetTimelineReportsQuery, GetTimelineReportsQueryVariables>;
export const GetTimelineReportDocument = gql`
    query getTimelineReport($id: String!) {
  timeline_report(id: $id) {
    ...TimelineReportFragment
  }
}
    ${TimelineReportFragmentFragmentDoc}`;

/**
 * __useGetTimelineReportQuery__
 *
 * To run a query within a React component, call `useGetTimelineReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimelineReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimelineReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTimelineReportQuery(baseOptions: Apollo.QueryHookOptions<GetTimelineReportQuery, GetTimelineReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimelineReportQuery, GetTimelineReportQueryVariables>(GetTimelineReportDocument, options);
      }
export function useGetTimelineReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimelineReportQuery, GetTimelineReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimelineReportQuery, GetTimelineReportQueryVariables>(GetTimelineReportDocument, options);
        }
export type GetTimelineReportQueryHookResult = ReturnType<typeof useGetTimelineReportQuery>;
export type GetTimelineReportLazyQueryHookResult = ReturnType<typeof useGetTimelineReportLazyQuery>;
export type GetTimelineReportQueryResult = Apollo.QueryResult<GetTimelineReportQuery, GetTimelineReportQueryVariables>;
export const CreateTimelineReportDocument = gql`
    mutation createTimelineReport($data: CreateTimelineReportInput!) {
  createTimelineReport(data: $data) {
    ...TimelineReportFragment
  }
}
    ${TimelineReportFragmentFragmentDoc}`;
export type CreateTimelineReportMutationFn = Apollo.MutationFunction<CreateTimelineReportMutation, CreateTimelineReportMutationVariables>;

/**
 * __useCreateTimelineReportMutation__
 *
 * To run a mutation, you first call `useCreateTimelineReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimelineReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimelineReportMutation, { data, loading, error }] = useCreateTimelineReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTimelineReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimelineReportMutation, CreateTimelineReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimelineReportMutation, CreateTimelineReportMutationVariables>(CreateTimelineReportDocument, options);
      }
export type CreateTimelineReportMutationHookResult = ReturnType<typeof useCreateTimelineReportMutation>;
export type CreateTimelineReportMutationResult = Apollo.MutationResult<CreateTimelineReportMutation>;
export type CreateTimelineReportMutationOptions = Apollo.BaseMutationOptions<CreateTimelineReportMutation, CreateTimelineReportMutationVariables>;
export const UpdateTimelineReportDocument = gql`
    mutation updateTimelineReport($id: String!, $data: EditTimelineReportInput!) {
  updateTimelineReport(id: $id, data: $data) {
    ...TimelineReportFragment
  }
}
    ${TimelineReportFragmentFragmentDoc}`;
export type UpdateTimelineReportMutationFn = Apollo.MutationFunction<UpdateTimelineReportMutation, UpdateTimelineReportMutationVariables>;

/**
 * __useUpdateTimelineReportMutation__
 *
 * To run a mutation, you first call `useUpdateTimelineReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimelineReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimelineReportMutation, { data, loading, error }] = useUpdateTimelineReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTimelineReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimelineReportMutation, UpdateTimelineReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimelineReportMutation, UpdateTimelineReportMutationVariables>(UpdateTimelineReportDocument, options);
      }
export type UpdateTimelineReportMutationHookResult = ReturnType<typeof useUpdateTimelineReportMutation>;
export type UpdateTimelineReportMutationResult = Apollo.MutationResult<UpdateTimelineReportMutation>;
export type UpdateTimelineReportMutationOptions = Apollo.BaseMutationOptions<UpdateTimelineReportMutation, UpdateTimelineReportMutationVariables>;
export const DeleteTimelineReportDocument = gql`
    mutation deleteTimelineReport($id: String!) {
  deleteTimelineReport(id: $id)
}
    `;
export type DeleteTimelineReportMutationFn = Apollo.MutationFunction<DeleteTimelineReportMutation, DeleteTimelineReportMutationVariables>;

/**
 * __useDeleteTimelineReportMutation__
 *
 * To run a mutation, you first call `useDeleteTimelineReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTimelineReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTimelineReportMutation, { data, loading, error }] = useDeleteTimelineReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTimelineReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTimelineReportMutation, DeleteTimelineReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTimelineReportMutation, DeleteTimelineReportMutationVariables>(DeleteTimelineReportDocument, options);
      }
export type DeleteTimelineReportMutationHookResult = ReturnType<typeof useDeleteTimelineReportMutation>;
export type DeleteTimelineReportMutationResult = Apollo.MutationResult<DeleteTimelineReportMutation>;
export type DeleteTimelineReportMutationOptions = Apollo.BaseMutationOptions<DeleteTimelineReportMutation, DeleteTimelineReportMutationVariables>;
export const CreateTimelineReportSectionDocument = gql`
    mutation createTimelineReportSection($data: CreateTimelineReportSectionInput!) {
  createTimelineReportSection(data: $data) {
    id
    timeline_report_id
    name
  }
}
    `;
export type CreateTimelineReportSectionMutationFn = Apollo.MutationFunction<CreateTimelineReportSectionMutation, CreateTimelineReportSectionMutationVariables>;

/**
 * __useCreateTimelineReportSectionMutation__
 *
 * To run a mutation, you first call `useCreateTimelineReportSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimelineReportSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimelineReportSectionMutation, { data, loading, error }] = useCreateTimelineReportSectionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTimelineReportSectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimelineReportSectionMutation, CreateTimelineReportSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimelineReportSectionMutation, CreateTimelineReportSectionMutationVariables>(CreateTimelineReportSectionDocument, options);
      }
export type CreateTimelineReportSectionMutationHookResult = ReturnType<typeof useCreateTimelineReportSectionMutation>;
export type CreateTimelineReportSectionMutationResult = Apollo.MutationResult<CreateTimelineReportSectionMutation>;
export type CreateTimelineReportSectionMutationOptions = Apollo.BaseMutationOptions<CreateTimelineReportSectionMutation, CreateTimelineReportSectionMutationVariables>;
export const UpdateTimelineReportSectionDocument = gql`
    mutation updateTimelineReportSection($id: String!, $data: EditTimelineReportSectionInput!) {
  updateTimelineReportSection(id: $id, data: $data) {
    id
    timeline_report_id
    name
  }
}
    `;
export type UpdateTimelineReportSectionMutationFn = Apollo.MutationFunction<UpdateTimelineReportSectionMutation, UpdateTimelineReportSectionMutationVariables>;

/**
 * __useUpdateTimelineReportSectionMutation__
 *
 * To run a mutation, you first call `useUpdateTimelineReportSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimelineReportSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimelineReportSectionMutation, { data, loading, error }] = useUpdateTimelineReportSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTimelineReportSectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimelineReportSectionMutation, UpdateTimelineReportSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimelineReportSectionMutation, UpdateTimelineReportSectionMutationVariables>(UpdateTimelineReportSectionDocument, options);
      }
export type UpdateTimelineReportSectionMutationHookResult = ReturnType<typeof useUpdateTimelineReportSectionMutation>;
export type UpdateTimelineReportSectionMutationResult = Apollo.MutationResult<UpdateTimelineReportSectionMutation>;
export type UpdateTimelineReportSectionMutationOptions = Apollo.BaseMutationOptions<UpdateTimelineReportSectionMutation, UpdateTimelineReportSectionMutationVariables>;
export const GetCaseContentFiltersDocument = gql`
    query GetCaseContentFilters($caseID: String!) {
  contentTypesForFilter(caseID: $caseID) {
    id
    value: name
    label
    origin
    parent_tag_id
    type
    subItems: sub_tags {
      id
      value: name
      label
      origin
      parent_tag_id
      type
    }
  }
}
    `;

/**
 * __useGetCaseContentFiltersQuery__
 *
 * To run a query within a React component, call `useGetCaseContentFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseContentFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseContentFiltersQuery({
 *   variables: {
 *      caseID: // value for 'caseID'
 *   },
 * });
 */
export function useGetCaseContentFiltersQuery(baseOptions: Apollo.QueryHookOptions<GetCaseContentFiltersQuery, GetCaseContentFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCaseContentFiltersQuery, GetCaseContentFiltersQueryVariables>(GetCaseContentFiltersDocument, options);
      }
export function useGetCaseContentFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCaseContentFiltersQuery, GetCaseContentFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCaseContentFiltersQuery, GetCaseContentFiltersQueryVariables>(GetCaseContentFiltersDocument, options);
        }
export type GetCaseContentFiltersQueryHookResult = ReturnType<typeof useGetCaseContentFiltersQuery>;
export type GetCaseContentFiltersLazyQueryHookResult = ReturnType<typeof useGetCaseContentFiltersLazyQuery>;
export type GetCaseContentFiltersQueryResult = Apollo.QueryResult<GetCaseContentFiltersQuery, GetCaseContentFiltersQueryVariables>;
export const GetCaseSourceFiltersDocument = gql`
    query GetCaseSourceFilters($caseID: String!) {
  sourceTypesForFilter(caseID: $caseID) {
    id
    value: name
    label
    origin
    type
  }
}
    `;

/**
 * __useGetCaseSourceFiltersQuery__
 *
 * To run a query within a React component, call `useGetCaseSourceFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseSourceFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseSourceFiltersQuery({
 *   variables: {
 *      caseID: // value for 'caseID'
 *   },
 * });
 */
export function useGetCaseSourceFiltersQuery(baseOptions: Apollo.QueryHookOptions<GetCaseSourceFiltersQuery, GetCaseSourceFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCaseSourceFiltersQuery, GetCaseSourceFiltersQueryVariables>(GetCaseSourceFiltersDocument, options);
      }
export function useGetCaseSourceFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCaseSourceFiltersQuery, GetCaseSourceFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCaseSourceFiltersQuery, GetCaseSourceFiltersQueryVariables>(GetCaseSourceFiltersDocument, options);
        }
export type GetCaseSourceFiltersQueryHookResult = ReturnType<typeof useGetCaseSourceFiltersQuery>;
export type GetCaseSourceFiltersLazyQueryHookResult = ReturnType<typeof useGetCaseSourceFiltersLazyQuery>;
export type GetCaseSourceFiltersQueryResult = Apollo.QueryResult<GetCaseSourceFiltersQuery, GetCaseSourceFiltersQueryVariables>;
export const GetPageTagsDocument = gql`
    query GetPageTags($where: TagWhereInput) {
  tags(where: $where) {
    id
    name
    label
    type
    parent_tag_id
    origin
  }
}
    `;

/**
 * __useGetPageTagsQuery__
 *
 * To run a query within a React component, call `useGetPageTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageTagsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPageTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetPageTagsQuery, GetPageTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageTagsQuery, GetPageTagsQueryVariables>(GetPageTagsDocument, options);
      }
export function useGetPageTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageTagsQuery, GetPageTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageTagsQuery, GetPageTagsQueryVariables>(GetPageTagsDocument, options);
        }
export type GetPageTagsQueryHookResult = ReturnType<typeof useGetPageTagsQuery>;
export type GetPageTagsLazyQueryHookResult = ReturnType<typeof useGetPageTagsLazyQuery>;
export type GetPageTagsQueryResult = Apollo.QueryResult<GetPageTagsQuery, GetPageTagsQueryVariables>;
export const GetPageTagsWithChildrenDocument = gql`
    query GetPageTagsWithChildren($where: TagWhereInput) {
  tags(where: $where) {
    id
    name
    type
    parent_tag_id
    origin
    sub_tags {
      id
      name
      type
      parent_tag_id
      origin
    }
  }
}
    `;

/**
 * __useGetPageTagsWithChildrenQuery__
 *
 * To run a query within a React component, call `useGetPageTagsWithChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageTagsWithChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageTagsWithChildrenQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPageTagsWithChildrenQuery(baseOptions?: Apollo.QueryHookOptions<GetPageTagsWithChildrenQuery, GetPageTagsWithChildrenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageTagsWithChildrenQuery, GetPageTagsWithChildrenQueryVariables>(GetPageTagsWithChildrenDocument, options);
      }
export function useGetPageTagsWithChildrenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageTagsWithChildrenQuery, GetPageTagsWithChildrenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageTagsWithChildrenQuery, GetPageTagsWithChildrenQueryVariables>(GetPageTagsWithChildrenDocument, options);
        }
export type GetPageTagsWithChildrenQueryHookResult = ReturnType<typeof useGetPageTagsWithChildrenQuery>;
export type GetPageTagsWithChildrenLazyQueryHookResult = ReturnType<typeof useGetPageTagsWithChildrenLazyQuery>;
export type GetPageTagsWithChildrenQueryResult = Apollo.QueryResult<GetPageTagsWithChildrenQuery, GetPageTagsWithChildrenQueryVariables>;
export const GetTimelineEntriesDocument = gql`
    query GetTimelineEntries($timelineEntriesWhere: TimelineEntryWhereInput!, $timelineEntriesOrderBy: TimelineEntryOrderByInput!, $pagesWhere: PageWhereInput, $pagesOrderBy: PageOrderByInput, $pagesViewedWhere: PagesViewedWhereInput, $skip: Int, $take: Int) {
  entries: timelineEntires(
    where: $timelineEntriesWhere
    orderBy: $timelineEntriesOrderBy
    skip: $skip
    take: $take
  ) {
    id
    entry_date
    last_modified_date
    timeline_id
    created_date
    author_id
    org_id
    subject_id
    marked_important
    pages(where: $pagesWhere, orderBy: $pagesOrderBy) {
      ...ApplicationPageFragment
    }
    order
  }
}
    ${ApplicationPageFragmentFragmentDoc}`;

/**
 * __useGetTimelineEntriesQuery__
 *
 * To run a query within a React component, call `useGetTimelineEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimelineEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimelineEntriesQuery({
 *   variables: {
 *      timelineEntriesWhere: // value for 'timelineEntriesWhere'
 *      timelineEntriesOrderBy: // value for 'timelineEntriesOrderBy'
 *      pagesWhere: // value for 'pagesWhere'
 *      pagesOrderBy: // value for 'pagesOrderBy'
 *      pagesViewedWhere: // value for 'pagesViewedWhere'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetTimelineEntriesQuery(baseOptions: Apollo.QueryHookOptions<GetTimelineEntriesQuery, GetTimelineEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimelineEntriesQuery, GetTimelineEntriesQueryVariables>(GetTimelineEntriesDocument, options);
      }
export function useGetTimelineEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimelineEntriesQuery, GetTimelineEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimelineEntriesQuery, GetTimelineEntriesQueryVariables>(GetTimelineEntriesDocument, options);
        }
export type GetTimelineEntriesQueryHookResult = ReturnType<typeof useGetTimelineEntriesQuery>;
export type GetTimelineEntriesLazyQueryHookResult = ReturnType<typeof useGetTimelineEntriesLazyQuery>;
export type GetTimelineEntriesQueryResult = Apollo.QueryResult<GetTimelineEntriesQuery, GetTimelineEntriesQueryVariables>;
export const GetTimelineEntriesDescriptorsDocument = gql`
    query GetTimelineEntriesDescriptors($timelineEntriesWhere: TimelineEntryWhereInput!, $timelineEntriesOrderBy: TimelineEntryOrderByInput!, $pagesWhere: PageWhereInput!) {
  timelineEntryDescriptors(
    where: $timelineEntriesWhere
    orderBy: $timelineEntriesOrderBy
  ) {
    id: entry_id
    pages(where: $pagesWhere) {
      id
      pageNumber: page_number
      documentID: document_id
    }
  }
}
    `;

/**
 * __useGetTimelineEntriesDescriptorsQuery__
 *
 * To run a query within a React component, call `useGetTimelineEntriesDescriptorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTimelineEntriesDescriptorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTimelineEntriesDescriptorsQuery({
 *   variables: {
 *      timelineEntriesWhere: // value for 'timelineEntriesWhere'
 *      timelineEntriesOrderBy: // value for 'timelineEntriesOrderBy'
 *      pagesWhere: // value for 'pagesWhere'
 *   },
 * });
 */
export function useGetTimelineEntriesDescriptorsQuery(baseOptions: Apollo.QueryHookOptions<GetTimelineEntriesDescriptorsQuery, GetTimelineEntriesDescriptorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTimelineEntriesDescriptorsQuery, GetTimelineEntriesDescriptorsQueryVariables>(GetTimelineEntriesDescriptorsDocument, options);
      }
export function useGetTimelineEntriesDescriptorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTimelineEntriesDescriptorsQuery, GetTimelineEntriesDescriptorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTimelineEntriesDescriptorsQuery, GetTimelineEntriesDescriptorsQueryVariables>(GetTimelineEntriesDescriptorsDocument, options);
        }
export type GetTimelineEntriesDescriptorsQueryHookResult = ReturnType<typeof useGetTimelineEntriesDescriptorsQuery>;
export type GetTimelineEntriesDescriptorsLazyQueryHookResult = ReturnType<typeof useGetTimelineEntriesDescriptorsLazyQuery>;
export type GetTimelineEntriesDescriptorsQueryResult = Apollo.QueryResult<GetTimelineEntriesDescriptorsQuery, GetTimelineEntriesDescriptorsQueryVariables>;
export const UpdateTimelineEntryDocument = gql`
    mutation UpdateTimelineEntry($changeTimelineEntryDetailsInput: ChangeTimelineEntryDetailsInput!, $pagesViewedWhere: PagesViewedWhereInput) {
  changeTimelineEntryDetails(data: $changeTimelineEntryDetailsInput) {
    ...TimelineEntryFragment
  }
}
    ${TimelineEntryFragmentFragmentDoc}`;
export type UpdateTimelineEntryMutationFn = Apollo.MutationFunction<UpdateTimelineEntryMutation, UpdateTimelineEntryMutationVariables>;

/**
 * __useUpdateTimelineEntryMutation__
 *
 * To run a mutation, you first call `useUpdateTimelineEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimelineEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimelineEntryMutation, { data, loading, error }] = useUpdateTimelineEntryMutation({
 *   variables: {
 *      changeTimelineEntryDetailsInput: // value for 'changeTimelineEntryDetailsInput'
 *      pagesViewedWhere: // value for 'pagesViewedWhere'
 *   },
 * });
 */
export function useUpdateTimelineEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimelineEntryMutation, UpdateTimelineEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimelineEntryMutation, UpdateTimelineEntryMutationVariables>(UpdateTimelineEntryDocument, options);
      }
export type UpdateTimelineEntryMutationHookResult = ReturnType<typeof useUpdateTimelineEntryMutation>;
export type UpdateTimelineEntryMutationResult = Apollo.MutationResult<UpdateTimelineEntryMutation>;
export type UpdateTimelineEntryMutationOptions = Apollo.BaseMutationOptions<UpdateTimelineEntryMutation, UpdateTimelineEntryMutationVariables>;
export const MergeSplitTimelineEntriesDocument = gql`
    mutation MergeSplitTimelineEntries($data: MergeSplitTimelineEntriesInput!) {
  mergeSplitTimelineEntries(data: $data) {
    newEntry {
      id
      entry_date
      timeline_id
      pages {
        id
        timeline_entry_id
      }
    }
    deletedTimelineEntries
  }
}
    `;
export type MergeSplitTimelineEntriesMutationFn = Apollo.MutationFunction<MergeSplitTimelineEntriesMutation, MergeSplitTimelineEntriesMutationVariables>;

/**
 * __useMergeSplitTimelineEntriesMutation__
 *
 * To run a mutation, you first call `useMergeSplitTimelineEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeSplitTimelineEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeSplitTimelineEntriesMutation, { data, loading, error }] = useMergeSplitTimelineEntriesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMergeSplitTimelineEntriesMutation(baseOptions?: Apollo.MutationHookOptions<MergeSplitTimelineEntriesMutation, MergeSplitTimelineEntriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeSplitTimelineEntriesMutation, MergeSplitTimelineEntriesMutationVariables>(MergeSplitTimelineEntriesDocument, options);
      }
export type MergeSplitTimelineEntriesMutationHookResult = ReturnType<typeof useMergeSplitTimelineEntriesMutation>;
export type MergeSplitTimelineEntriesMutationResult = Apollo.MutationResult<MergeSplitTimelineEntriesMutation>;
export type MergeSplitTimelineEntriesMutationOptions = Apollo.BaseMutationOptions<MergeSplitTimelineEntriesMutation, MergeSplitTimelineEntriesMutationVariables>;
export const BreakApartTimelineEntriesDocument = gql`
    mutation BreakApartTimelineEntries($data: BreakApartTimelineEntriesInput!) {
  breakApartTimelineEntries(data: $data) {
    updatedPages {
      id
      timeline_entry_id
    }
  }
}
    `;
export type BreakApartTimelineEntriesMutationFn = Apollo.MutationFunction<BreakApartTimelineEntriesMutation, BreakApartTimelineEntriesMutationVariables>;

/**
 * __useBreakApartTimelineEntriesMutation__
 *
 * To run a mutation, you first call `useBreakApartTimelineEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBreakApartTimelineEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [breakApartTimelineEntriesMutation, { data, loading, error }] = useBreakApartTimelineEntriesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBreakApartTimelineEntriesMutation(baseOptions?: Apollo.MutationHookOptions<BreakApartTimelineEntriesMutation, BreakApartTimelineEntriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BreakApartTimelineEntriesMutation, BreakApartTimelineEntriesMutationVariables>(BreakApartTimelineEntriesDocument, options);
      }
export type BreakApartTimelineEntriesMutationHookResult = ReturnType<typeof useBreakApartTimelineEntriesMutation>;
export type BreakApartTimelineEntriesMutationResult = Apollo.MutationResult<BreakApartTimelineEntriesMutation>;
export type BreakApartTimelineEntriesMutationOptions = Apollo.BaseMutationOptions<BreakApartTimelineEntriesMutation, BreakApartTimelineEntriesMutationVariables>;
export const BulkUpdatePageTagsDocument = gql`
    mutation BulkUpdatePageTags($where: BulkUpdatePageTagsWhereInput!, $data: BulkUpdatePageTagsDataInput!) {
  bulkUpdatePageTags(where: $where, data: $data) {
    ...PageWithTagsFragment
  }
}
    ${PageWithTagsFragmentFragmentDoc}`;
export type BulkUpdatePageTagsMutationFn = Apollo.MutationFunction<BulkUpdatePageTagsMutation, BulkUpdatePageTagsMutationVariables>;

/**
 * __useBulkUpdatePageTagsMutation__
 *
 * To run a mutation, you first call `useBulkUpdatePageTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdatePageTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdatePageTagsMutation, { data, loading, error }] = useBulkUpdatePageTagsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useBulkUpdatePageTagsMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdatePageTagsMutation, BulkUpdatePageTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdatePageTagsMutation, BulkUpdatePageTagsMutationVariables>(BulkUpdatePageTagsDocument, options);
      }
export type BulkUpdatePageTagsMutationHookResult = ReturnType<typeof useBulkUpdatePageTagsMutation>;
export type BulkUpdatePageTagsMutationResult = Apollo.MutationResult<BulkUpdatePageTagsMutation>;
export type BulkUpdatePageTagsMutationOptions = Apollo.BaseMutationOptions<BulkUpdatePageTagsMutation, BulkUpdatePageTagsMutationVariables>;
export const UpdatePageFavouriteDocument = gql`
    mutation UpdatePageFavourite($data: UpdatePageFavouriteInput!) {
  updatePageFavouriteStatus(data: $data) {
    id
    is_favourite
  }
}
    `;
export type UpdatePageFavouriteMutationFn = Apollo.MutationFunction<UpdatePageFavouriteMutation, UpdatePageFavouriteMutationVariables>;

/**
 * __useUpdatePageFavouriteMutation__
 *
 * To run a mutation, you first call `useUpdatePageFavouriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageFavouriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageFavouriteMutation, { data, loading, error }] = useUpdatePageFavouriteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePageFavouriteMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageFavouriteMutation, UpdatePageFavouriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageFavouriteMutation, UpdatePageFavouriteMutationVariables>(UpdatePageFavouriteDocument, options);
      }
export type UpdatePageFavouriteMutationHookResult = ReturnType<typeof useUpdatePageFavouriteMutation>;
export type UpdatePageFavouriteMutationResult = Apollo.MutationResult<UpdatePageFavouriteMutation>;
export type UpdatePageFavouriteMutationOptions = Apollo.BaseMutationOptions<UpdatePageFavouriteMutation, UpdatePageFavouriteMutationVariables>;
export const UpdatePageViewedDocument = gql`
    mutation UpdatePageViewed($data: UpdatePageViewedInput!) {
  updatePageViewedStatus(data: $data) {
    id
    pages_viewed {
      page_id
      user_id
      viewed_at
    }
  }
}
    `;
export type UpdatePageViewedMutationFn = Apollo.MutationFunction<UpdatePageViewedMutation, UpdatePageViewedMutationVariables>;

/**
 * __useUpdatePageViewedMutation__
 *
 * To run a mutation, you first call `useUpdatePageViewedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageViewedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageViewedMutation, { data, loading, error }] = useUpdatePageViewedMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePageViewedMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageViewedMutation, UpdatePageViewedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageViewedMutation, UpdatePageViewedMutationVariables>(UpdatePageViewedDocument, options);
      }
export type UpdatePageViewedMutationHookResult = ReturnType<typeof useUpdatePageViewedMutation>;
export type UpdatePageViewedMutationResult = Apollo.MutationResult<UpdatePageViewedMutation>;
export type UpdatePageViewedMutationOptions = Apollo.BaseMutationOptions<UpdatePageViewedMutation, UpdatePageViewedMutationVariables>;
export const UpdatePageRotationDocument = gql`
    mutation UpdatePageRotation($data: UpdatePageRotationInput!) {
  updatePageRotation(data: $data) {
    id
    rotation_angle
  }
}
    `;
export type UpdatePageRotationMutationFn = Apollo.MutationFunction<UpdatePageRotationMutation, UpdatePageRotationMutationVariables>;

/**
 * __useUpdatePageRotationMutation__
 *
 * To run a mutation, you first call `useUpdatePageRotationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageRotationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageRotationMutation, { data, loading, error }] = useUpdatePageRotationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePageRotationMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePageRotationMutation, UpdatePageRotationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePageRotationMutation, UpdatePageRotationMutationVariables>(UpdatePageRotationDocument, options);
      }
export type UpdatePageRotationMutationHookResult = ReturnType<typeof useUpdatePageRotationMutation>;
export type UpdatePageRotationMutationResult = Apollo.MutationResult<UpdatePageRotationMutation>;
export type UpdatePageRotationMutationOptions = Apollo.BaseMutationOptions<UpdatePageRotationMutation, UpdatePageRotationMutationVariables>;
export const UpdateEntryDateDocument = gql`
    mutation UpdateEntryDate($data: UpdateEntryDateInput!) {
  updateEntryDate(data: $data) {
    id
  }
}
    `;
export type UpdateEntryDateMutationFn = Apollo.MutationFunction<UpdateEntryDateMutation, UpdateEntryDateMutationVariables>;

/**
 * __useUpdateEntryDateMutation__
 *
 * To run a mutation, you first call `useUpdateEntryDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntryDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntryDateMutation, { data, loading, error }] = useUpdateEntryDateMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEntryDateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntryDateMutation, UpdateEntryDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntryDateMutation, UpdateEntryDateMutationVariables>(UpdateEntryDateDocument, options);
      }
export type UpdateEntryDateMutationHookResult = ReturnType<typeof useUpdateEntryDateMutation>;
export type UpdateEntryDateMutationResult = Apollo.MutationResult<UpdateEntryDateMutation>;
export type UpdateEntryDateMutationOptions = Apollo.BaseMutationOptions<UpdateEntryDateMutation, UpdateEntryDateMutationVariables>;
export const UpdateEntityDocument = gql`
    mutation UpdateEntity($data: ChangeEntityInput!) {
  changeEntity(data: $data) {
    id
    author_id
    org_id
    subject_id
    authors {
      id
      value
    }
    organizations {
      id
      value
    }
  }
}
    `;
export type UpdateEntityMutationFn = Apollo.MutationFunction<UpdateEntityMutation, UpdateEntityMutationVariables>;

/**
 * __useUpdateEntityMutation__
 *
 * To run a mutation, you first call `useUpdateEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityMutation, { data, loading, error }] = useUpdateEntityMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEntityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntityMutation, UpdateEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntityMutation, UpdateEntityMutationVariables>(UpdateEntityDocument, options);
      }
export type UpdateEntityMutationHookResult = ReturnType<typeof useUpdateEntityMutation>;
export type UpdateEntityMutationResult = Apollo.MutationResult<UpdateEntityMutation>;
export type UpdateEntityMutationOptions = Apollo.BaseMutationOptions<UpdateEntityMutation, UpdateEntityMutationVariables>;
export const UpdateEntrySourceDocument = gql`
    mutation UpdateEntrySource($data: UpdateEntrySourceInput!) {
  updateEntrySource(data: $data) {
    id
  }
}
    `;
export type UpdateEntrySourceMutationFn = Apollo.MutationFunction<UpdateEntrySourceMutation, UpdateEntrySourceMutationVariables>;

/**
 * __useUpdateEntrySourceMutation__
 *
 * To run a mutation, you first call `useUpdateEntrySourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntrySourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntrySourceMutation, { data, loading, error }] = useUpdateEntrySourceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEntrySourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntrySourceMutation, UpdateEntrySourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntrySourceMutation, UpdateEntrySourceMutationVariables>(UpdateEntrySourceDocument, options);
      }
export type UpdateEntrySourceMutationHookResult = ReturnType<typeof useUpdateEntrySourceMutation>;
export type UpdateEntrySourceMutationResult = Apollo.MutationResult<UpdateEntrySourceMutation>;
export type UpdateEntrySourceMutationOptions = Apollo.BaseMutationOptions<UpdateEntrySourceMutation, UpdateEntrySourceMutationVariables>;
export const BulkUpdateTimelineEntriesDocument = gql`
    mutation BulkUpdateTimelineEntries($input: BulkUpdateTimelineEntriesInput!) {
  bulkUpdateTimelineEntries(input: $input) {
    success
    message
  }
}
    `;
export type BulkUpdateTimelineEntriesMutationFn = Apollo.MutationFunction<BulkUpdateTimelineEntriesMutation, BulkUpdateTimelineEntriesMutationVariables>;

/**
 * __useBulkUpdateTimelineEntriesMutation__
 *
 * To run a mutation, you first call `useBulkUpdateTimelineEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkUpdateTimelineEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkUpdateTimelineEntriesMutation, { data, loading, error }] = useBulkUpdateTimelineEntriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkUpdateTimelineEntriesMutation(baseOptions?: Apollo.MutationHookOptions<BulkUpdateTimelineEntriesMutation, BulkUpdateTimelineEntriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkUpdateTimelineEntriesMutation, BulkUpdateTimelineEntriesMutationVariables>(BulkUpdateTimelineEntriesDocument, options);
      }
export type BulkUpdateTimelineEntriesMutationHookResult = ReturnType<typeof useBulkUpdateTimelineEntriesMutation>;
export type BulkUpdateTimelineEntriesMutationResult = Apollo.MutationResult<BulkUpdateTimelineEntriesMutation>;
export type BulkUpdateTimelineEntriesMutationOptions = Apollo.BaseMutationOptions<BulkUpdateTimelineEntriesMutation, BulkUpdateTimelineEntriesMutationVariables>;