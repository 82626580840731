import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import HelpIcon from '../../../components/NewTemplateModal/HelpIcon';

interface ExportProps {
  onSubmit: (exportType: string[], shouldIncludeOCR: boolean, reportName: string) => void;
  onClose: () => void;
  defaultReportName: string;
}

export default function ExportReportDialog({ onSubmit, onClose, defaultReportName }: ExportProps) {
  const [exportTypes, setExportTypes] = useState(['pdf']);
  const [shouldIncludeOCR, setShouldIncludeOCR] = useState(true);
  const [reportName, setReportName] = useState(defaultReportName);

  const toggleExportType = (type: string) => {
    setExportTypes((prevExportTypes) => {
      if (prevExportTypes.includes(type)) {
        return prevExportTypes.filter((exportType) => exportType !== type);
      }
      return [...prevExportTypes, type];
    });
  };

  const handleExport = () => {
    onSubmit(
      exportTypes,
      shouldIncludeOCR,
      reportName?.length > 0 ? reportName : defaultReportName,
    );
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="export-report-dialog-title"
      open
      maxWidth="sm"
      PaperProps={{ sx: { py: '1rem', px: '.5rem' } }}
    >
      <DialogTitle
        id="export-report-dialog-title"
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <Typography variant="filterMenuHead">Export Report</Typography>
        <Typography variant="filterMenuSubHead">
          Please select a format to export your report
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormControlLabel
          value="pdf"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginBottom: '-1rem',
          }}
          control={
            <Checkbox
              sx={{ pt: 0 }}
              checked={exportTypes.includes('pdf')}
              onClick={() => toggleExportType('pdf')}
            />
          }
          label={
            <div>
              <Typography
                variant="filterMenuSubHead"
                sx={{ lineHeight: '12px', fontWeight: 600, display: 'block' }}
              >
                Complete Index (PDF)
              </Typography>
              <Typography variant="filterMenuSubHead">Includes related pages</Typography>
              <FormGroup>
                <FormControlLabel
                  sx={{ mt: '-0.5rem' }}
                  control={
                    <Checkbox
                      id="confirm"
                      checked={shouldIncludeOCR}
                      onChange={(e) => {
                        setShouldIncludeOCR(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography variant="filterMenuSubHead" sx={{ fontWeight: 500 }}>
                      Make PDF Searchable
                      <HelpIcon
                        sx={{ marginTop: '-0.5rem' }}
                        helpText="Check this box to include searchable text in the export. Enabling this may increase file size and cause issues when printing files."
                      />
                    </Typography>
                  }
                />
              </FormGroup>
            </div>
          }
        />
        <FormControlLabel
          value="docx"
          control={
            <Checkbox
              checked={exportTypes.includes('docx')}
              onClick={() => toggleExportType('docx')}
            />
          }
          label={
            <div>
              <Typography
                variant="filterMenuSubHead"
                sx={{ lineHeight: '12px', fontWeight: 600, display: 'block', mt: '1rem' }}
              >
                Document List (Word)
              </Typography>
              <Typography variant="filterMenuSubHead">Table of Contents</Typography>
            </div>
          }
        />
        <Typography
          variant="filterMenuSubHead"
          sx={{ lineHeight: '12px', fontWeight: 600, display: 'block', mt: '1rem' }}
        >
          Report Name
        </Typography>
        <TextField
          sx={{ mt: '0.8rem', width: '100%' }}
          size="small"
          value={reportName}
          onChange={(e) => setReportName(e.target.value ?? defaultReportName)}
        />
        <br />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', width: '100%', alignContent: 'space-between' }}>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          sx={{ width: '100%', fontSize: '0.8rem' }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleExport}
          variant="contained"
          disabled={exportTypes.length === 0}
          sx={{ width: '100%', backgroundColor: 'primary.light', fontSize: '0.8rem' }}
        >
          Export
        </Button>
      </DialogActions>
    </Dialog>
  );
}
