import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getDocumentComparison,
  markDocumentAsDuplicateOf,
  markDocumentAsNotDuplicateOf,
} from '../../../../api';
import { useAsync } from '../../../../hooks/useAsync';

export function useCompareDocuments(
  firstDocumentId: string,
  secondDocumentId: string,
  caseVersion: number,
  firstDocumentSectionID: string,
  secondDocumentSectionID: string,
  setDocumentIds: (ids: { firstDocumentID?: string; secondDocumentID?: string }) => void,
  updateDocumentStatusV1: (
    entryID: string,
    contentTagsSectionID: string,
    isDuplicate: boolean,
  ) => void,
  isFirstDocumentDuplicateV1: boolean, // used for v1 duplicates only
  isSecondDocumentDuplicateV1: boolean, // used for v1 duplicates only
  updateIndexRowCache: (sectionId: string, rowId: string, updatedValues: any) => void,
) {
  const { caseID } = useParams();
  const [documentSimilarity, setDocumentSimilarity] = useState<any | null>(null);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  const [existingDocumentSimilarity, refreshDocumentSimilarity] = useAsync(
    () => fetchDocumentSimilarityIfNeeded(),
    [caseID, firstDocumentId, secondDocumentId],
  );

  useEffect(() => {
    // handle error state
    if (existingDocumentSimilarity.status === 'resolved') {
      if (existingDocumentSimilarity?.data?.document_similarity) {
        const stringifiedDocumentSimilarity = {
          ...existingDocumentSimilarity.data?.document_similarity,
          document_id: String(existingDocumentSimilarity.data?.document_similarity.document_id),
          duplicate_of: String(existingDocumentSimilarity.data?.document_similarity.duplicate_of),
        };
        setDocumentSimilarity(stringifiedDocumentSimilarity);
      }
    } else {
      setDocumentSimilarity(null);
    }
  }, [existingDocumentSimilarity]);

  const indexSectionIDByDocumentID = useMemo(() => {
    if (!firstDocumentSectionID || !secondDocumentSectionID) {
      return null;
    }
    return {
      firstDocumentSectionID,
      secondDocumentSectionID,
    };
  }, [firstDocumentSectionID, secondDocumentSectionID]);

  const fetchDocumentSimilarityIfNeeded = async () => {
    if (!caseID || !firstDocumentId || !secondDocumentId || caseVersion === 1) {
      return null;
    }
    const res = await getDocumentComparison(
      caseID,
      String(firstDocumentId),
      String(secondDocumentId),
    );
    return res.data ?? null;
  };

  const updateIndexData = useCallback(
    async (entryID: string, isDuplicate: boolean) => {
      updateIndexRowCache(String(firstDocumentSectionID), String(entryID), {
        all_pages_marked_duplicate: isDuplicate,
      });

      if (isDuplicate) {
        const otherEntryId = entryID === firstDocumentId ? secondDocumentId : firstDocumentId;
        updateIndexRowCache(String(secondDocumentSectionID), String(otherEntryId), {
          all_pages_marked_duplicate: false,
        });
      }
    },
    [
      firstDocumentId,
      secondDocumentId,
      firstDocumentSectionID,
      secondDocumentSectionID,
      indexSectionIDByDocumentID,
      updateIndexRowCache,
    ],
  );

  const markAsNotDuplicate = useCallback(
    async ({ document_id, duplicate_of }: { document_id: string; duplicate_of: string }) => {
      if (caseVersion !== 2) {
        throw new Error('Using wrong function for case version');
      }
      if (!caseID) {
        throw new Error('Case ID is required');
      }

      await markDocumentAsNotDuplicateOf(caseID, document_id, duplicate_of);
      await refreshDocumentSimilarity();
      await updateIndexData(document_id, false);
      await updateIndexData(duplicate_of, false);

      toast.success('Document marked as not duplicate', { autoClose: 500 });
    },
    [
      caseID,
      caseVersion,
      documentSimilarity,
      firstDocumentId,
      secondDocumentId,
      firstDocumentSectionID,
      secondDocumentSectionID,
    ],
  );

  const markAsDuplicate = useCallback(
    async ({ document_id, duplicate_of }: { document_id: string; duplicate_of: string }) => {
      if (caseVersion !== 2) {
        throw new Error('Using wrong function for case version');
      }

      if (!caseID) {
        throw new Error('Case ID is required');
      }
      await markDocumentAsDuplicateOf(caseID, document_id, duplicate_of);
      await updateIndexData(document_id, true);
      toast.success('Document marked as duplicate', { autoClose: 500 });
      setDocumentIds({ firstDocumentID: duplicate_of, secondDocumentID: document_id });
      await refreshDocumentSimilarity();
    },
    [
      caseID,
      caseVersion,
      firstDocumentId,
      secondDocumentId,
      documentSimilarity,
      firstDocumentSectionID,
      secondDocumentSectionID,
    ],
  );

  // hook returns
  const isFirstDocumentDuplicate = useMemo(() => {
    if (caseVersion === 1) {
      return isFirstDocumentDuplicateV1;
    }

    if (
      documentSimilarity?.status === 'Duplicate' &&
      String(documentSimilarity?.document_id) === firstDocumentId
    ) {
      return true;
    }
    return false;
  }, [caseVersion, isFirstDocumentDuplicateV1, firstDocumentId, documentSimilarity]);

  const isSecondDocumentDuplicate = useMemo(() => {
    if (caseVersion === 1) {
      return isSecondDocumentDuplicateV1;
    }

    if (
      documentSimilarity?.status === 'Duplicate' &&
      String(documentSimilarity?.document_id) === secondDocumentId
    ) {
      return true;
    }
    return false;
  }, [caseVersion, isSecondDocumentDuplicateV1, secondDocumentId, documentSimilarity]);

  const updateDocumentToDuplicateV1 = useCallback(
    async (documentId: string) => {
      if (caseVersion !== 1) {
        throw new Error('Using wrong function for case version');
      }
      setIsUpdateInProgress(true);

      const sectionId =
        documentId === String(firstDocumentId) ? firstDocumentSectionID : secondDocumentSectionID;

      await updateDocumentStatusV1(documentId, sectionId, true);

      setIsUpdateInProgress(false);
    },
    [
      caseVersion,
      firstDocumentId,
      firstDocumentSectionID,
      updateDocumentStatusV1,
      secondDocumentSectionID,
    ],
  );

  const updateDocumentToNonDuplicateV1 = useCallback(
    async (documentId: string) => {
      if (caseVersion !== 1) {
        throw new Error('Using wrong function for case version');
      }
      setIsUpdateInProgress(true);

      const sectionId =
        documentId === String(firstDocumentId) ? firstDocumentSectionID : secondDocumentSectionID;

      await updateDocumentStatusV1(documentId, sectionId, false);
      setIsUpdateInProgress(false);
    },
    [
      caseVersion,
      firstDocumentId,
      firstDocumentSectionID,
      secondDocumentSectionID,
      updateDocumentStatusV1,
    ],
  );

  return {
    isFirstDocumentDuplicate,
    isSecondDocumentDuplicate,
    updateDocumentToDuplicateV1,
    updateDocumentToNonDuplicateV1,
    isUpdateInProgress,
    markAsDuplicate,
    markAsNotDuplicate,
    documentSimilarity,
  };
}
