import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { apiBaseUrl } from './config/apiConfig';
import { EdgeDiff } from './containers/DocumentGrouping/utils/documentParser';
import { queueLimit } from './utils/asyncUtils';
import { TimelineDetailsProps } from './containers/ReportEditor/IndexReports/DocumetPreviewer/DocumentPreviewer';

type httpRequest<T> = AxiosRequestConfig<T> & {
  method: NonNullable<AxiosRequestConfig<T>['method']>;
  url: NonNullable<AxiosRequestConfig<T>['url']>;
  keepalive?: boolean;
};

export const authenticatedRequest = <T, R = T>(config: httpRequest<T>) => {
  const axiosConfig: AxiosRequestConfig<T> = {
    baseURL: apiBaseUrl,
    ...config,
    headers: {
      Authorization: `Bearer ${axios.defaults.headers.common.Authorization}`,
      ...config?.headers,
    },
  };

  if (config.keepalive) {
    axiosConfig.headers = {
      ...axiosConfig.headers,
      Connection: 'keep-alive',
    };
  }

  return axios.request<T, AxiosResponse<R>>(axiosConfig);
};

type DocumentPreview = {
  id: string;
  pages: {
    id: string;
    url: string;
    rotation_angle: number;
  }[];
};

type PreviewProps = {
  caseId: string;
  documentID: string;
  hideBlanks: boolean;
};

type checkFileStatusProps = {
  caseId: string;
  fileId: string;
};

export const getDocumentPreview = ({ caseId, documentID, hideBlanks }: PreviewProps) =>
  authenticatedRequest<DocumentPreview>({
    method: 'GET',
    url: `/api/v1/case/${caseId}/document/${documentID}/preview`,
    params: { hideBlanks },
  });

enum caseStatusEnum {
  NEW = 'NEW',
  READY = 'READY',
  CLOSED = 'CLOSED',
  PROCESSING = 'PROCESSING',
  REQUIRES_PROCESSOR = 'REQUIRES_PROCESSOR',
  ERROR = 'ERROR',
  TAG_IDENTIFICATION = 'TAG_IDENTIFICATION',
}

type CaseUserLocationObject = {
  case_id: string;
  user_id: string;
  page_id: number;
  view: 'document' | 'timeline';
  document_id: string;
  timeline_entry_id: string;
};

export type Case = {
  id: string;
  userID: string;
  processor_assigned: string | null;
  similarity_threshold: number;
  case_status: caseStatusEnum;
  case_name: string;
  claimReason: string | null;
  fullName: string | null;
  isReady: number;
  createDate: Date;
  lastOpened: Date;
  dueDate: Date | null;
  marked_ready_date: Date | null;
  dateOfBirth: Date | null;
  dateOfInjury: Date | null;
  number_of_pages?: string;
  number_of_pages_viewed?: string;
  ref_id: bigint;
  case_user_location?: CaseUserLocationObject;
  archived_at: Date | null;
  archived_by: string | null;
  version: number;
  duplicates_regenerate: boolean;
};

export const getCase = (caseId: string) =>
  authenticatedRequest<Case>({
    method: 'GET',
    url: `/api/v1/case/${caseId}`,
  });

export enum NewOrReady {
  NEW = 'NEW',
  READY = 'READY',
}

export enum FileStatus {
  UPLOADING = 'UPLOADING',
  PENDING = 'PENDING',
  GROUPING = 'GROUPING',
  TAGGING = 'TAGGING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  CLOSED = 'CLOSED',
  QA_REQUIRED = 'QA_REQUIRED',
  APPROVED = 'APPROVED',
  COPYING = 'COPYING',
}

// File = document (trying to switch to use file instead!)
export type FileRawObject = {
  id: string;
  case_id: string;
  document_file_name: string;
  document_file_path: string;
  is_ready: number;
  upload_date: Date;
  classified_chunks: number;
  number_of_chunks: number;
  retry: number;
  number_of_pages: number;
  file_status: FileStatus;
  author_chunks: number;
  author_status: NewOrReady;
  duplicate_status: NewOrReady;
  completed_duplicate_chunks: number;
  num_duplicate_chunks: number;
};

export const getCaseFiles = async (caseId: string) =>
  await authenticatedRequest({
    method: 'GET',
    url: `/api/v1/case/${caseId}/files`,
  });

type FileStatusUpdate = {
  caseID: string;
  fileID: string;
  status: FileStatus;
};

export const updateFileStatus = async ({ caseID, fileID, status }: FileStatusUpdate) =>
  await authenticatedRequest({
    method: 'PUT',
    url: `/api/v1/case/${caseID}/file/${fileID}/status`,
    data: { status },
  });

export const getCasesForAdmin = async () =>
  await authenticatedRequest({
    method: 'GET',
    url: `/api/v1/case/admin/cases`,
  });

export const reprocessCase = (caseId: string) =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/case/${caseId}/reprocess`,
  });

export const updateSupportAccess = (caseId: string, action: 'ADD' | 'REMOVE') =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/case/${caseId}/updateSupportAccess/${action}`,
  });

export const getImagePages = (caseID: string) =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/case/${caseID}/images`,
  });

export type EntryTagsObject = {
  documentTypeId: number | null;
  specialityId: number | null;
  suggestedDocumentTypeIds: number[];
  suggestedSpecialityIds: number[];
};
export const getEntryTags = (entryId: number) =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/timeline-entry/${entryId}/tags`,
  });

export type UpdateEntryTagInput = {
  entryId: number;
  tagId: number | null;
  tagType: string;
};

export const updateEntryTag = ({ entryId, tagId, tagType }: UpdateEntryTagInput): Promise<any> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/timeline-entry/updateEntryTag`,
    data: {
      entryId,
      tagId,
      tagType,
    },
  });

export const getPagesByCaseFile = (
  caseID: string,
  caseFileID: string,
): Promise<AxiosResponse<PageObject[]>> =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/cases/${caseID}/case-files/${caseFileID}/pages`,
  });

export const getCaseFileDocumentIDs = (
  fileID: string,
  caseID: string,
): Promise<AxiosResponse<string[]>> =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/cases/${caseID}/case-files/${fileID}/document_ids`,
  });

export const createDocumentEdge = async (
  caseId: string,
  caseFileId: string,
  pageId: string,
  body: { type: 'Start' | 'End'; confidence: number; created: 'Processor' | 'QA' },
): Promise<AxiosResponse<DocumentEdge>> =>
  authenticatedRequest<
    { type: 'Start' | 'End'; confidence: number; created: 'Processor' | 'QA' },
    DocumentEdge
  >({
    method: 'POST',
    url: `/api/v1/cases/${caseId}/case-files/${caseFileId}/document-edges/${pageId}`,
    data: body,
  });

export const updateDocumentEdgeStatus = async (
  edgeId: string,
  caseId: string,
  caseFileId: string,
  body: { status: 'Confirmed' | 'Dismissed' },
): Promise<AxiosResponse<DocumentEdge>> =>
  authenticatedRequest<{ status: 'Confirmed' | 'Dismissed' }, DocumentEdge>({
    method: 'PATCH',
    url: `/api/v1/cases/${caseId}/case-files/${caseFileId}/document-edges/${edgeId}`,
    data: body,
  });

export const bulkUpdateDocumentEdgesStatuses = async (
  caseID: string,
  caseFileID: string,
  body: EdgeDiff,
): Promise<AxiosResponse<DocumentEdge[]>> =>
  authenticatedRequest<EdgeDiff, DocumentEdge[]>({
    method: 'PATCH',
    url: `/api/v1/cases/${caseID}/case-files/${caseFileID}/document-edges`,
    data: body,
  });

export const buildGroupingsForCaseFile = async (
  caseId: string,
  caseFileId: string,
  startPage: number,
  endPage: number,
): Promise<AxiosResponse> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/cases/${caseId}/case-files/${caseFileId}/buildDocumentsFromEdges`,
    data: { start_page: startPage, end_page: endPage },
  });

export const resetDocumentEdgesForCaseFile = async (
  caseId: string,
  caseFileId: string,
  body: {
    created: 'Processor' | 'QA';
    start_page: number;
    end_page: number;
  },
): Promise<AxiosResponse> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/cases/${caseId}/case-files/${caseFileId}/resetDocumentEdges`,
    data: body,
  });

export const getCaseFile = async (
  caseId: string,
  caseFileId: string,
): Promise<AxiosResponse<CaseFile>> =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/cases/${caseId}/case-files/${caseFileId}`,
  });

export const updateMarkedImportantTimelineEntry = async (
  entryID: number,
  markedImportant: boolean,
) => {
  return authenticatedRequest({
    method: 'POST',
    url: `/timeline-entry/updateMarkedImportant/${entryID}`,
    data: { marked_important: markedImportant },
  });
};

export const updateDuplicateDocument = ({
  entryId,
  isDuplicate,
}: UpdateDuplicateDocumentInput): Promise<any> =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/timeline-entry/updateDuplicateDocument`,
    data: {
      entryId,
      isDuplicate,
    },
  });

export const checkFileStatus = ({ caseId, fileId }: checkFileStatusProps) =>
  authenticatedRequest<FileStatus>({
    method: 'GET',
    url: `/api/v1/case/${caseId}/document/${fileId}/checkFileStatus`,
  });

export const getDocumentSimilarities = async (
  caseID: string,
  similarityThreshold?: number,
  hideResolved?: boolean,
  hideUnresolved?: boolean,
  page?: number,
  pageSize?: number,
): Promise<
  AxiosResponse<{
    count: number;
    documentSimilarities: DocumentSimilarityRecord[];
  }>
> =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/cases/${caseID}/document-similarities`,
    params: {
      similarity_threshold: similarityThreshold,
      hide_resolved: hideResolved,
      hide_unresolved: hideUnresolved,
      page,
      page_size: pageSize,
    },
  });

export const getDocumentComparison = async (
  caseID: string,
  documentID: string,
  compareWith: string,
): Promise<
  AxiosResponse<{
    document_similarity: DocumentSimilarityRecord;
    document: { id: number };
    compare_with: { id: number };
  }>
> =>
  authenticatedRequest({
    method: 'GET',
    url: `/api/v1/cases/${caseID}/document-similarities/document-compare`,
    params: {
      document_id: documentID,
      compare_with: compareWith,
    },
  });

export const markDocumentAsDuplicateOf = async (
  caseID: string,
  documentID: string,
  duplicateOf: string,
) =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/cases/${caseID}/document-similarities/set-duplicate`,
    data: {
      document_id: documentID,
      duplicate_of: duplicateOf,
    },
  });

export const markDocumentAsNotDuplicateOf = async (
  caseID: string,
  documentID: string,
  duplicateOf: string,
) =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/cases/${caseID}/document-similarities/set-not-duplicate`,
    data: {
      document_id: documentID,
      duplicate_of: duplicateOf,
    },
  });

export const createAllDocumentSimilaritiesForCase = async (caseID: string) =>
  authenticatedRequest({
    method: 'POST',
    url: `/api/v1/cases/${caseID}/document-similarities/create-similarities`,
  });

export const getIndexReport = async (
  caseID: string,
  indexReportID: string,
  shouldHideDuplicates: boolean,
  uploadDates?: string[],
) => {
  const paramObject: { shouldHideDuplicates: string; uploadDates?: string } = {
    shouldHideDuplicates: shouldHideDuplicates.toString(),
  };
  if (uploadDates && uploadDates.length > 0) {
    paramObject.uploadDates = uploadDates.toString();
  }
  const params = new URLSearchParams(paramObject);
  const url = `/api/v1/cases/${caseID}/reports/index/${indexReportID}?${params.toString()}`;
  return authenticatedRequest({
    method: 'GET',
    url,
  });
};

export async function updateTimelineEntriesForReportSection({
  caseID,
  entryID,
  valuesToUpdate,
}: UpdateTimelineEntriesProps) {
  await authenticatedRequest({
    method: 'POST',
    url: `/timeline-entry/updateTimelineEntriesForReportSection/${caseID}`,
    data: { entryID, valuesToUpdate },
  });
}

type UpdateTimelineEntriesProps = {
  caseID: string | undefined;
  entryID: bigint;
  valuesToUpdate: TimelineDetailsProps;
};

export type PageObject = {
  id: string;
  document_id: string;
  file_id: string;
  page_number: number;
  rotation_angle: number;
  document_edges: DocumentEdge[];
  document_status: string;
};

export type DocumentEdge = {
  id: string; // converted bigint
  case_id: string;
  file_id: string;
  page_id: string; // converted bigint
  type: 'Start' | 'End';
  confidence: number;
  status: 'Proposed' | 'Confirmed' | 'Dismissed';
  status_changed: 'Processor' | 'QA' | null;
  status_changed_by: string | null;
  created: 'ML' | 'Processor' | 'QA';
  created_by: string | null;
};

export type UpdateDuplicateDocumentInput = {
  entryId: string;
  isDuplicate: boolean;
};

export type CaseFile = {
  id: string;
  case_id: string;
  document_file_name: string;
  document_file_path: string;
  is_ready: number;
  upload_date: Date;
  classified_chunks: number;
  number_of_chunks: number;
  retry: number;
  number_of_pages: number;
  file_status: FileStatus;
  author_chunks: number;
  author_status: NewOrReady;
  completed_duplicate_chunks: number;
  num_duplicate_chunks: number;
  duplicate_status: NewOrReady;
};

export type PaginationDetails = {
  count: number;
  page: number;
  page_size: number;
  next: string | null;
  previous: string | null;
  first: string;
  last: string;
};

export type DocumentSimilarityRecord = {
  case_id: CaseFile['id'];
  document_id: string;
  duplicate_of: string;
  similarity: number;

  status: 'Unset' | 'Duplicate' | 'NotDuplicate';
  status_changed: 'ML' | 'Processor' | 'QA' | 'User' | null;
  status_changed_by: string | null;

  created_at: Date;
  updated_at: Date;
};

// Activity Logging
type UserActivities =
  | { activity: 'report-templates' }
  | { activity: 'report-template'; report_template_id: string }
  | { activity: 'cases' }
  | { activity: 'case:image'; case_id: string }
  | { activity: 'case:tagging'; case_id: string; file_id: string }
  | { activity: 'case:review'; case_id: string; file_id?: string }
  | { activity: 'case:reports'; case_id: string }
  | { activity: 'case:notes'; case_id: string }
  | { activity: 'case:report'; case_id: string; report_id: string }
  | { activity: 'case:index'; case_id: string; index_report_id: string }
  | { activity: 'case:grouping'; case_id: string; file_id: string }
  | { activity: 'case:files'; case_id: string }
  | { activity: 'case:files:uploading'; case_id: string } // todo: add logging for this
  | { activity: 'case:exports'; case_id: string }
  | { activity: 'case:entities'; case_id: string }
  | { activity: 'case:duplicates'; case_id: string }
  | { activity: 'case:document-compare'; case_id: string; document_id: string; compare_id: string }; // todo: add logging for this in future (new duplicate by document);

export type UserActivity = {
  inactive?: boolean;
} & UserActivities;
export const logUserActivity = queueLimit({ limit: 1 }, (activity: UserActivity) =>
  authenticatedRequest({
    method: 'POST',
    url: '/api/v1/me/activity',
    data: activity,
  }),
);

export const updateUserDetails = () =>
  authenticatedRequest({
    method: 'PUT',
    url: '/api/v1/me',
  });
