import { useCallback, useMemo } from 'react';
import { NoSim, SdCardAlert, FlagOutlined, Flag } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import IconVisible from '../../../../components/icons/IconVisible';
import IconHidden from '../../../../components/icons/IconHidden';
import { useUpdateDocumentHiddenStatus } from '../../../Timeline/api-queries/useUpdateDocumentHiddenStatus';
import Theme from '../../../../theme';
import GoToSource from '../../../../components/icons/GoToSource';
import Preview from '../../../../components/icons/Preview';
import Compare from '../../../../components/icons/Compare';

type Props = {
  timelineID: bigint;
  timelineEntryID: bigint;
  firstPageID: bigint;
  setPreviewDocumentID: (id: any) => void;
  setPreviewTimelineID: (id: any) => void;
  setPreviewFirstPage: (id: any) => void;
  handleSetFirstDocumentComparisonID: (id: bigint, isDuplicate: boolean, sectionID: string) => void;
  handleSetSecondDocumentComparisonIDAndOpenModal: (
    id: bigint,
    isDuplicate: boolean,
    sectionID: string,
  ) => void;
  documentComparerDocumentIds: { firstDocumentID?: bigint; secondDocumentID?: bigint };
  isHidden: boolean;
  sourceName: string;
  contentTags: { id: string; value: string }[];
  setPreviewEntryDetails: (id: any) => void;
  contentTagsSectionID: string;
  entryDate: string;
  documentName: string;
  author: { id: number | null; name: string | null };
  organization: { id: number | null; name: string | null };
  isFileProcessor: boolean;
  allPagesMarkedAsDuplicate: boolean;
  monetary_total: string;
  markedImportant: boolean;
  file_id: string;
  handleChangeImportanceMark: (documentId: string, isImportant: boolean) => void;
  sectionId: string;
  updateIndexRowCache: (sectionId: string, rowId: string, updatedValues: any) => void;
};

export default function IndexTableActions({
  timelineID,
  file_id,
  timelineEntryID,
  firstPageID,
  setPreviewDocumentID,
  setPreviewTimelineID,
  setPreviewFirstPage,
  handleSetFirstDocumentComparisonID,
  handleSetSecondDocumentComparisonIDAndOpenModal,
  documentComparerDocumentIds,
  isHidden,
  sourceName,
  contentTags,
  setPreviewEntryDetails,
  contentTagsSectionID,
  sectionId,
  entryDate,
  documentName,
  monetary_total,
  markedImportant,
  author,
  organization,
  isFileProcessor,
  allPagesMarkedAsDuplicate,
  handleChangeImportanceMark,
  updateIndexRowCache,
}: Props) {
  const updateDocumentHiddenStatus = useUpdateDocumentHiddenStatus();

  let HideIconComponent;
  let tooltipTitle;
  if (isFileProcessor) {
    HideIconComponent = allPagesMarkedAsDuplicate ? NoSim : SdCardAlert;
    tooltipTitle = allPagesMarkedAsDuplicate ? 'Mark as not duplicate' : 'Mark as duplicate';
  } else {
    HideIconComponent = isHidden ? IconVisible : IconHidden;
    tooltipTitle = isHidden ? 'Unhide Document' : 'Hide Document';
  }

  const handleHideClick = useCallback(() => {
    updateDocumentHiddenStatus(timelineEntryID, !isHidden);
    updateIndexRowCache(sectionId, String(timelineEntryID), { is_hidden: !isHidden ? 1 : 0 });
  }, [timelineEntryID, updateDocumentHiddenStatus, isHidden, updateIndexRowCache]);

  const { isDocumentComparisonSelectionInProgress, shouldSecondComparisonButtonBeHidden } =
    useMemo(() => {
      const isDocumentComparisonSelectionInProgress =
        documentComparerDocumentIds.firstDocumentID !== undefined &&
        documentComparerDocumentIds.secondDocumentID === undefined;
      const shouldSecondComparisonButtonBeHidden =
        documentComparerDocumentIds.firstDocumentID === timelineEntryID;
      return { isDocumentComparisonSelectionInProgress, shouldSecondComparisonButtonBeHidden };
    }, [documentComparerDocumentIds, timelineEntryID]);

  const FlagComponent = markedImportant ? Flag : FlagOutlined;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        gap: '0.2rem',
      }}
    >
      {isDocumentComparisonSelectionInProgress ? (
        <Tooltip title="Open Document Comparer">
          <span>
            <Compare
              style={{
                cursor: shouldSecondComparisonButtonBeHidden ? 'not-allowed' : 'pointer',
                color: shouldSecondComparisonButtonBeHidden ? 'grey' : 'var(--color-action-blue)',
              }}
              onClick={
                documentComparerDocumentIds.firstDocumentID !== timelineEntryID
                  ? () => {
                      handleSetSecondDocumentComparisonIDAndOpenModal(
                        timelineEntryID,
                        allPagesMarkedAsDuplicate,
                        contentTagsSectionID,
                      );
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      ) : (
        <>
          <Tooltip title="Preview Document">
            <span>
              <Preview
                onClick={() => {
                  setPreviewDocumentID(timelineEntryID);
                  setPreviewTimelineID(timelineID);
                  setPreviewFirstPage(firstPageID);
                  setPreviewEntryDetails({
                    sourceName: sourceName,
                    contentTags: contentTags.map((tag) => ({ value: tag.id, label: tag.value })),
                    sectionId: sectionId,
                    entryDate: entryDate,
                    documentName: documentName,
                    monetary_total: monetary_total,
                    author: { id: author.id, name: author.name },
                    organization: { id: organization.id, name: organization.name },
                    isHidden: isHidden,
                    markedImportant,
                    file_id: file_id,
                  });
                }}
                style={{
                  cursor: 'pointer',
                }}
              />
            </span>
          </Tooltip>
          {!isFileProcessor && (
            <Tooltip title="Flag Important">
              <span>
                <FlagComponent
                  style={{
                    cursor: 'pointer',
                    color: markedImportant ? Theme.palette.primary.light : '',
                  }}
                  onClick={() => {
                    handleChangeImportanceMark(String(timelineEntryID), !markedImportant);
                    updateIndexRowCache(sectionId, String(timelineEntryID) ?? '', {
                      marked_important: !markedImportant,
                    });
                  }}
                />
              </span>
            </Tooltip>
          )}
          {!isFileProcessor && (
            <Tooltip title={tooltipTitle}>
              <span>
                <HideIconComponent
                  width="1.2rem"
                  height="1.2rem"
                  sx={{ cursor: 'pointer' }}
                  onClick={handleHideClick}
                />
              </span>
            </Tooltip>
          )}
          <Tooltip title="Go to source">
            <Link
              to={`../timeline/${timelineID}/${timelineEntryID}/${firstPageID}${
                isFileProcessor ? `?documentID=${file_id}` : ''
              }`}
              state={{
                resetFilters: true,
              }}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: 'none',
                color: 'inherit',
                marginLeft: '0.1rem',
                marginRight: '0.1rem',
              }}
            >
              <GoToSource stroke="#344054" />
            </Link>
          </Tooltip>
          <Tooltip title="Compare Documents">
            <span>
              <Compare
                style={{
                  cursor:
                    documentComparerDocumentIds.firstDocumentID === timelineEntryID
                      ? 'not-allowed'
                      : 'pointer',
                  color: 'var(--color-action-blue)',
                }}
                onClick={
                  documentComparerDocumentIds.firstDocumentID !== timelineEntryID
                    ? () => {
                        handleSetFirstDocumentComparisonID(
                          timelineEntryID,
                          allPagesMarkedAsDuplicate,
                          contentTagsSectionID,
                        );
                      }
                    : undefined
                }
              />
            </span>
          </Tooltip>
        </>
      )}
    </div>
  );
}
