/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import {
  Button,
  Box,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import axios from 'axios';
import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import FileUploadBox from '../../components/FileUploadBox';
import DocumentProgressCard from '../../components/common/DocumentProgressCard';
import { GetCasesDocument } from '../../__generated__/graphql';
import useDocumentUpload from './useDocumentUpload';
import apiUrl from '../../library/utilities/apiUrl';
import CaseProcessingStatus from '../../components/common/CaseProcessingStatus';
import useCaseFiles from '../Files/useCaseFiles';

function AddFilesDialog({ caseID, open, setOpen, refreshCaseTable }) {
  const {
    handleFileUpload,
    handleRemoveFromFailedFiles,
    files,
    uploadProgress,
    failedFiles,
    clearFiles,
    deleteUnprocessedFiles,
    deleteFile,
  } = useDocumentUpload(caseID);
  const [caseProcessingStatusDialogOpen, setCaseProcessingStatusDialogOpen] = useState(false);
  const [currentlyProcessing, setCurrentlyProcessing] = useState(false);
  const { data: caseFiles } = useCaseFiles(caseID);

  const client = useApolloClient();
  const processedDocuments = caseFiles?.filter((doc) => doc.isReady === 1);
  const unprocessedDocuments = caseFiles?.filter((doc) => doc.isReady === 0);
  const [confirmDeleteDoc, setConfirmDeleteDoc] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const getFileSize = (bytes) => {
    const kb = bytes / 1024;
    if (kb < 1000) {
      return `${kb.toFixed(1)} KB`;
    }
    const mb = kb / 1000;
    return `${mb.toFixed(1)} MB`;
  };

  const startCaseProcessing = async () => {
    setCurrentlyProcessing(true);
    await axios.post(`${apiUrl}startCaseProcessing`, { caseID });
    setOpen(false);
    setCurrentlyProcessing(false);
    clearFiles();
    client.refetchQueries({
      include: [GetCasesDocument],
    });
    if (refreshCaseTable) {
      refreshCaseTable();
    }

    if (location.pathname.indexOf('/cases') > -1) {
      return navigate(`/case/${caseID}/files`);
    }
    return navigate(0);
  };

  const handleCancel = () => {
    deleteUnprocessedFiles();
    clearFiles();
    setOpen(false);
  };

  return (
    <>
      <Dialog
        aria-labelledby="add-files-dialog-wrap"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            handleCancel();
          }
        }}
        PaperProps={{
          sx: {
            width: '85%',
            height: '90%',
            maxHeight: '38rem',
            maxWidth: '70rem',
          },
        }}
      >
        <DialogTitle id="add-files-title">
          <Typography
            color="primary.light"
            sx={{
              marginTop: '0.5rem',
              fontWeight: 700,
              fontSize: '1rem',
              marginBottom: '0.5rem',
            }}
          >
            Add Files
          </Typography>
          <Typography lineHeight="1rem" sx={{ fontSize: '0.65rem', opacity: '80%' }}>
            Click “Browse” to attach files. Please ensure your files are not password protected and
            are unzipped.
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            height: '40rem',
            overflowX: 'scroll',
            minWidth: '68rem',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '38%',
              textAlign: 'center',
              marginBottom: '2rem',
            }}
          >
            <FileUploadBox uploadFiles={handleFileUpload} />
          </Box>
          <Box
            id="files-in-progress"
            sx={{
              width: '29rem',
              height: '15rem',
              right: '4rem',
              position: 'absolute',
              top: '0rem',
            }}
          >
            <Typography variant="body2" sx={{ opacity: '80%', marginBottom: '0.7rem' }}>
              In Progress
            </Typography>
            <Box
              sx={{
                ...addFilesStyles.fileBoxStyles,
                textAlign:
                  (!files || files?.length < 1) &&
                  (!unprocessedDocuments || unprocessedDocuments?.length < 1) &&
                  'center',
                paddingTop:
                  (!files || files?.length < 1) &&
                  (!unprocessedDocuments || unprocessedDocuments?.length < 1) &&
                  '4rem',
              }}
            >
              {files.map((file) => (
                <DocumentProgressCard
                  open={true}
                  key={file.name}
                  fileName={file.name}
                  fileSize={getFileSize(file.size)}
                  fileProgress={uploadProgress[file.name]}
                  onClose={() => {
                    setConfirmDeleteDoc(
                      unprocessedDocuments.find((doc) => doc.docFileName === file.name),
                    );
                  }}
                />
              ))}
              {unprocessedDocuments
                ?.filter((document) => !files.find((file) => file.name === document.docFileName))
                .map((document) => (
                  <DocumentProgressCard
                    key={document.docFileName}
                    open={true}
                    fileName={document.docFileName}
                    fileSize=""
                    fileProgress={100}
                    onClose={() => setConfirmDeleteDoc(document)}
                  />
                ))}
              {(!files || files?.length < 1) &&
                (!unprocessedDocuments || unprocessedDocuments?.length < 1) && (
                  <Typography sx={addFilesStyles.placeholderStyles}>No Files Uploading</Typography>
                )}
            </Box>
            <>
              <Typography
                variant="body2"
                sx={{
                  opacity: '80%',
                  marginBottom: '0.7rem',
                  marginTop: '1.5rem',
                }}
              >
                Previously Uploaded
              </Typography>
              <Box
                sx={{
                  ...addFilesStyles.fileBoxStyles,
                  textAlign: (!processedDocuments || processedDocuments?.length < 1) && 'center',
                  paddingTop: (!processedDocuments || processedDocuments?.length < 1) && '4rem',
                }}
              >
                {processedDocuments?.length > 0 &&
                  processedDocuments?.map((document) => (
                    <DocumentProgressCard
                      key={document.docFileName}
                      open={true}
                      fileName={document.docFileName}
                      fileSize=""
                      fileProgress={100}
                    />
                  ))}
                {(!processedDocuments || processedDocuments?.length === 0) && (
                  <Typography sx={addFilesStyles.placeholderStyles}>
                    No Files Previously Uploaded
                  </Typography>
                )}
              </Box>
            </>
          </Box>

          <Box
            sx={{
              textAlign: 'right',
              marginTop: 8,
              position: 'absolute',
              right: '7rem',
              bottom: '1.5rem',
            }}
          >
            <Button
              onClick={() => handleCancel()}
              variant="outlined"
              classes="primary"
              sx={{ marginRight: '1rem' }}
              disabled={currentlyProcessing}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (Object.keys(failedFiles).length > 0) {
                  toast.error("Looks like we've encountered an error with one of your files");
                } else {
                  toast.success(
                    "We've received your files. We will send you an email once your case is ready!",
                  );
                }
                startCaseProcessing();
              }}
              disabled={
                Object.keys(failedFiles)?.length > 0 ||
                (Object.keys(uploadProgress)?.length > 0 &&
                  Object.keys(uploadProgress)?.filter((file) => uploadProgress[file] !== 100)
                    ?.length > 0) ||
                unprocessedDocuments?.length < 1 ||
                currentlyProcessing
              }
              variant="contained"
              classes="primary"
            >
              Add Files
            </Button>
          </Box>
          <Grid container direction="row">
            <Typography
              variant="body2"
              sx={{
                display: 'inline',
                opacity: '80%',
                marginBottom: '0.6rem',
              }}
            >
              Failed
            </Typography>
          </Grid>
          <Box
            id="failed-uploads"
            sx={{
              ...addFilesStyles.fileBoxStyles,
              textAlign: Object.keys(failedFiles).length < 1 && 'center !important',
              paddingTop: Object.keys(failedFiles).length < 1 && '4rem !important',
            }}
          >
            {Object.keys(failedFiles).length > 0 ? (
              Object.keys(failedFiles).map((file) => (
                <DocumentProgressCard
                  open={true}
                  key={`failed ${document.docFileName} ${Object.keys(failedFiles).indexOf(file)}`}
                  fileName={file}
                  errorMessage={failedFiles[file]}
                  fileProgress={100}
                  onClose={() => handleRemoveFromFailedFiles(file, failedFiles[file])}
                />
              ))
            ) : (
              <Typography sx={addFilesStyles.placeholderStyles}>No Failed Files</Typography>
            )}
          </Box>
        </DialogContent>
      </Dialog>

      <CaseProcessingStatus
        open={caseProcessingStatusDialogOpen}
        retry={() => {
          setCaseProcessingStatusDialogOpen(false);
          setOpen(true);
        }}
        onClose={() => {
          setCaseProcessingStatusDialogOpen(false);
          clearFiles();
        }}
        caseStatus={Object.keys(failedFiles).length > 0 ? 'error' : 'success'}
      />

      <Dialog
        open={!!confirmDeleteDoc}
        aria-labelledby="delete-doc-confirmation"
        PaperProps={{ sx: { padding: '1rem' } }}
      >
        <DialogTitle sx={{ fontSize: '1rem' }}>
          Are you sure you want to delete this document?
        </DialogTitle>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button onClick={() => setConfirmDeleteDoc(null)} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => {
              deleteFile(confirmDeleteDoc);
              setConfirmDeleteDoc(null);
            }}
            sx={{ backgroundColor: 'primary.light' }}
            autoFocus
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const addFilesStyles = {
  placeholderStyles: {
    opacity: '60%',
    fontSize: '0.7rem',
  },
  fileBoxStyles: {
    height: '10rem',
    width: '30rem',
    overflowY: 'scroll',
    border: 'solid 1px #BEBEBE',
    borderRadius: 3,
  },
};

export default AddFilesDialog;
